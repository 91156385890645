import React, { useState } from 'react';
import { Modal } from 'antd';
import DaumPostcode from 'react-daum-postcode';

const FindPostcode = ({ children, onComplete }) => {
  const [isZipcode, setZipcode] = useState(false);

  const handleComplete = (data) => {
    onComplete(data);
    setZipcode(false);
  };

  const childrenArray = React.useMemo(() => {
    return React.Children.toArray(children);
  }, [children]);

  return (
    <>
      <Modal visible={isZipcode} footer={null} onCancel={() => setZipcode(false)}>
        {isZipcode && (
          <div style={{ margin: '30px -24px -24px' }}>
            <DaumPostcode onComplete={handleComplete} />
          </div>
        )}
      </Modal>
      {childrenArray.map((v) =>
        React.cloneElement(v, {
          onClick: () => setZipcode(true),
        }),
      )}
    </>
  );
};

export default React.memo(FindPostcode);
