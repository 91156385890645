import React, { useState, useEffect } from 'react';
import ToDoMessageForm from '@widgets/todo/todoMessageForm';
import { bsApi } from '@common/api';

const TodoMessages = ({ history, match }) => {
  const { params } = match || {};

  const [currentData, setCurrentData] = useState();
  const [isLoaed, setIsLoaed] = useState(false);
  const [todoResources, setTodoResources] = useState([]);
  const fetchData = async (id) => {
    if (params?.id !== 'add') {
      const { data } = await bsApi.get(`/todo/todo-messages/detail/${id}`);
      const args = {
        ...data,
        toDoResourceIds: data?.toDoResources?.map((v) => v.id),
      };
      setCurrentData(args);
    }

    const messageData = await bsApi.get('/todo/todo-resources/all');
    setTodoResources(messageData?.data || []);

    setIsLoaed(true);
  };

  useEffect(() => {
    fetchData(params?.id).catch(console.error);
  }, [params?.id]);

  return (
    <div>
      {isLoaed ? (
        <ToDoMessageForm currentData={currentData} todoResources={todoResources} params={params?.id} />
      ) : (
        <span>loading...</span>
      )}
    </div>
  );
};
export default TodoMessages;
