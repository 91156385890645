export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm';

export const ROLE = {
  ADMIN: '관리자',
  RELATIONSHIP_MANAGER: 'RM',
  CENTER_MANAGER: '센터장',
  CONSULTANT: '일반 상담원',
  PRO_CONSULTANT: '전문 상담원',
  MEMBER: '사용자'
};

export const PUSH_STATUS = {
  SUCCESS: '성공',
  FAILURE: '실패',
  IN_PREPARATION: '처리중',
};
export const WORK_TYPE = {
  FULL_TIME: '정규직',
  PART_TIME: '파트타임',
  PARTNER: '파트너',
};

export const NOTICE_CATEGORY_TYPE = {
  GROUP_WORKSHOPS: '단체워크샾',
  FINANCIAL_EDUCATION: '금융교육',
  CAMPAIGN: '캠페인',
  NOTICE: '일반공지',
};

export const EDUCATION = {
  BASIC: '기본수료',
  INTERMEDIATE: '중급수료',
  ADVANCED: '고급수료',
};

export const ACCOUNT_TYPE = {
  CONSULTANT: '일반 상담원',
  PRO_CONSULTANT: '전문 상담원',
  RELATIONSHIP_MANAGER: 'RM',
  CENTER_MANAGER: '센터장',
};

export const ORGARNIZATION = {
  CENTER: '센터',
  INSTITUTION: '기관',
};

export const CONSULT_TYPE = {
  CALL: '유선상담',
  SNS: '카카오톡상담',
  EMAIL: '이메일 상담',
  MEET_INSIDE: '대면(상담센터방문)상담',
  MEET_OUTSIDE: '대면(고객방문)상담',
  ETC: '기타',
};

export const CONSULTANT_TYPE = {
  BASIC_CONSULTATION: '일반 상담원',
  PRO_CONSULTATION: '전문 상담원',
  RELATIONSHIP_MANAGER: 'RM',
  CENTER_MANAGER: '센터장',
  ADMIN: '최고관리자'
};

export const CONSULTATION_LEVEL = {
  A: 'A레벨',
  B: 'B레벨',
  C: 'C레벨',
};

export const LEAVE_TYPE = {
  THROW_OUT: '관리자탈퇴',
  LEAVE: '회원탈퇴',
};

export const LEAVE_REASON_TYPE = {
  REQ_USER: '고객요청',
  FLOW_OUT_USER_INFO: '개인정보유출',
  COPYRIGHT_VIOLATIONS: '저작권위반',
  AD_WALLPAPER: '광고성도배',
  ETC: '기타',
};

export const WANT_TIME = {
  AM: '오전 9:00 ~ 12:00',
  PM: '오후 13:00 ~ 18:00',
};

export const PROFESSIONAL_FILELD = {
  금융상품: '금융상품',
  재무상담: '재무상담',
  복지상담: '복지상담',
  전문상담: '전문상담',
};

export const PROFESSIONAL_FILELD_SUB = {
  금융상품_예금: ['예금', PROFESSIONAL_FILELD.금융상품],
  금융상품_대출: ['대출', PROFESSIONAL_FILELD.금융상품],
  금융상품_보험: ['보험', PROFESSIONAL_FILELD.금융상품],
  금융상품_카드: ['카드', PROFESSIONAL_FILELD.금융상품],
  금융상품_펀드: ['펀드', PROFESSIONAL_FILELD.금융상품],
  금융상품_은행관리: ['은행관리', PROFESSIONAL_FILELD.금융상품],
  금융상품_신용등급: ['신용등급', PROFESSIONAL_FILELD.금융상품],

  재무상담_은퇴설계: ['은퇴설계', PROFESSIONAL_FILELD.재무상담],
  재무상담_내집마련: ['내집마련/주거', PROFESSIONAL_FILELD.재무상담],
  재무상담_생애주기: ['생애주기맞춤상담', PROFESSIONAL_FILELD.재무상담],

  복지상담_서민금융: ['서민금융', PROFESSIONAL_FILELD.복지상담],
  복지상담_채무조정: ['채무조정', PROFESSIONAL_FILELD.복지상담],
  복지상담_회생: ['회생/파산', PROFESSIONAL_FILELD.복지상담],
  복지상담_민원: ['민원', PROFESSIONAL_FILELD.복지상담],
  복지상담_복지신청: ['복지신청', PROFESSIONAL_FILELD.복지상담],

  전문상담_세무: ['세무', PROFESSIONAL_FILELD.전문상담],
  전문상담_회계: ['회계', PROFESSIONAL_FILELD.전문상담],
  전문상담_상속: ['상속/증여', PROFESSIONAL_FILELD.전문상담],
  전문상담_정책자금: ['정책자금', PROFESSIONAL_FILELD.전문상담],
  전문상담_부동산: ['부동산', PROFESSIONAL_FILELD.전문상담],
  전문상담_P2P: ['P2P', PROFESSIONAL_FILELD.전문상담],
  전문상담_소상공인: ['소상공인', PROFESSIONAL_FILELD.전문상담],
  전문상담_연말정산: ['연말정산', PROFESSIONAL_FILELD.전문상담],
  전문상담_법률: ['법률', PROFESSIONAL_FILELD.전문상담],
};

export const CONSULTATION_STATUS = {
  APPLY: '기본 상담 신청',
  RECEIPT: '상담 지정',
  COMPANION: '기본 상담 반려',
  ACCEPT: '기본 상담 수락',
  COMPLETE: '상담 완료',
  PRO_APPLY: '전문 상담 신청',

  PRO_RECEIPT: '전문 상담 지정',
  PRO_COMPANION: '전문 상담 반려',
  PRO_ACCEPT: '전문 상담 수락',
  PRO_COMPLETE: '전문 상담 완료',
};

export const ERROR_MSG = {
  NONE_DATA: '조회 가능한 데이터가 없습니다.',
};

export const searchItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    md: {
      span: 24,
    },
    lg: {
      span: 3,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    md: {
      span: 24,
    },
    lg: {
      span: 20,
    },
  },
};

export const HOUSE_MODE = [
  { mode: 'TRADING', category: '주택', label: '주택 매매 시세' },
  { mode: 'HIRE', category: '', label: '임차보증금 -(세입자)' },
  { mode: 'LEASE', category: '', label: '임대보증금(임대인, 집주인)' },
  { mode: 'TRADING', category: '상가', label: '상가 시세' },
  { mode: 'TRADING', category: '대지', label: '대지 시세' },
];

export const todoType = [
  { id: 0, value: '찾기' },
  { id: 1, value: '앱쓰기' },
  { id: 2, value: '환전하기' },
  { id: 3, value: '사용하기' },
  { id: 4, value: '만들기' },
  { id: 5, value: '점검하기' },
  { id: 6, value: '되기' },
  { id: 7, value: '수집하기' },
  { id: 8, value: '조정하기' },
  { id: 9, value: '투자하기' },
  { id: 10, value: '상담하기' },
  { id: 11, value: '나누기' },
  { id: 12, value: '높이기' },
  { id: 13, value: '정리하기' },
  { id: 14, value: '쪼개기' },
  { id: 15, value: '창업하기' },
  { id: 16, value: '수립하기' },
  { id: 17, value: '줄이기' },
  { id: 18, value: '지출하기' },
  { id: 19, value: '진단받기' },
  { id: 20, value: '파악하기' },
  { id: 21, value: '올리기' },
  { id: 22, value: '집중하기' },
  { id: 23, value: '확인하기' },
  { id: 24, value: '가입하기' },
  { id: 25, value: '늘리기' },
  { id: 26, value: '시작하기' },
  { id: 27, value: '실천하기' },
  { id: 28, value: '저축하기' },
  { id: 29, value: '상환히기' },
  { id: 30, value: '이용하기' },
  { id: 31, value: '통합하기' },
  { id: 32, value: '전환하기' },
  { id: 33, value: '관리하기' },
  { id: 34, value: '해지하기' },
  { id: 35, value: '받기' },
  { id: 36, value: '탈출하기' },
  { id: 37, value: '신청하기' },
  { id: 38, value: '합치기' },
  { id: 39, value: '챙기기' },
  { id: 40, value: '공부하기' },
  { id: 41, value: '준비하기' },
  { id: 42, value: '마련하기' },
  { id: 43, value: '연습하기' },
  { id: 44, value: '입주하기' },
  { id: 45, value: '이사하기' },
  { id: 46, value: '진단하기' },
  { id: 47, value: '해약하기' },
  { id: 48, value: '활용하기' },
  { id: 49, value: '갈아타기' },
  { id: 50, value: '돌리기' },
  { id: 51, value: '빌리기' },
  { id: 52, value: '상환하기' },
  { id: 53, value: '알아보기' },
  { id: 54, value: '이체하기' },
  { id: 55, value: '작성하기' },
  { id: 56, value: '남기기' },
];

export const serviceCategory = [
  { id: 0, value: '휴면' },
  { id: 1, value: '환전' },
  { id: 2, value: '피싱' },
  { id: 3, value: '포인트' },
  { id: 4, value: '펀드' },
  { id: 5, value: '투자' },
  { id: 6, value: '통장' },
  { id: 7, value: '추천' },
  { id: 8, value: '창업' },
  { id: 9, value: '지출' },
  { id: 10, value: '주거래' },
  { id: 11, value: '전문가' },
  { id: 12, value: '저축' },
  { id: 13, value: '재무설계' },
  { id: 14, value: '자산관리' },
  { id: 15, value: '자동차' },
  { id: 16, value: '이체' },
  { id: 17, value: '연금' },
  { id: 18, value: '신용카드' },
  { id: 19, value: '신용' },
  { id: 20, value: '소득' },
  { id: 21, value: '세금' },
  { id: 22, value: '새는돈' },
  { id: 23, value: '부자' },
  { id: 24, value: '부동산' },
  { id: 25, value: '복지' },
  { id: 26, value: '보험' },
  { id: 27, value: '멘토' },
  { id: 28, value: '대출' },
  { id: 29, value: '노후' },
  { id: 30, value: '금융지식' },
];


export const OLD_COST = {
  1800000: "기본 노후생활",
  2080000: "만족스러운 노후생활",
  2620000: "여유로운 노후생활",
  4020000: "아름다운 노후생활",
  1500000: "기본 노후생활",
  1800000: "만족스러운 노후생활",
  2200000: "여유로운 노후생활",
  3500000: "아름다운 노후생활"
}
