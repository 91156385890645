/**
 * @providesModule ActionAuth
 */
import * as ActionTypes from './actionTypes';
import { bsApi } from '@common/api';

export const me = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      bsApi
        .get('/accounts/authentication')
        .then((response) => {
          if (response.data.type === 'MEMBER') throw { status: 401 };
          dispatch({ type: ActionTypes.SET_PRINCIPAL, data: response.data });
          resolve(response.data);
        })
        .catch(reject);
    });
  };
};

export const clear = () => {
  return (dispatch) => dispatch({ type: ActionTypes.CLEAR_PRINCIPAL });
};
