import React, { useState, useEffect } from 'react';
import RiskMessageForm from '@widgets/todo/riskMessageForm';
import { bsApi } from '@common/api';

const RiskMessages = ({ history, location, match }) => {
  const { params } = match || {};

  const [currentData, setCurrentData] = useState({});
  const [toDoMessages, setToDoMessages] = useState([]);
  const fetchData = async (id) => {
    let args = {};
    if (params?.id !== 'add') {
      const res = await bsApi.get(`/todo/risk-messages/detail/${id}`);
      console.log('Res::', res);
      args = { ...res?.data };
      args.toDoMessageIds = res?.data?.toDoMessages.map((v) => v['id']);
      setCurrentData(args);
    }
    const messageData = await bsApi.get('/todo/todo-messages/all');
    console.log('messageData::', messageData);
    setToDoMessages(messageData?.data || []);
  };

  useEffect(() => {
    fetchData(params?.id).catch(console.error);
  }, [params?.id]);

  return (
    <div>
      {toDoMessages.length > 0 ? (
        <RiskMessageForm currentData={currentData} toDoMessages={toDoMessages} />
      ) : (
        <span>loading...</span>
      )}
    </div>
  );
};
export default RiskMessages;
