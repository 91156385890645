import React, { useEffect, useCallback, useState } from 'react';
import { Button, Card, Table, Form, Popconfirm, Input } from 'antd';
import { bsApi } from '@common/api';
import { Link } from 'react-router-dom';

const EditTableCell = ({ editing, dataIndex, title, record, index, children, ...restProps }) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rule={[
            {
              required: true,
              message: `내용을 입력하세요`,
            },
          ]}
        >
          <Input />
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const CategorySub = ({ match }) => {
  const { params: query } = match || {};
  const smallColumns = [
    {
      title: 'id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '대분류',
      dataIndex: 'bigCategory',
      key: 'bigCategory',
      editable: false,
      render: (_, record) => {
        return (
          <span>
            {typeof record?.bigCategory === 'string' ? record?.bigCategory : record?.bigCategory?.bigCategory}
          </span>
        );
      },
    },
    {
      title: '소분류',
      dataIndex: 'smallCategory',
      key: 'smallCategory',
      editable: true,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => {
        const editable = isEditing(record);
        return (
          <div>
            <Popconfirm title="삭제하시겠습니까?" onConfirm={() => deleteSmallCategoryt(record.id)}>
              <Button>삭제</Button>
              {'  '}
            </Popconfirm>
            {editable ? (
              <>
                <Button onClick={() => save(record.id)}>저장</Button>
                {'  '}
                <Button onClick={() => cancel()}>취소</Button>
              </>
            ) : (
              <Button onClick={() => edit(record)}>수정</Button>
            )}
          </div>
        );
      },
    },
  ];

  const [form] = Form.useForm();
  const [smallCategorys, setSmallCategorys] = useState();
  const [editingKey, setEditingKey] = useState('');
  const isEditing = (record) => record.id === editingKey;
  const edit = (record) => {
    form.setFieldsValue({
      bigCategory: '',
      ...record,
    });
    setEditingKey(record.id);
  };
  const cancel = () => {
    setEditingKey('');
  };

  const save = useCallback(
    async (id) => {
      try {
        const row = await form.validateFields();
        if (row?.smallCategory) {
          const saved = id
            ? await bsApi.put(`/todo-category/small/${id}`, {
                bigCategoryId: query?.id,
                smallCategory: row?.smallCategory,
              })
            : await bsApi.post('/todo-category/small', { bigCategoryId: query?.id, smallCategory: row?.smallCategory });
          setSmallCategorys([...saved?.data]);
          setEditingKey('');
        }
      } catch (e) {
        console.log('validate...fail..', e);
      }
    },
    [smallCategorys],
  );

  const deleteSmallCategoryt = useCallback(
    async (id) => {
      if (!id) {
        if (smallCategorys) {
          const test = smallCategorys;
          test.splice(test.length - 1, 1);
          setSmallCategorys([...test]);
          return;
        }
      } else {
        const res = await bsApi.delete(`/todo-category/small/${id}`);
        fetchData().catch(console.error);
      }
    },
    [smallCategorys],
  );

  const addSmallRow = useCallback(() => {
    const newData = {
      id: null,
      bigCategory: query?.name,
      smallCategory: '',
    };

    setSmallCategorys([...smallCategorys, newData]);
  }, [smallCategorys]);

  const fetchData = useCallback(async () => {
    const res = await bsApi.get(`/todo-category/small/${query.id}`);
    setSmallCategorys(res.data);
  }, [smallCategorys]);

  useEffect(() => {
    if (!smallCategorys) {
      fetchData().catch(console.error);
    }
  }, [smallCategorys]);

  const mergedColumns = smallColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputtype: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Card>
      <h2>소분류 정보</h2>
      {smallCategorys && (
        <div>
          <Button
            type="primary"
            style={{
              marginBottom: 16,
            }}
            onClick={() => addSmallRow()}
          >
            소분류 추가
          </Button>
          <Form form={form} component={false}>
            <Table
              components={{
                body: {
                  cell: EditTableCell,
                },
              }}
              rowKey={(record) => record.id}
              columns={mergedColumns}
              bordered
              pagination={false}
              dataSource={smallCategorys}
            />
          </Form>
        </div>
      )}
    </Card>
  );
};

export default CategorySub;
