import React, { useRef, useState } from 'react';

import { Button, Card, Form, Input, Radio, Select } from 'antd';
import { bsApi } from '@common/api';
import { useHistory } from 'react-router-dom';

const { Option } = Select;

const RiskMessageForm = ({ currentData, toDoMessages }) => {
  const form = useRef();

  const [current, setCurrent] = useState({ ...currentData, toDoMessages: toDoMessages });
  const [select, setSelect] = useState('');
  const onChange = async (e) => {
    setCurrent({ ...current, [e.type]: e.value });
  };
  //저장하기
  const historyPush = useHistory();
  const saveData = async (values) => {
    values.id = currentData.id;
    const res = await bsApi.put('/todo/risk-messages', values);
    alert('저장완료');
    history.back();
  };

  const searchKeyword = (e) => {
    setSelect(e);
  };

  const filteredOptions = current?.toDoMessages.filter((value) => {
    if (!current?.toDoMessages.includes(value)) return value;
  });
  return (
    <div>
      <h2>취약점 상세</h2>
      <Form ref={form} onFinish={saveData}>
        <Card>
          <table className={'details-table'}>
            <colgroup>
              <col width={'30%'} />
              <col width={'70%'} />
            </colgroup>
            <tbody>
              <tr>
                <th>사용여부</th>
                <td>
                  <Form.Item name="visible" initialValue={current?.visible}>
                    <Radio.Group>
                      <Radio value={true}>표시</Radio>
                      <Radio value={false}>가림</Radio>
                    </Radio.Group>
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <th>CODE</th>
                <td>
                  <Form.Item name="code" initialValue={current?.code}>
                    <Input style={{ width: '100%' }} disabled />
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <th>취약점 명</th>
                <td>
                  <Form.Item name="riskName" initialValue={current?.riskName}>
                    <Input style={{ width: '100%' }} />
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <th>to do message</th>
                <td>
                  <Form.Item
                    name="toDoMessagesIds"
                    rules={[
                      {
                        required: true,
                        message: 'toDoMessagesIds 입력해 주세요.',
                      },
                    ]}
                    initialValue={current?.toDoMessageIds}
                  >
                    <Select
                      mode="multiple"
                      style={{ width: '100%' }}
                      onSearch={searchKeyword}
                      filterOption={false}
                      onChange={(e) => onChange({ type: 'toDoMessagesIds', value: e })}
                    >
                      {current?.toDoMessages &&
                        current?.toDoMessages
                          ?.filter((v) => v.name.includes(select))
                          .map((v) => {
                            return (
                              <Option value={v.id} key={`to-do-msg-${v.id}`}>
                                [{v.code}]{v.name}
                              </Option>
                            );
                          })}
                    </Select>
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <th>성공 메세지</th>
                <td>
                  <Form.Item name="successMessage" initialValue={current?.successMessage}>
                    <Input
                      style={{ width: '100%' }}
                      onChange={(e) => onChange({ type: 'successMessage', value: e.target.value })}
                    />
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <th>실패 메세지</th>
                <td>
                  <Form.Item name="failureMessage" initialValue={current?.failureMessage}>
                    <Input
                      style={{ width: '100%' }}
                      onChange={(e) => onChange({ type: 'failureMessage', value: e.target.value })}
                    />
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <th>새는 돈 명칭</th>
                <td>
                  <Form.Item name="leakMoneyName" initialValue={current?.leakMoneyName}>
                    <Input
                      style={{ width: '100%' }}
                      onChange={(e) => onChange({ type: 'leakMoneyName', value: e.target.value })}
                    />
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <th>새는 돈 메세지</th>
                <td>
                  <Form.Item name="leakMoneyMessage" initialValue={current?.leakMoneyMessage}>
                    <Input.TextArea
                      style={{ width: '100%' }}
                      onChange={(e) => onChange({ type: 'leakMoneyMessage', value: e.target.value })}
                    />
                  </Form.Item>
                </td>
              </tr>
            </tbody>
          </table>
          <Button htmlType="submit" type={'primary'} style={{ float: 'right' }} type={'primary'}>
            저장
          </Button>
        </Card>
      </Form>
    </div>
  );
};
export default RiskMessageForm;
