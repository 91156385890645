import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useWindowsDim } from '@common/hooks/useWindowsDim';
import { produce } from 'immer';
import { Button, Card, Col, Form, Input, Row, Select, Table, DatePicker } from 'antd';
import { bsApi } from '@common/api';
import moment from 'moment';
import { DATE_FORMAT, searchItemLayout } from '@common/constants';
import { Link } from 'react-router-dom';
import { formatPrice } from '@common/helpers';
import { useAppState } from '@components/shared/AppProvider';
import { shallowEqual, useSelector } from 'react-redux';
import FileSaver from 'file-saver';
import querystring from 'querystring';

const columnsOrigin = [
  {
    title: '연',
    dataIndex: 'year',
    key: 'year',
    width: 'auto',
    minWidth: 70,
    fixed: 'left',
    align: 'center',
  },
  {
    title: '월',
    dataIndex: 'month',
    key: 'month',
    width: 'auto',
    minWidth: 70,
    fixed: 'left',
    align: 'center',
  },
  {
    title: '임차료',
    dataIndex: 'rent',
    key: 'rent',
    width: 120,
    align: 'right',
    render: (v) => formatPrice(v),
  },
  {
    title: '관리비',
    dataIndex: 'maintenanceFee',
    key: 'maintenanceFee',
    width: 120,
    align: 'right',
    render: (v) => formatPrice(v),
  },
  {
    title: '전기세',
    dataIndex: 'electricityFee',
    key: 'electricityFee',
    width: 120,
    align: 'right',
    render: (v) => formatPrice(v),
  },
  {
    title: '수도세',
    dataIndex: 'waterFee',
    key: 'waterFee',
    width: 120,
    align: 'right',
    render: (v) => formatPrice(v),
  },
  {
    title: '도시가스비',
    dataIndex: 'cityGasFee',
    key: 'cityGasFee',
    width: 120,
    align: 'right',
    render: (v) => formatPrice(v),
  },
  {
    title: '유선전화비',
    dataIndex: 'phoneFee',
    key: 'phoneFee',
    width: 120,
    align: 'right',
    render: (v) => formatPrice(v),
  },
  {
    title: '인터넷비',
    dataIndex: 'internetFee',
    key: 'internetFee',
    width: 120,
    align: 'right',
    render: (v) => formatPrice(v),
  },
  {
    title: '사무용품비',
    dataIndex: 'officeSuppliesCost',
    key: 'officeSuppliesCost',
    width: 120,
    align: 'right',
    render: (v) => formatPrice(v),
  },
  {
    title: '기타',
    dataIndex: 'otherCost',
    key: 'otherCost',
    width: 120,
    align: 'right',
    render: (v) => formatPrice(v),
  },
  {
    title: '총합계',
    dataIndex: 'consultationInformation',
    key: 'username',
    width: 120,
    align: 'right',
    render: (v, r) =>
      formatPrice(
        r?.rent +
          r?.maintenanceFee +
          r?.otherCost +
          r?.officeSuppliesCost +
          r?.internetFee +
          r?.phoneFee +
          r?.cityGasFee +
          r?.waterFee +
          r?.electricityFee,
      ),
  },
];

const initialQuery = {
  page: 0,
  size: 20,
  searchType: undefined,
  periodType: 'month',
  keyword: '',
  year: undefined,
  month: undefined,
};

const FacilityIndex = (props) => {
  const { principal } = useSelector((s) => s.auth, shallowEqual);
  const { width } = useWindowsDim();
  const tableWidth = width - 280;
  const form = useRef();
  const [state] = useAppState();

  const columns = produce(columnsOrigin, (draft) => {
    let cwidths = draft.reduce((a, c) => {
      if (c.width !== 'auto') {
        return a + c.width;
      } else {
        return a + 300;
      }
    }, 0);

    if (['ADMIN'].includes(principal.type)) {
      draft.splice(2, 0, {
        title: '센터명',
        dataIndex: 'center',
        key: 'center',
        width: 'auto',
        minWidth: 250,
        fixed: 'left',
        align: 'left',
        render: (v) => v.name,
      });
      cwidths += 250;
    }

    if (cwidths > tableWidth) {
      draft.forEach((v) => {
        if (v.width === 'auto') v.width = v.minWidth || 300;
        if (state.mobile) delete v.fixed;
      });
    }
  });

  //메인에서 수치링크 타고 올 때
  const { search } = props?.location || {};
  let args = initialQuery;
  if (search?.length > 2) {
    args = {
      ...args,
      ...querystring.decode(search.substring(1)),
    };
  }

  const [data, setData] = useState([]);
  const [query, setQuery] = useState(args);
  const [pagination, setPagination] = useState({});

  const fetchData = useCallback(async () => {
    const { status, data: response } = await bsApi.get('/facilities', { params: query });
    console.log(response);

    setData(response['_embedded'] ? response._embedded.responseList : []);
    setPagination({ ...pagination, total: response.page.totalElements });
  }, [query]);
  console.log(data);

  //검색
  const handleSubmit = async (data) => {
    // e?.preventDefault?.();
    console.log(data);
    const queries = await extractQuery();
    setQuery(queries);
  };

  //초기화
  const handleReset = async () => {
    form?.current?.setFieldsValue(initialQuery);
    await handleSubmit(initialQuery);
  };

  async function extractQuery(pager = {}) {
    console.log(queries);
    const values = await promised(props?.form?.validateFields);
    console.log(values);

    const queries = [];
    Object.keys(values).map((key) => {
      if (['field', 'keyword'].indexOf(key) < 0 && values[key]) {
        queries.push(`${key}=${values[key]}`);
      }
    });

    if (values.keyword) {
      queries.push(`q=${values.field}:${values.keyword}`);
    }

    if (!isEmpty(pager)) {
      queries.push('limit=' + pager.pageSize);
      queries.push('page=' + pager.current);
    }
    console.log(queries);
    console.log(values);

    return queries.join('&');
  }

  const promised = (fn) => {
    return new Promise((resolve, reject) => {
      fn = (e, v) => {
        if (e) {
          reject(e);
          console.log(e);
        } else {
          resolve(v);
          console.log(v);
        }
      };
    });
  };

  const handleTableChange = async (pager, filters, sorter) => {
    console.log(pager);
    setPagination({ ...pagination, current: pager.current });
    const queries = await extractQuery(pager);
    setQuery(queries);
  };
  //기간검색
  const [periodType, setPeriodType] = useState();
  const handleDateType = (e) => {
    console.log(e);
    setPeriodType(e);
  };
  //연도, 월 선택
  const handleDatePicker = async (e) => {
    const date = moment(e);
    if (e.type === 'year') {
      setQuery(
        produce((draft) => {
          draft.year = date.year();
          draft.month = undefined;
        }),
      );
    }
    else if (e.type === 'month') {
      setQuery(
        produce((draft) => {
          draft.year = date.year();
          draft.month = date.month() + 1;
        }),
      );
    }
  };

  useEffect(() => {
    const { history, location } = props;
    history.replace(location.pathname + '?' + querystring.encode(query));

    fetchData();
  }, [fetchData]);

  let fullWidth = 0;
  columns.forEach((v) => (fullWidth += v.width));

  const goFileDown = async () => {
    const { status, data: response } = await bsApi.get('/facilities/excel', {
      params: query,
      headers: { Accept: 'application/hal+json' },
      responseType: 'blob',
    });
    FileSaver.saveAs(response, '시설관리.xls');
  };

  return (
    <div>
      <h2>시설 관리</h2>
      {['ADMIN'].includes(principal.type) && (
        <>
          <Form ref={form} onFinish={handleSubmit}>
            <Card>
              <Form.Item {...searchItemLayout} label="기간검색">
                <Row type={'flex'} gutter={[5, 5]}>
                  <Col>
                    <Form.Item name="periodType" noStyle initialValue={initialQuery.periodType}>
                      <Select style={{ width: 150, marginRight: 5 }} onChange={handleDateType}>
                        <Select.Option value="year">연도별</Select.Option>
                        <Select.Option value="month">월별</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col flex={1}>
                    <Form.Item name="datePicker" noStyle>
                      {/* 연도별 선택하면 */}
                      {initialQuery.periodType === 'month' ? (
                        <DatePicker
                          picker="month"
                          allowClear
                          onChange={(e) => handleDatePicker({ type: 'month', value: e })}
                        />
                      ) : (
                        <DatePicker
                          picker="year"
                          allowClear
                          onChange={(e) => handleDatePicker({ type: 'year', value: e })}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>

              <Form.Item {...searchItemLayout} label="상세검색">
                <Row type={'flex'} gutter={[5, 5]}>
                  <Col>
                    <Form.Item name="searchType" noStyle initialValue={props?.query?.searchType}>
                      <Select style={{ width: 150, marginRight: 5 }} placeholder={'전체'} allowClear>
                        <Select.Option value="name">센터명</Select.Option>
                        <Select.Option value="code">센터ID</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col flex={1} style={{ minWidth: 280 }}>
                    <Form.Item name="keyword" noStyle>
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            </Card>
            <div style={{ marginTop: 10 }}>
              <Row>
                <Col xs={12}>
                  <Button htmlType="button" type={'success'} onClick={goFileDown}>
                    엑셀 다운로드
                  </Button>
                </Col>
                <Col style={{ textAlign: 'right' }} xs={12}>
                  <Button htmlType="button" onClick={handleReset}>
                    초기화
                  </Button>{' '}
                  <Button htmlType="submit" type="primary">
                    검색
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>

          <Card style={{ marginTop: 25 }}>
            <table className={'table-summary'}>
              <thead>
                <tr>
                  <th colSpan={10}>{moment().format('YYYY - MM')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>임차료</th>
                  <th>관리비</th>
                  <th>전기세</th>
                  <th>수도세</th>
                  <th>도시가스비</th>
                  <th>유선전화비</th>
                  <th>인터넷비</th>
                  <th>사무용품비</th>
                  <th>기타</th>
                  <th>총합계</th>
                </tr>
                <tr>
                  <td>₩0</td>
                  <td>₩0</td>
                  <td>₩0</td>
                  <td>₩0</td>
                  <td>₩0</td>
                  <td>₩0</td>
                  <td>₩0</td>
                  <td>₩0</td>
                  <td>₩0</td>
                  <td>₩0</td>
                </tr>
              </tbody>
            </table>
          </Card>
        </>
      )}
      {['CENTER_MANAGER'].includes(principal.type) && (
        <Form ref={form} onFinish={handleSubmit}>
          <Card>
            <Form.Item {...searchItemLayout} label="연도검색">
              <Row type={'flex'} gutter={[5, 5]}>
                <Col flex={1}>
                  <Form.Item name="datePicker" noStyle>
                    {/* 연도별 선택하면 */}
                    <DatePicker
                      picker="year"
                      allowClear
                      onChange={(e) => handleDatePicker({ type: 'year', value: e })}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          </Card>
        </Form>
      )}

      <div style={{ marginTop: 25 }}>
        <Table
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={data}
          pagination={pagination}
          scroll={{ x: fullWidth }}
          onChange={handleTableChange}
          footer={() => (
            <Row>
              <Col span={12}></Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                {principal.type != 'ADMIN' && (
                  <Link to={'/facilities/create'}>
                    <Button style={{ marginLeft: 5 }} type={'primary'}>
                      등록
                    </Button>
                  </Link>
                )}
              </Col>
            </Row>
          )}
          bordered
        />
      </div>
    </div>
  );
};

export default FacilityIndex;
