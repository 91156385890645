import React from 'react';
import ManagerMyForm from '@widgets/managers/myForm';
import { shallowEqual, useSelector } from 'react-redux';


const MyDetails = ({ history, match }) => {
  const { params } = match || {};
  const { principal } = useSelector((s) => s.auth, shallowEqual);

  console.log("principal::", principal);
  return (
    <div>
      {params?.id ? (
        <>
          <h2>마이페이지</h2>
          <ManagerMyForm history={history} match={match} />
        </>
      ) : (
        <p> 잘못된 접근 입니다. </p>
      )}
    </div>
  );
};

export default MyDetails;
