import { shallowEqual, useSelector } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import { intersection } from 'lodash';
import Page from '@components/Page';

import Home from '../pages/index';
import Signin from '@components/Signin';
import React, { useMemo } from 'react';
import appRoutes from '../lib/menus';
import MyDetails from '~/src/pages/managers/myPage';
import { bsApi } from '@common/api';
import Find from '~/src/pages/find';

const Routes = ({ location }) => {
  bsApi.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error?.response?.status === 403) {
        location.href = '/';
      }
      return Promise.reject(error);
    },
  );

  const { principal } = useSelector((state) => state.auth, shallowEqual);
  const pages = useMemo(() => {
    if (!principal) return [];
    const obj = appRoutes?.reduce((a, v, ix) => {
      if (v.component && intersection(v.roles, principal.authorities).length > 0) {
        a[v.route || v.path] = v.component;
      }
      if (v.child) {
        v.child
          ?.filter(
            (v) => !!v.component && (!!v.path || !!v.route) && intersection(v.roles, principal.authorities).length > 0,
          )
          .forEach((v) => {
            a[v.route || v.path] = v.component;
          });
      }
      return a;
    }, {});

    return Object.keys(obj).reduce((a, v) => {
      a.push({ path: v, component: obj[v] });
      return a;
    }, []);
  }, [appRoutes, principal]);

  console.log(pages);

  if (location?.pathname.startsWith('/find')) {
    return <Find />;
  }

  if (!principal) {
    return <Signin />;
  }

  return (
    <Page key={'page'}>
      <Switch>
        <Route sensitive path="/" exact component={Home} />
        <Route sensitive path={['/my-page/:id']} exact component={MyDetails} />
        {pages?.map((page, index) => (
          <Route key={`${index}`} sensitive exact path={page.route || page.path} component={page.component} />
        ))}

        {/*<Route sensitive path={'/todo/risk/:id'} exact component={TodoRiskDetails} />*/}
        {/*<Route sensitive path={'/todo/risk'} exact component={TodoRiskIndex} />*/}

        {/*<Route sensitive path={'/todo/message/:id'} exact component={TodoMessageDetails} />*/}
        {/*<Route sensitive path={'/todo/message'} exact component={TodoMessageIndex} />*/}

        {/*<Route sensitive path={'/todo/resource/:id'} exact component={TodoResourceDetails} />*/}
        {/*<Route sensitive path={'/todo/resource'} exact component={TodoResourceIndex} />*/}

        {/*<Route sensitive path="/todo/category" exact component={Categorys} />*/}
        {/*<Route sensitive path="/todo/category/sub/:id/:name" exact component={CategorySub} />*/}
        {/*<Route sensitive path={['/todo', '/todo/:type']} exact component={TodoIndex} />*/}
      </Switch>
    </Page>
  );
};

export default withRouter(Routes);
