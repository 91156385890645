import axios from 'axios';

export const getAPIHost = () => {
  // return 'https://19080.in.trabricks.io/';
  return process.env.REACT_APP_API_URL || 'https://19080.in.trabricks.io/';
  // return 'https://bankscan.io/' || 'https://19080.in.trabricks.io/';
  // return 'http://localhost:19080/';
};

export const bsApi = axios.create({
  baseURL: getAPIHost() + 'api',
});

export const authApi = axios.create({
  baseURL: getAPIHost(),
});

export const setToken = (cookies) => {
  const { accessToken } = cookies;
  bsApi.defaults.headers.common['x-bankscan-token'] = accessToken;
};

export default { getAPIHost, bsApi, authApi, setToken };
