import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Tabs } from 'antd';
import { useHistory } from "react-router-dom";
import { shallowEqual, useSelector } from 'react-redux';
import { BasicInfo, CallHistory, ConsulHistory, Consult, ProConsult } from '@widgets/consultations/detail';

const { TabPane } = Tabs;

const createTabList = (queryId, userType) => {
  const tabPaneList = [
    {
      title: '기본 상담 내역',
      content: <Consult queryId={queryId} type="basic" />,
      key: 'info2',
      roles: ['ADMIN', 'CENTER_MANAGER', 'CONSULTANT', 'RELATIONSHIP_MANAGER', 'PRO_CONSULTANT'],
    },
    {
      title: '전문 상담 내역',
      content: <ProConsult queryId={queryId} type="pro" />,
      key: 'info3',
      roles: ['ADMIN', 'CENTER_MANAGER', 'CONSULTANT', 'RELATIONSHIP_MANAGER', 'PRO_CONSULTANT'],
    },
    {
      title: '상담 이력',
      content: <ConsulHistory queryId={queryId} />,
      key: 'info4',
      roles: ['ADMIN', 'CENTER_MANAGER', 'RELATIONSHIP_MANAGER'],
    },
    {
      title: '통화 이력',
      content: <CallHistory queryId={queryId} />,
      key: 'info5',
      roles: ['ADMIN', 'CENTER_MANAGER', 'RELATIONSHIP_MANAGER'],
    },
  ];


  const result = tabPaneList.map((v) => {
    if (v.roles.includes(userType)) return v;
  });

  return result;
};
const Operations = ({onClickOption}) => {
  return (<Button onClick={onClickOption}>목록</Button>)
};

const ConsultationDetails = ({ history, match }) => {
  const { params: query } = match || {};
  const { principal } = useSelector((state) => state.auth, shallowEqual);
  // const { query } = useRouter();
  // const query = {};
  const historyPush = useHistory();
  const [tabList, setTabList] = useState(null);

  useEffect(() => {
    const list = createTabList(query.id, principal.type);
    if (list.length > 0 && tabList === null) {
      setTabList(list);
    }
  }, [tabList]);

  const goConsultationList = () => {
    historyPush.push('/consultations');
  };

  const changeTab = (value) => {
    console.log('value...', value);
  };

  return (
    <div>
      <h2>상담 관리</h2>
      <Tabs defaultActiveKey="info1" onChange={changeTab} tabBarExtraContent={<Operations onClickOption={goConsultationList}/>}>
        <TabPane tab="기본 정보" key="info1">
          <BasicInfo queryId={query.id} />
        </TabPane>
        {tabList &&
          tabList.length > 0 &&
          tabList.map((pane) => {
            if(pane) {
              return(
                <TabPane tab={pane.title} key={pane.key}>
                  {pane.content}
                </TabPane>
              )
            }})}
      </Tabs>
    </div>
  );
};
export default ConsultationDetails;
