import moment from 'moment';
import { CONSULTATION_STATUS, DATETIME_FORMAT, WANT_TIME } from '@common/constants';

export const ADMIN_LIST = [
  {
    title: '상담번호',
    dataIndex: 'code',
    key: 'code',
    width: 190,
    fixed: 'left',
    // render: (v) => v.code,
  },
  {
    title: '고객 ID',
    dataIndex: 'createdBy',
    key: 'username',
    width: 150,
    render: (v) => v.username,
  },
  {
    title: '이름',
    dataIndex: 'createdBy',
    key: 'name',
    width: 120,
    render: (v) => v.name,
  },
  {
    title: '전화번호',
    dataIndex: 'createdBy',
    key: 'mobilePhone',
    width: 160,
    render: (text) => (text?.mobilePhone || '').replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, '$1-$2-$3'),
  },
  {
    title: '상담 유형',
    dataIndex: 'status',
    key: 'status',
    width: 210,
    render: (v) => CONSULTATION_STATUS[v],
  },

  {
    title: '희망 상담 일자',
    dataIndex: 'consultWantDate',
    key: 'consultWantDate',
    width: 210,
    render: (v) => moment(v).format('YYYY-MM-DD'),
  },
  {
    title: '희망 상담 시간',
    dataIndex: 'consultWantTime',
    key: 'consultWantTime',
    width: 210,
    render: (v) => WANT_TIME[v],
  },
  {
    title: '센터 /기관 명',
    dataIndex: '',
    key: '',
    width: 210,
    render: (v) => {
      if (v.institution != null) {
        return v.institution?.name;
      } else {
        return v.center.name;
      }
    },
  },
  {
    title: '센터 /기관 코드',
    dataIndex: '',
    key: '',
    width: 210,
    render: (v) => {
      if (v.institution != null) {
        return v.institution?.code;
      } else {
        return v.center.code;
      }
    },
  },
  {
    title: '상담원 명',
    dataIndex: '',
    key: '',
    width: 210,
    render: (v) => {
      if (v.institution !== null) {
        if (v.proConsultant !== null) {
          return v?.proConsultant?.name;
        } else {
          return v?.institution?.manager?.name;
        }
      } else {
        if (v.consultant === null) {
          return v?.center?.manager?.name;
        } else {
          return v?.consultant?.name;
        }
      }
    },
  },
  {
    title: '상담원 유형',
    dataIndex: '',
    key: '',
    width: 210,
    render: (v) => {
      if (v.proConsultant != null) {
        return '전문 상담원';
      } else {
        return '기본 상담원';
      }
    },
  },

  {
    title: '상담 접수 일',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 160,
    render: (v) => {
      return moment(v).format(DATETIME_FORMAT);
    },
  },
  {
    title: '최종 상담 완료 일',
    dataIndex: '',
    key: '',
    width: 210,
    render: (v) => {
      if (v.status === 'COMPLETE' || v.status === 'PRO_COMPLETE') {
        return moment(v.updatedOn).format(DATETIME_FORMAT);
      } else {
        return '';
      }
    },
  },
  {
    title: '최근 업데이트',
    dataIndex: 'updatedOn',
    key: 'updatedOn',
    width: 160,
    render: (v) => moment(v).format(DATETIME_FORMAT),
  },
  {
    title: '관리',
    key: 'operation',
    width: 80,
    align: 'center',
    fixed: 'right',
  },
];

export const CENTER = [
  {
    title: '상담번호',
    dataIndex: 'code',
    key: 'code',
    width: 190,
    fixed: 'left',
    // render: (v) => v.code,
  },
  {
    title: '고객 ID',
    dataIndex: 'createdBy',
    key: 'username',
    width: 150,
    render: (v) => v.username,
  },
  {
    title: '이름',
    dataIndex: 'createdBy',
    key: 'name',
    width: 120,
    render: (v) => v.name,
  },
  {
    title: '전화번호',
    dataIndex: 'createdBy',
    key: 'mobilePhone',
    width: 160,
    render: (text) => (text?.mobilePhone || '').replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, '$1-$2-$3'),
  },
  {
    title: '상담 진행 상태',
    dataIndex: 'status',
    key: 'status',
    width: 210,
    render: (v) => CONSULTATION_STATUS[v],
  },

  {
    title: '희망 상담 일자',
    dataIndex: 'consultWantDate',
    key: 'consultWantDate',
    width: 210,
    render: (v) => moment(v).format('YYYY-MM-DD'),
  },
  {
    title: '희망 상담 시간',
    dataIndex: 'consultWantTime',
    key: 'consultWantTime',
    width: 210,
    render: (v) => WANT_TIME[v],
  },
  {
    title: '센터 명',
    dataIndex: '',
    key: '',
    width: 210,
    render: (v) => {
      if (v.institution != null) {
        return v.institution?.name;
      } else {
        return v.center.name;
      }
    },
  },
  {
    title: '센터 코드',
    dataIndex: '',
    key: '',
    width: 210,
    render: (v) => {
      if (v.institution != null) {
        return v.institution?.code;
      } else {
        return v.center.code;
      }
    },
  },
  {
    title: '상담원 명',
    dataIndex: '',
    key: '',
    width: 210,
    render: (v) => {
      if (v.institution !== null) {
        if (v.proConsultant !== null) {
          return v?.proConsultant?.name;
        } else {
          return v?.institution?.manager?.name;
        }
      } else {
        if (v.consultant === null) {
          return v?.center?.manager?.name;
        } else {
          return v?.consultant?.name;
        }
      }
    },
  },
  {
    title: '상담원 유형',
    dataIndex: '',
    key: '',
    width: 210,
    render: (v) => {
      if (v.proConsultant != null) {
        return '전문 상담원';
      } else {
        return '기본 상담원';
      }
    },
  },

  {
    title: '기본 상담 접수 일',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 160,
    render: (v) => moment(v).format(DATETIME_FORMAT),
  },
  {
    title: '기본 상담 완료 일',
    dataIndex: '',
    key: '',
    width: 210,
    render: (v) => {
      if (v.status === 'COMPLETE' || v.status === 'PRO_COMPLETE') {
        return moment(v.updatedOn).format(DATETIME_FORMAT);
      } else {
        return '';
      }
    },
  },
  {
    title: '최근 업데이트',
    dataIndex: 'updatedOn',
    key: 'updatedOn',
    width: 160,
    render: (v) => moment(v).format(DATETIME_FORMAT),
  },
  {
    title: '관리',
    key: 'operation',
    width: 80,
    align: 'center',
    fixed: 'right',
  },
];

export const PRO_PESSIONAL = [
  {
    title: '상담번호',
    dataIndex: 'code',
    key: 'code',
    width: 190,
    fixed: 'left',
    // render: (v) => v.code,
  },
  {
    title: '고객 ID',
    dataIndex: 'createdBy',
    key: 'username',
    width: 150,
    render: (v) => v.username,
  },
  {
    title: '이름',
    dataIndex: 'createdBy',
    key: 'name',
    width: 120,
    render: (v) => v.name,
  },
  {
    title: '전화번호',
    dataIndex: 'createdBy',
    key: 'mobilePhone',
    width: 160,
    render: (text) => (text?.mobilePhone || '').replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, '$1-$2-$3'),
  },
  {
    title: '상담 유형',
    dataIndex: 'status',
    key: 'status',
    width: 210,
    render: (v) => CONSULTATION_STATUS[v],
  },

  {
    title: '희망 상담 일자',
    dataIndex: 'consultWantDate',
    key: 'consultWantDate',
    width: 210,
    render: (v) => moment(v).format('YYYY-MM-DD'),
  },
  {
    title: '희망 상담 시간',
    dataIndex: 'consultWantTime',
    key: 'consultWantTime',
    width: 210,
    render: (v) => WANT_TIME[v],
  },
  {
    title: '기관 명',
    dataIndex: '',
    key: '',
    width: 210,
    render: (v) => {
      if (v.institution != null) {
        return v.institution?.name;
      } else {
        return v.center.name;
      }
    },
  },
  {
    title: '기관 코드',
    dataIndex: '',
    key: '',
    width: 210,
    render: (v) => {
      if (v.institution != null) {
        return v.institution?.code;
      } else {
        return v.center.code;
      }
    },
  },
  {
    title: '상담원 명',
    dataIndex: '',
    key: '',
    width: 210,
    render: (v) => {
      if (v.institution !== null) {
        if (v.proConsultant !== null) {
          return v?.proConsultant?.name;
        } else {
          return v?.institution?.manager?.name;
        }
      } else {
        if (v.consultant === null) {
          return v?.center?.manager?.name;
        } else {
          return v?.consultant?.name;
        }
      }
    },
  },
  {
    title: '상담원 유형',
    dataIndex: '',
    key: '',
    width: 210,
    render: (v) => {
      if (v.proConsultant != null) {
        return '전문 상담원';
      } else {
        return '기본 상담원';
      }
    },
  },

  {
    title: '전문 상담 접수 일',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 160,
    render: (v) => moment(v).format(DATETIME_FORMAT),
  },
  {
    title: '전문 상담 완료 일',
    dataIndex: '',
    key: '',
    width: 210,
    render: (v) => {
      if (v.status === 'COMPLETE' || v.status === 'PRO_COMPLETE') {
        return moment(v.updatedOn).format(DATETIME_FORMAT);
      } else {
        return '';
      }
    },
  },
  {
    title: '최근 업데이트',
    dataIndex: 'updatedOn',
    key: 'updatedOn',
    width: 160,
    render: (v) => moment(v).format(DATETIME_FORMAT),
  },
  {
    title: '관리',
    key: 'operation',
    width: 80,
    align: 'center',
    fixed: 'right',
  },
];
