import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'antd';
// import { HorizontalGridLines, LineSeries, VerticalGridLines, XAxis, XYPlot, YAxis } from 'react-vis';
import moment from 'moment';
import { shallowEqual, useSelector } from 'react-redux';
import { bsApi } from '@common/api';
import { formatPrice2 } from '@common/helpers';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Example from '@widgets/main/Charts';
import Charts2 from '@widgets/main/Charts2';

const Counter = styled.b`
  display: flex;
  justify-content: center;
  font-size: 22px;
  letter-spacing: -1px;
`;
const CounterTitle = styled.p`
  display: flex;
  justify-content: center;
`;
const BorderLine = styled.div`
  border-left: 1px dashed #ddd;
`;

const Home = () => {
  const { principal } = useSelector((s) => s.auth, shallowEqual);
  const [accrue1, setAccrue1] = useState(null);
  const [accrue2, setAccrue2] = useState(null);
  const [accrue3, setAccrue3] = useState(null);
  const [accrue4, setAccrue4] = useState([{ x: 0, y: 0 }]);

  const [data, setData] = useState();
  const [notices, setNotices] = useState();

  const loadData = async () => {
    const { status, data } = await bsApi.get(`/statistics/main`, {
      validateStatus: () => true,
    });

    setData(data);

    if (data?.['누적기본상담신청건수'] != null) {
      const array = [];
      Object.keys(data?.['누적기본상담신청건수']).map((value) => {
        const obj = {};
        obj.y = data?.['누적기본상담신청건수'][value];
        obj.x = parseInt(value);
        array.push(obj);
      });
      setAccrue1(array);
    }

    if (data?.['누적전문상담신청건수'] != null) {
      const array = [];
      Object.keys(data?.['누적전문상담신청건수']).map((value) => {
        const obj = {};
        obj.y = data?.['누적전문상담신청건수'][value];
        obj.x = parseInt(value);
        array.push(obj);
      });
      setAccrue2(array);
    }

    if (data?.['누적정상회원추이'] != null) {
      const array = [];
      Object.keys(data?.['누적정상회원추이']).map((value) => {
        const obj = {};
        obj.y = data?.['누적정상회원추이'][value];
        obj.x = parseInt(value);
        array.push(obj);
      });
      setAccrue3(array);
    }

    if (data?.['누적탈퇴회원추이'] != null) {
      const array = [];
      Object.keys(data?.['누적탈퇴회원추이']).map((value) => {
        const obj = {};
        obj.y = data?.['누적탈퇴회원추이'][value];
        obj.x = parseInt(value);
        array.push(obj);
      });
      setAccrue4(array);
    }

    console.log(status, data);
  };

  const historyPush = useHistory();
  const loadNotice = async () => {
    const { status, data } = await bsApi.get(`/notices?size=4`, {
      validateStatus: () => true,
    });
    if (status <= 300) {
      setNotices(data?._embedded?.responseList);
    }
  };
  useEffect(() => {
    if (principal.status === 'READY') {
      historyPush.push('/my-page/' + principal.id);
    }
    loadData().catch(console.log);
    loadNotice().catch(console.log);
  }, []);

  return (
    <div>
      <Card title={`상담관리`} style={{ marginBottom: 20 }}>
        <Row gutter={[10, 10]}>
          {['ADMIN', 'CONSULTANT', 'CENTER_MANAGER'].includes(principal.type) && (
            <>
              <Col span={12}>
                <h5>기본상담 (Today {moment().format('YYYY년 M월 D일')})</h5>
                <Row gutter={[10, 10]} style={{ marginTop: 90 }}>
                  <Col span={12}>
                    <Counter>
                      <Link to={'/consultations?type=BASIC_CONSULTATION&status=APPLY'}>
                        {formatPrice2(data?.['상담통계']?.APPLY || 0)}
                      </Link>
                      건
                    </Counter>
                    <CounterTitle>신청건</CounterTitle>
                  </Col>
                  <Col span={12}>
                    <Counter>
                      <Link to={'/consultations?type=BASIC_CONSULTATION&status=COMPANION'}>
                        {formatPrice2(data?.['상담통계']?.COMPANION || 0)}
                      </Link>
                      건
                    </Counter>
                    <CounterTitle>반려건</CounterTitle>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <b>누적 기본상담신청 건수</b>
                {accrue1 && <Example myDATA={accrue1} />}
              </Col>
            </>
          )}
          {['ADMIN', 'PRO_CONSULTANT', 'RELATIONSHIP_MANAGER'].includes(principal.type) && (
            <>
              <Col span={12}>
                <h5>전문상담 (Today {moment().format('YYYY년 M월 D일')})</h5>
                <Row gutter={[10, 10]} style={{ marginTop: 90 }}>
                  <Col span={12}>
                    <Counter>
                      <Link to={'/consultations?type=PRO_CONSULTATION&status=PRO_APPLY'}>
                        {formatPrice2(data?.['상담통계']?.PRO_APPLY || 0)}
                      </Link>
                      건
                    </Counter>
                    <CounterTitle>신청건</CounterTitle>
                  </Col>
                  <Col span={12}>
                    <Counter>
                      <a href={'/consultations?type=PRO_CONSULTATION&status=PRO_COMPANION'}>
                        {formatPrice2(data?.['상담통계']?.PRO_COMPANION || 0)}
                      </a>
                      건
                    </Counter>
                    <CounterTitle>반려건</CounterTitle>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <b>누적 전문상담신청 건수</b>
                {accrue2 && <Example myDATA={accrue2} />}
              </Col>
            </>
          )}
        </Row>
      </Card>

      {['ADMIN'].includes(principal.type) && (
        <>
          <Card title={'누적회원수'} style={{ marginBottom: 20 }}>
            <Row gutter={[10, 10]}>
              <Col span={12} style={{ marginTop: 90 }}>
                <Row gutter={[10, 10]}>
                  <Col span={12}>
                    <Counter>
                      <a href={'/members/active'}>{formatPrice2(data?.['회원통계']?.live || 0)}</a>명
                    </Counter>
                    <CounterTitle>정상회원수</CounterTitle>
                  </Col>
                  <Col span={12}>
                    <Counter>
                      <a href={'/members/leave'}>{formatPrice2(data?.['회원통계']?.leaved || 0)}</a>명
                    </Counter>
                    <CounterTitle>탈퇴회원수</CounterTitle>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>{accrue3 && <Charts2 myDATA={accrue3} myDATA2={accrue4} />}</Col>
            </Row>
          </Card>

          <Row gutter={[20, 20]} style={{ marginBottom: 20 }}>
            <Col span={12}>
              <Card title={'센터정보'}>
                <Row gutter={[10, 10]} style={{ marginTop: 10 }}>
                  <Col span={8}>
                    <Counter>
                      <Link to="/codes/center">{formatPrice2(data?.['기관통계']?.CENTER || 0)}</Link>개
                    </Counter>
                    <CounterTitle>센터</CounterTitle>
                  </Col>
                  <Col span={8}>
                    <Counter>
                      <a href={'/managers/basic?type=CENTER_MANAGER'}>
                        {formatPrice2(data?.['상담원통계']?.CENTER_MANAGER || 0)}
                      </a>
                      명
                    </Counter>
                    <CounterTitle>센터장</CounterTitle>
                  </Col>
                  <Col span={8}>
                    <Counter>
                      <a href={'/managers/basic?type=CONSULTANT'}>
                        {formatPrice2(data?.['상담원통계']?.CONSULTANT || 0)}
                      </a>
                      명
                    </Counter>
                    <CounterTitle>기본상담원 수</CounterTitle>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={12}>
              <Card title={'기관정보'}>
                <Row gutter={[10, 10]} style={{ marginTop: 10 }}>
                  <Col span={8}>
                    <Counter>
                      <a href={'/codes/rm'}>{formatPrice2(data?.['기관통계']?.INSTITUTION || 0)}</a>개
                    </Counter>
                    <CounterTitle>기관</CounterTitle>
                  </Col>
                  <Col span={8}>
                    <Counter>
                      <a href={'/managers/pro?type=RELATIONSHIP_MANAGER'}>
                        {formatPrice2(data?.['상담원통계']?.RELATIONSHIP_MANAGER || 0)}
                      </a>
                      명
                    </Counter>
                    <CounterTitle>RM</CounterTitle>
                  </Col>
                  <Col span={8}>
                    <Counter>
                      <a href={'/managers/pro?type=PRO_CONSULTANT'}>
                        {formatPrice2(data?.['상담원통계']?.PRO_CONSULTANT || 0)}
                      </a>
                      명
                    </Counter>
                    <CounterTitle>전문상담원 수</CounterTitle>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={12}>
              {/* <h5>전문상담 (Today {moment().format('YYYY년 M월 D일')})</h5> */}
              <Card title={'센터 정산내역 (' + moment().format('YYYY년 M월') + ')'}>
                <Row gutter={[10, 10]} style={{ marginTop: 10 }}>
                  <Col span={8}>
                    <Counter>
                      <a href={'/calculates'}>
                        {formatPrice2(data?.['정산통계']?.정산 + data?.['정산통계']?.미정산 || 0)}
                      </a>
                      건
                    </Counter>
                    <CounterTitle>총 상담 건</CounterTitle>
                  </Col>
                  <Col span={8}>
                    <Counter>{formatPrice2(data?.['정산통계']?.정산 || 0)}건</Counter>
                    <CounterTitle>미확인 건</CounterTitle>
                  </Col>
                  <Col span={8}>
                    <Counter>{formatPrice2(data?.['정산통계']?.미정산 || 0)}건</Counter>
                    <CounterTitle>확인 건</CounterTitle>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={12}>
              <Card title={'센터 시설관리'}>
                <Row gutter={[10, 10]} style={{ marginTop: 10 }}>
                  <Col span={12}>
                    <Counter>
                      <a href={`/facilities?month=${moment().subtract(1, 'M').format('YYYY-MM')}`}>
                        {formatPrice2(
                          data?.['시설관리']?.[
                            Object.keys(data?.['시설관리'])[Object.keys(data?.['시설관리']).length - 2]
                          ] || 0,
                        )}
                      </a>
                      원
                    </Counter>
                    <CounterTitle>{moment().subtract(1, 'M').format('YYYY년 MM월')}</CounterTitle>
                  </Col>
                  <Col span={12}>
                    <Counter>
                      <a href={'/facilities'}>
                        {formatPrice2(
                          data?.['시설관리']?.[
                            Object.keys(data?.['시설관리'])[Object.keys(data?.['시설관리']).length - 1]
                          ] || 0,
                        )}
                      </a>
                      원
                    </Counter>
                    <CounterTitle>{moment().format('YYYY년 MM월')}</CounterTitle>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </>
      )}

      <Card title={'공지사항'} style={{ marginBottom: 20 }}>
        <Row gutter={[10, 10]} offset={8}>
          {notices?.map((v) => (
            <Col span={12} key={'' + v.id}>
              <div style={{ border: '1px solid #ddd', padding: 10 }}>
                <Link to={`/notices/${v.id}`}>
                  {v.title}
                  <small style={{ float: 'right' }}>{moment(v?.createdAt).format('YYYY-MM-DD HH:mm')}</small>
                </Link>
              </div>
            </Col>
          ))}
        </Row>
      </Card>

      {['ADMIN'].includes(principal.type) && (
        <Card title={'통화이력'} style={{ marginBottom: 20 }}>
          <Row gutter={[10, 10]}>
            <Col span={14}>
              <Row gutter={[10, 10]} style={{ marginTop: 10 }}>
                <Col span={8}>
                  <Counter>{formatPrice2(data?.['통화이력']?.inbound + data?.['통화이력']?.outbound || 0)}건</Counter>
                  <CounterTitle>총 통화 건</CounterTitle>
                </Col>
                <Col span={8}>
                  <Counter>
                    <Link to="/calls?callType=INBOUND">{formatPrice2(data?.['통화이력']?.inbound || 0)}</Link>건
                  </Counter>
                  <CounterTitle>Inbound</CounterTitle>
                </Col>
                <Col span={8}>
                  <Counter>
                    <a href={'/calls?callType=OUTBOUND'}>{formatPrice2(data?.['통화이력']?.outbound || 0)}</a>건
                  </Counter>
                  <CounterTitle>Outbound</CounterTitle>
                </Col>
              </Row>
            </Col>
            <Col span={8}>
              <BorderLine>
                <Row gutter={[10, 10]} style={{ marginTop: 10 }}>
                  <Col span={12}>
                    <Counter>
                      <a href={'/calls?consultation=BASIC_CONSULTATION'}>
                        {formatPrice2(data?.['상담타입별전화통계']?.BASIC_CONSULTATION) || 0}
                      </a>
                      건
                    </Counter>
                    <CounterTitle>기본상담건</CounterTitle>
                  </Col>
                  <Col span={12}>
                    <Counter>
                      <a href={'/calls?consultation=PRO_CONSULTATION'}>
                        {formatPrice2(data?.['상담타입별전화통계']?.PRO_CONSULTATION || 0)}
                      </a>
                      건
                    </Counter>
                    <CounterTitle>전문상담건</CounterTitle>
                  </Col>
                </Row>
              </BorderLine>
            </Col>
          </Row>
        </Card>
      )}

      {['CENTER_MANAGER'].includes(principal.type) && (
        <>
          <Card title={`${principal.organization.name} 상담원관리 (기본상담원)`} style={{ marginBottom: 20 }}>
            <Row gutter={[10, 10]}>
              <Col span={12}>
                <Row gutter={[10, 10]} style={{ marginTop: 10 }}>
                  <Col span={12}>
                    <b>근무형태</b>
                  </Col>
                </Row>
                <Row gutter={[10, 10]} style={{ marginTop: 10 }}>
                  <Col span={6}>
                    <Counter>
                      <a href={'/managers'}>{formatPrice2(data?.['근무형태']?.allCounts || 0)}</a>명
                    </Counter>
                    <CounterTitle>총 상담원</CounterTitle>
                  </Col>
                  <Col span={6}>
                    <Counter>{formatPrice2(data?.['근무형태']?.FULL_TIME || 0)}명</Counter>
                    <CounterTitle>정규직</CounterTitle>
                  </Col>
                  <Col span={6}>
                    <Counter>{formatPrice2(data?.['근무형태']?.PART_TIME || 0)}명</Counter>
                    <CounterTitle>파트타임</CounterTitle>
                  </Col>
                  <Col span={6}>
                    <Counter>{formatPrice2(data?.['근무형태']?.PARTNER || 0)}명</Counter>
                    <CounterTitle>파트너</CounterTitle>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <BorderLine>
                  <Row gutter={[10, 10]} style={{ marginTop: 10, paddingLeft: 20 }}>
                    <Col span={12}>
                      <b>전문분야</b>
                    </Col>
                  </Row>
                  <Row gutter={[10, 10]} style={{ marginTop: 10, paddingLeft: 20 }}>
                    <Col span={6}>
                      <Counter>{formatPrice2(data?.['전문분야']?.금융상품 || 0)}명</Counter>
                      <CounterTitle>금융상품</CounterTitle>
                    </Col>
                    <Col span={6}>
                      <Counter>{formatPrice2(data?.['전문분야']?.재무상담 || 0)}명</Counter>
                      <CounterTitle>재무상담</CounterTitle>
                    </Col>
                    <Col span={6}>
                      <Counter>{formatPrice2(data?.['전문분야']?.전문상담 || 0)}명</Counter>
                      <CounterTitle>전문상담</CounterTitle>
                    </Col>
                    <Col span={6}>
                      <Counter>{formatPrice2(data?.['전문분야']?.복지상담 || 0)}명</Counter>
                      <CounterTitle>복지상담</CounterTitle>
                    </Col>
                  </Row>
                </BorderLine>
              </Col>
            </Row>
          </Card>

          <Row gutter={[10, 10]}>
            <Col span={12}>
              <Card title={`${principal.organization.name} 정산내역`} style={{ marginBottom: 20 }}>
                <Row gutter={[10, 10]} style={{ marginTop: 10 }}>
                  <Col span={8}>
                    <Counter>
                      <a href={'/consultations'}>
                        {formatPrice2(data?.['정산통계']?.정산 + data?.['정산통계']?.미정산 || 0)}
                      </a>
                      건
                    </Counter>
                    <CounterTitle>총 상담 건</CounterTitle>
                  </Col>
                  <Col span={8}>
                    <Counter>{formatPrice2(data?.['정산통계']?.정산 || 0)}건</Counter>
                    <CounterTitle>미확인 건</CounterTitle>
                  </Col>
                  <Col span={8}>
                    <Counter>{formatPrice2(data?.['정산통계']?.미정산 || 0)}건</Counter>
                    <CounterTitle>확인 건</CounterTitle>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={12}>
              <Card title={`${principal.organization.name} 시설관리 총합계`} style={{ marginBottom: 20 }}>
                <Row gutter={[10, 10]} style={{ marginTop: 10 }}>
                  <Col span={12}>
                    <Counter>
                      <a href={'/facilities'}>
                        {formatPrice2(
                          data?.['시설관리']?.[
                            Object.keys(data?.['시설관리'])[Object.keys(data?.['시설관리']).length - 2]
                          ] || 0,
                        )}
                      </a>
                      원
                    </Counter>
                    <CounterTitle>{moment().subtract(1, 'M').format('YYYY년 MM월')}</CounterTitle>
                  </Col>
                  <Col span={12}>
                    <Counter>
                      <a href={'/facilities'}>
                        {formatPrice2(
                          data?.['시설관리']?.[
                            Object.keys(data?.['시설관리'])[Object.keys(data?.['시설관리']).length - 1]
                          ] || 0,
                        )}
                      </a>
                      원
                    </Counter>
                    <CounterTitle>{moment().format('YYYY년MM월')}</CounterTitle>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </>
      )}
      {['RELATIONSHIP_MANAGER'].includes(principal.type) && (
        <>
          <Card title={`${principal.organization.name} 상담원관리 (전문상담원)`} style={{ marginBottom: 20 }}>
            <Row gutter={[10, 10]}>
              <Col span={12}>
                <Row gutter={[10, 10]} style={{ marginTop: 10 }}>
                  <Col span={12}>
                    <b>근무형태</b>
                  </Col>
                </Row>
                <Row gutter={[10, 10]} style={{ marginTop: 10 }}>
                  <Col span={6}>
                    <Counter>
                      <a href={'/managers'}>{formatPrice2(data?.['근무형태']?.allCounts || 0)}</a>명
                    </Counter>
                    <CounterTitle>총 상담원</CounterTitle>
                  </Col>
                  <Col span={6}>
                    <Counter>{formatPrice2(data?.['근무형태']?.FULL_TIME || 0)}명</Counter>
                    <CounterTitle>정규직</CounterTitle>
                  </Col>
                  <Col span={6}>
                    <Counter>{formatPrice2(data?.['근무형태']?.PART_TIME || 0)}명</Counter>
                    <CounterTitle>파트타임</CounterTitle>
                  </Col>
                  <Col span={6}>
                    <Counter>{formatPrice2(data?.['근무형태']?.PARTNER || 0)}명</Counter>
                    <CounterTitle>파트너</CounterTitle>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <BorderLine>
                  <Row gutter={[10, 10]} style={{ marginTop: 10, paddingLeft: 20 }}>
                    <Col span={12}>
                      <b>전문분야</b>
                    </Col>
                  </Row>
                  <Row gutter={[10, 10]} style={{ marginTop: 10, paddingLeft: 20 }}>
                    <Col span={6}>
                      <Counter>{formatPrice2(data?.['전문분야']?.금융상품 || 0)}명</Counter>
                      <CounterTitle>금융상품</CounterTitle>
                    </Col>
                    <Col span={6}>
                      <Counter>{formatPrice2(data?.['전문분야']?.재무상담 || 0)}명</Counter>
                      <CounterTitle>재무상담</CounterTitle>
                    </Col>
                    <Col span={6}>
                      <Counter>{formatPrice2(data?.['전문분야']?.전문상담 || 0)}명</Counter>
                      <CounterTitle>전문상담</CounterTitle>
                    </Col>
                    <Col span={6}>
                      <Counter>{formatPrice2(data?.['전문분야']?.복지상담 || 0)}명</Counter>
                      <CounterTitle>복지상담</CounterTitle>
                    </Col>
                  </Row>
                </BorderLine>
              </Col>
            </Row>
          </Card>
        </>
      )}


      {['CONSULTANT'].includes(principal.type) && (
        <>
          <Card title={'나의정산내역'} style={{ marginBottom: 20 }}>
            <Row gutter={[10, 10]} style={{ marginTop: 10 }}>
              <Col span={8}>
                <Counter>
                  <a href={'/calculates'}>0</a>건
                </Counter>
                <CounterTitle>총 상담 건</CounterTitle>
              </Col>
              <Col span={8}>
                <Counter>0건</Counter>
                <CounterTitle>미확인 건</CounterTitle>
              </Col>
              <Col span={8}>
                <Counter>0건</Counter>
                <CounterTitle>확인 건</CounterTitle>
              </Col>
            </Row>
          </Card>
        </>
      )}
    </div>
  );
};

export default Home;
