import React, { useCallback, useEffect, useState } from 'react';
import { useWindowsDim } from '@common/hooks/useWindowsDim';
import { produce } from 'immer';
import { Button, Card, Col, Form, Input, Row, Select, Table } from 'antd';
import { bsApi } from '@common/api';
import { Link } from 'react-router-dom';
import { WANT_TIME, CONSULTANT_TYPE } from '@common/constants';
import moment from 'moment';

const columnsOrigin = [
  {
    title: '통화 구분',
    dataIndex: 'update',
    key: 'consultWantDate',
    width: 210,
    //    render: (v) => moment(v).format('YYYY-MM-DD'),
  },
  {
    title: '통화 시작',
    dataIndex: 'account',
    key: 'type',
    width: 210,
    //  render: (v) => v != null ? CONSULTANT_TYPE[v.type] : ""
  },
  {
    title: '통화 종료',
    dataIndex: 'consultationInformation',
    key: 'consultWantTime',
    width: 210,
    //    render: (v) => WANT_TIME[v.consultWantTime]
  },
  {
    title: '수신 번호',
    dataIndex: 'account',
    key: 'name',
    width: 210,
    //    render: (v) => v != null ? v.name : ""
  },
  {
    title: '발신 번호',
    dataIndex: 'account',
    key: 'name',
    width: 210,
    //    render: (v) => v != null ? v.name : ""
  },
  {
    title: '회원 ID',
    dataIndex: 'account',
    key: 'name',
    width: 210,
    //    render: (v) => v != null ? v.name : ""
  },
  {
    title: '회원 이름',
    dataIndex: 'account',
    key: 'name',
    width: 210,
    //    render: (v) => v != null ? v.name : ""
  },
  {
    title: '센터/기관 코드',
    dataIndex: 'account',
    key: 'name',
    width: 210,
    //    render: (v) => v != null ? v.name : ""
  },
  {
    title: '상담원 ID',
    dataIndex: 'account',
    key: 'name',
    width: 210,
    //    render: (v) => v != null ? v.name : ""
  },
  {
    title: '상담원 이름',
    dataIndex: 'account',
    key: 'name',
    width: 210,
    //    render: (v) => v != null ? v.name : ""
  },
  {
    title: '상담원 구분',
    dataIndex: 'account',
    key: 'name',
    width: 210,
    //    render: (v) => v != null ? v.name : ""
  },
];

const ConsulHistory = () => {
  const { width } = useWindowsDim();
  const tableWidth = width - 40;

  const columns = produce(columnsOrigin, (draft) => {
    const cwidths = draft.reduce((a, c) => {
      if (c.width !== 'auto') {
        return a + c.width;
      } else {
        return a + 300;
      }
    }, 0);

    if (cwidths > tableWidth) {
      draft.forEach((v) => {
        if (v.width === 'auto') v.width = 300;
      });
    }
  });

  const [data, setData] = useState([]);
  const [query, setQuery] = useState('');

  const fetchData = useCallback(async () => {
    const { status, data: response } = await bsApi.get('/consultations?' + query);
    setData(response?._embedded?.responseList);
  }, [query]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  let fullWidth = 0;
  columns.forEach((v) => (fullWidth += v.width));

  return (
    <div>
      <Card title="상담 이력">
        <Table
          pagination={false}
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={data}
          scroll={{ x: fullWidth }}
          bordered
        />
      </Card>
    </div>
  );
};

export default ConsulHistory;
