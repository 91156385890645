import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useWindowsDim } from '@common/hooks/useWindowsDim';
import { produce } from 'immer';
import { Alert, Button, Card, Col, DatePicker, Form, Input, Row, Select, Table } from 'antd';
import { bsApi } from '@common/api';
import { Link } from 'react-router-dom';
import { searchItemLayout } from '@common/constants';
import moment from 'moment';
import { useAppState } from '@components/shared/AppProvider';
import { green } from '@ant-design/colors';
import FileSaver from 'file-saver';
import { shallowEqual, useSelector } from 'react-redux';
import { ADMIN_LIST, CENTER, PRO_PESSIONAL } from '@widgets/consultations/data';
import { TypeOfFindConsult, TypeOfFindProConsult } from '@widgets/consultations/list/Buttons';
import querystring from 'querystring';

let columnsOrigin = [...ADMIN_LIST];

const initialQuery = {
  page: 0,
  size: 20,
  organizationType: undefined,
  type: undefined,
  status: undefined,
  searchType: undefined,
  periodType: 'createdAt',
  keyword: undefined,
  fromDate: undefined,
  toDate: undefined,
  sort: 'createdAt,desc',
};

const ConsultationIndex = (props) => {
  console.log(props);
  const { width } = useWindowsDim();
  const tableWidth = width - 40;
  const form = useRef();
  const { principal } = useSelector((state) => state.auth, shallowEqual);
  const [state] = useAppState();

  const { search } = props?.location || {};
  let args = initialQuery;
  if (search?.length > 2) {
    args = {
      ...args,
      ...querystring.decode(search.substring(1)),
    };
  }

  const [query, setQuery] = useState(args);
  console.log(query);
  const [data, setData] = useState({});
  const [type, setType] = useState(undefined); //상담유형
  const [updateList, setUpdateList] = useState([]);
  const [allCounts, setAllCounts] = useState();
  const userType = userTypeResponse(principal.type);
  const columns = produce(columnsOrigin, (draft) => {
    const cwidths = draft.reduce((a, c) => {
      if (c.width !== 'auto') {
        return a + c.width;
      } else {
        return a + 300;
      }
    }, 0);

    if (cwidths > tableWidth) {
      draft.forEach((v) => {
        if (v.width === 'auto') {
          v.width = 300;
        }
        if (state.mobile) {
          delete v.fixed;
        }
      });
    }
    draft[columnsOrigin.length - 1].render = (record) => {
      return (
        <Link to={`/consultations/${record.id}`}>
          <Button htmlType={'button'} size={'small'}>
            상세
          </Button>
        </Link>
      );
    };
  });

  const fetchData = useCallback(async () => {
    try {
      const { status, data: response } = await bsApi.get('/consultations', { params: query });

      setData({
        rows: response?.content || [],
        pagination: {
          current: response?.number + 1,
          pageSize: response?.size,
          total: response?.totalElements || 0,
        },
      });
    } catch (e) {
      console.error(e.response.message);
    }
  }, [query]);

  const fetchDataAllCount = useCallback(async () => {
    const { status, data: response } = await bsApi.get('/consultations/statics', { params: query });
    setAllCounts(response);
  }, [query]);

  const getMyExcel = async () => {
    const { data: response } = await bsApi.get('/consultations/file-download', {
      params: query,
      headers: { Accept: 'application/hal+json' },
      responseType: 'blob',
    });
    FileSaver.saveAs(response, '상담관리.xlsx');
  };

  const handleSubmit = async (data) => {
    let oraganizationType = undefined;

    if (userType !== 'all') {
      if (userType === 'BASIC_CONSULTATION') {
        oraganizationType = 'CENTER';
      } else if (userType === 'PRO_CONSULTATION') {
        oraganizationType = 'INSTITUTION';
      }
    } else {
      if ((data?.searchType && data?.searchType.indexOf('center') > -1) || data?.type === 'BASIC_CONSULTATION') {
        oraganizationType = 'CENTER';
      } else if ((data?.searchType && data?.searchType.indexOf('center') > -1) || data?.type === 'PRO_CONSULTATION') {
        oraganizationType = 'INSTITUTION';
      }
    }

    setQuery(
      produce((draft) => {
        draft.type = data.type;
        draft.status = data.status;
        draft.page = 0;
        draft.keyword = data.keyword;
        draft.searchType = data.searchType;
        draft.organizationType = oraganizationType;
        draft.fromDate = data.dateRange?.[0]?.format('YYYY-MM-DD');
        draft.toDate = data.dateRange?.[1]?.format('YYYY-MM-DD');
      }),
    );
  };

  const handleReset = async () => {
    form?.current?.setFieldsValue(initialQuery);
    await handleSubmit(initialQuery);
  };

  const handleTableChange = async (pager) => {
    setQuery(
      produce((draft) => {
        draft.page = pager?.current - 1;
        draft.size = pager?.pageSize;
      }),
    );
  };

  const handleDateRangeSet = (date) => {
    const date1 = moment().add(-date, 'days');
    const date2 = moment();
    form?.current?.setFieldsValue({
      dateRange: [date1, date2],
    });
    form?.current?.submit();
  };

  useEffect(() => {
    if (userType === 'BASIC_CONSULTATION') {
      columnsOrigin = null;
      columnsOrigin = [...CENTER];
    }
    if (userType === 'PRO_CONSULTATION') {
      columnsOrigin = null;
      columnsOrigin = [...PRO_PESSIONAL];
    }

    fetchData().catch(console.log);
    if (!allCounts) {
      fetchDataAllCount().catch(console.log);
    }
  }, [query]);

  let fullWidth = 0;
  columns.forEach((v) => (fullWidth += v.width));

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setUpdateList(selectedRows);
    },
  };

  const datas = form?.current?.getFieldValue()?.['status'];
  console.log(datas);
  const chanageData = (value) => {
    if (updateList.length > 0) {
      const arrays = updateListData(value);
      fetchData2(arrays);
    } else {
      alert('변경할 row가 선택 되지 않았습니다.');
    }
  };

  const updateListData = (value) => {
    if (updateList.length > 0) {
      for (var i = 0; i < updateList.length; i++) {
        updateList[i].status = principal.type === 'CENTER_MANAGER' ? 'RECEIPT' : 'PRO_RECEIPT';
        if (principal.type === 'CENTER_MANAGER') {
          updateList[i].consultant = value;
        } else {
          updateList[i].proConsultant = value;
        }
      }
    }
    return updateList;
  };

  const fetchData2 = useCallback(
    async (values) => {
      const { status, data: response } = await bsApi.post('/consultations/update-list', values);
      console.log(response.content);
      setData({
        rows: response?.content || [],
        pagination: {
          current: response?.number + 1,
          pageSize: response?.size,
          total: response?.totalElements || 0,
        },
      });
    },
    [query],
  );

  return (
    <div>
      <h2>상담 관리</h2>
      <Alert
        message="※ 고객의 금융 민감정보를 보유하고 있습니다.  해당 페이지 접속 시 주의가 필요합니다. 또한 검토 이력은 기록됩니다."
        type="error"
        style={{ marginBottom: 10 }}
      />
      <Form ref={form} onFinish={handleSubmit}>
        <Card>
          <Form.Item {...searchItemLayout} label="기간검색">
            <Row type={'flex'} gutter={[5, 5]}>
              <Col>
                <Form.Item name="periodType" noStyle initialValue={initialQuery.periodType}>
                  <Select style={{ width: 150, marginRight: 5 }}>
                    <Select.Option value="createdAt">상담접수일</Select.Option>
                    <Select.Option value="lastAccessTime">최종 상담완료일</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col flex={1}>
                <Form.Item name="dateRange" noStyle initialValue={null}>
                  <DatePicker.RangePicker
                    style={{ marginRight: 5 }}
                    disabled={[false, false]}
                    allowClear
                    allowEmpty={[true, true]}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Button.Group>
                  <Button onClick={() => handleDateRangeSet(0)}>오늘</Button>
                  <Button onClick={() => handleDateRangeSet(7)}>7일</Button>
                  <Button onClick={() => handleDateRangeSet(30)}>30일</Button>
                  <Button onClick={() => handleDateRangeSet(365)}>1년</Button>
                </Button.Group>
              </Col>
            </Row>
          </Form.Item>

          <Form.Item {...searchItemLayout} label="선택검색">
            {userType === 'all' && <AdminSearchType type={type} setType={setType} form={form} query={query} />}
            {userType === 'BASIC_CONSULTATION' && <BasicSearchType form={form} query={query} />}
            {userType === 'PRO_CONSULTATION' && <ProSearchType form={form} query={query} />}
          </Form.Item>
          <Form.Item {...searchItemLayout} label="상세검색">
            <Row type={'flex'} gutter={[5, 5]}>
              <Col>
                <Form.Item name="searchType" noStyle>
                  <Select style={{ width: 150, marginRight: 5 }} placeholder={'전체'} allowClear>
                    <Select.Option value="center.name">센터명</Select.Option>
                    <Select.Option value="institution.name">기관명</Select.Option>
                    <Select.Option value="center.code">센터코드</Select.Option>
                    <Select.Option value="institution.code">기관코드</Select.Option>
                    <Select.Option value="code">상담번호</Select.Option>
                    <Select.Option value="consultant.name">기본상담원명</Select.Option>
                    <Select.Option value="proConsultant.name">전문상담원명</Select.Option>
                    <Select.Option value="createdBy.name">고객명</Select.Option>
                    <Select.Option value="createdBy.mobilePhone">고객 전화번호</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col flex={1} style={{ minWidth: 280 }}>
                <Form.Item name="keyword" noStyle>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
        </Card>
        <div style={{ marginTop: 10 }}>
          <Row>
            <Col xs={12}>
              <Button
                htmlType="button"
                onClick={getMyExcel}
                type={'success'}
                style={{
                  backgroundColor: green[6],
                  borderColor: green[7],
                  color: 'white',
                  fontWeight: 'bold',
                }}
              >
                엑셀 다운로드
              </Button>{' '}
              {datas === 'APPLY' && ['CENTER_MANAGER', 'ADMIN'].includes(principal.type) && (
                <TypeOfFindConsult
                  chanageData={chanageData}
                  addQuery={{ center: principal.organization }}
                  principal={principal}
                />
              )}{' '}
              {datas === 'PRO_APPLY' && ['RELATIONSHIP_MANAGER', 'ADMIN'].includes(principal.type) && (
                <TypeOfFindProConsult
                  chanageData={chanageData}
                  addQuery={{ institution: principal.organization }}
                  principal={principal}
                />
              )}
            </Col>
            <Col style={{ textAlign: 'right' }} xs={12}>
              <Button htmlType="button" onClick={handleReset}>
                초기화
              </Button>{' '}
              <Button htmlType="submit" type="primary">
                검색
              </Button>
            </Col>
          </Row>
        </div>
      </Form>

      <Card style={{ marginTop: 25 }}>
        <table className={'table-summary'}>
          <thead>
            <tr>
              <th colSpan={4}>{moment().format('YYYY-MM-DD')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>기본상담 신청</th>
              <th>기본상담 반려</th>
              <th>전문상담 신청</th>
              <th>전문상담 반려</th>
            </tr>
            <tr>
              <td>{allCounts ? (allCounts['APPLY'] ? allCounts['APPLY'] : 0) : 0}</td>
              <td>{allCounts ? (allCounts['COMPANION'] ? allCounts['COMPANION'] : 0) : 0}</td>
              <td>{allCounts ? (allCounts['PRO_APPLY'] ? allCounts['PRO_APPLY'] : 0) : 0}</td>
              <td>{allCounts ? (allCounts['PRO_COMPANION'] ? allCounts['PRO_COMPANION'] : 0) : 0}</td>
            </tr>
          </tbody>
        </table>
      </Card>

      <div style={{ marginTop: 25 }}>
        <Table
          rowSelection={{
            ...rowSelection,
          }}
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={data?.rows || []}
          pagination={data?.pagination || {}}
          scroll={{ x: fullWidth }}
          onChange={handleTableChange}
          bordered
        />
      </div>
    </div>
  );
};

export default ConsultationIndex;

const AdminSearchType = (props) => {
  console.log(props);
  const onChangeType = (e) => {
    props?.form?.current.setFieldsValue({ status: undefined });
    props.setType(e);
    console.log(e);
  };

  return (
    <Row type={'flex'} gutter={[5, 5]}>
      <Col flex={1}>
        <Form.Item name="type" noStyle initialValue={props?.query?.type}>
          <Select style={{ flex: 1, marginRight: 5 }} placeholder={'상담유형'} allowClear onChange={onChangeType}>
            <Select.Option value="BASIC_CONSULTATION">기본상담</Select.Option>
            <Select.Option value="PRO_CONSULTATION">전문상담</Select.Option>
          </Select>
        </Form.Item>
      </Col>
      <Col flex={1}>
        <Form.Item name="status" noStyle initialValue={props?.query?.status}>
          <Select style={{ flex: 1, marginRight: 5 }} placeholder="상담진행 상태" disabled={!props.type}>
            {props.type === 'BASIC_CONSULTATION' ? (
              <>
                <Select.Option value="APPLY">기본상담 신청</Select.Option>
                <Select.Option value="RECEIPT">기본상담 지정</Select.Option>
                <Select.Option value="COMPANION">기본상담 반려</Select.Option>
                <Select.Option value="ACCEPT">기본상담 수락</Select.Option>
                <Select.Option value="COMPLETE">기본상담 완료</Select.Option>
                <Select.Option value="PRO_APPLY">전문상담 신청</Select.Option>
              </>
            ) : (
              <>
                <Select.Option value="PRO_APPLY">전문상담 신청</Select.Option>
                <Select.Option value="PRO_RECEIPT">전문상담 지정</Select.Option>
                <Select.Option value="PRO_COMPANION">전문상담 반려</Select.Option>
                <Select.Option value="PRO_ACCEPT">전문상담 수락</Select.Option>
                <Select.Option value="PRO_COMPLETE">전문상담 완료</Select.Option>
              </>
            )}
          </Select>
        </Form.Item>
      </Col>
    </Row>
  );
};

const BasicSearchType = (props) => {
  return (
    <Row type={'flex'} gutter={[5, 5]}>
      <Col>
        <Form.Item name="status" noStyle initialValue={props?.query?.status}>
          <Select style={{ flex: 1, marginRight: 5 }} placeholder="상담진행 상태">
            <Select.Option value="APPLY">기본상담 신청</Select.Option>
            <Select.Option value="RECEIPT">기본상담 지정</Select.Option>
            <Select.Option value="COMPANION">기본상담 반려</Select.Option>
            <Select.Option value="ACCEPT">기본상담 수락</Select.Option>
            <Select.Option value="COMPLETE">기본상담 완료</Select.Option>
          </Select>
        </Form.Item>
      </Col>
    </Row>
  );
};

const ProSearchType = (props) => {
  return (
    <Row type={'flex'} gutter={[5, 5]}>
      <Col>
        <Form.Item name="status" noStyle initialValue={props?.query?.status}>
          <Select style={{ flex: 1, marginRight: 5 }} placeholder="상담진행 상태">
            <Select.Option value="PRO_APPLY">전문상담 신청</Select.Option>
            <Select.Option value="PRO_RECEIPT">전문상담 지정</Select.Option>
            <Select.Option value="PRO_COMPANION">전문상담 반려</Select.Option>
            <Select.Option value="PRO_ACCEPT">전문상담 수락</Select.Option>
            <Select.Option value="PRO_COMPLETE">전문상담 완료</Select.Option>
          </Select>
        </Form.Item>
      </Col>
    </Row>
  );
};

const userTypeResponse = (value) => {
  switch (value) {
    case 'CENTER_MANAGER':
    case 'CONSULTANT':
      return 'BASIC_CONSULTATION';
    case 'RELATIONSHIP_MANAGER':
    case 'PRO_CONSULTANT':
      return 'PRO_CONSULTATION';
    case 'ADMIN':
      return 'all';
    default:
      return 'all';
  }
};
