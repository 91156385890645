import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useWindowsDim } from '@common/hooks/useWindowsDim';
import { produce } from 'immer';
import { Button, Card, Col, DatePicker, Form, Input, Radio, Row, Select, Table, Tabs } from 'antd';
import { bsApi } from '@common/api';
import { DATE_FORMAT, searchItemLayout } from '@common/constants';
import moment from 'moment';
import FileSaver from 'file-saver';

const { TabPane } = Tabs;

const columnsOrigin = [
  {
    title: '순위',
    dataIndex: 'id',
    key: 'id',
    width: 80,
    fixed: 'left',
    render: (text, record, index) => index + 1,
  },
  {
    title: '구분',
    dataIndex: 'name',
    key: 'name',
    width: 100,
  },
  {
    title: '명칭',
    dataIndex: 'name',
    key: 'name',
    width: 150,
  },
  {
    title: '상담신청건수',
    dataIndex: 'organizationCount',
    key: 'organizationCount',
    width: 150,
  },
  {
    title: '센터상담비율',
    dataIndex: 'percentage',
    key: 'percentage',
    width: 210,
  },
];
const initialQuery = {
  page: 0,
  size: 20,
  searchType: undefined,
  periodType: 'createdAt',
  detailType: null,
  keyword: null,
  fromDate: null,
  toDate: null,
  type: undefined,
  oraganizationType: 'all',
};
const AnalyticsCountIndex = () => {
  const { width } = useWindowsDim();
  const tableWidth = width - 280;

  const columns = produce(columnsOrigin, (draft) => {
    const cwidths = draft.reduce((a, c) => {
      if (c.width !== 'auto') {
        return a + c.width;
      } else {
        return a + 300;
      }
    }, 0);

    if (cwidths > tableWidth) {
      draft.forEach((v) => {
        if (v.width === 'auto') v.width = 300;
      });
    }
  });
  const form = useRef();
  const [data, setData] = useState([]);
  const [query, setQuery] = useState(initialQuery);
  console.log(data);
  const handleDateRangeSet = (date) => {
    const date1 = moment().add(-date, 'days');
    const date2 = moment();
    form?.current?.setFieldsValue({
      dateRange: [date1, date2],
    });
    form?.current?.submit();
  };

  const fetchData = useCallback(async () => {
    console.log('query:::', query);
    const { status, data: response } = await bsApi.get('/statistics', { params: query });
    setData({
      rows: response?._embedded?.responseList || [],
      pagination: {
        current: response?.page?.number + 1,
        pageSize: response?.page?.size,
        total: response?.page?.totalElements || 0,
      },
    });
  }, [query]);

  const handleSubmit = async (data) => {
    console.log(data);
    console.log(radio);
    setQuery(
      produce((draft) => {
        draft.page = 0;
        draft.size = data.pageSize || 10;
        draft.keyword = data.keyword;
        draft.searchType = radio || data.searchType;
        draft.detailType = 
        draft.detailType = data.detailType;
        draft.oraganizationType = data.oraganizationType;
        draft.fromDate = data.dateRange?.[0]?.format('YYYY-MM-DD');
        draft.toDate = data.dateRange?.[1]?.format('YYYY-MM-DD');

        draft.type = data.type;
      }),
    );
  };
  console.log(query);
  const handleReset = async () => {
    form?.current?.setFieldsValue(initialQuery);
    await handleSubmit(initialQuery);
  };

  const handleTableChange = async (pager) => {
    setQuery(
      produce((draft) => {
        draft.size = pager?.pageSize;
        draft.page = pager?.current - 1;
      }),
    );
  };
  //라디오버튼
  const [radio, setRadio] = useState("all");
  const onChangeOrgani = (e) => {
    setRadio(e.target.value);
  };

  const getMyExcel = async () => {
    const { data: response } = await bsApi.get('/statistics/excel-download', {
      params: query,
      headers: { Accept: 'application/hal+json' },
      responseType: 'blob',
    });
    FileSaver.saveAs(response, '통계.xls');
  };

  useEffect(() => {
    fetchData().catch(console.log);
  }, [query]);

  let fullWidth = 0;
  columns.forEach((v) => (fullWidth += v.width));

  return (
    <div>
      <h2>통계</h2>
      <Form ref={form} onFinish={handleSubmit}>
        <Card>
          <Form.Item {...searchItemLayout} label="구분검색" name="searchType">
            <Row>
              <Col flex={1}>
                <Radio.Group value={radio} onChange={onChangeOrgani}>
                  <Radio value={'all'}>전체</Radio>
                  <Radio value={'CENTER'}>센터명</Radio>
                  <Radio value={'INSTITUTION'}>기관별</Radio>
                </Radio.Group>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item {...searchItemLayout} label="상세검색">
            <Row type={'flex'}>
              <Col width={300}>
                <Form.Item name={'detailType'} noStyle initialValue={''}>
                  {radio === 'CENTER' && (
                    <Select style={{ width: 150, marginRight: 5 }} placeholder="전체" allowClear>
                      <Select.Option value="center.name">센터명</Select.Option>
                      <Select.Option value="center.code">센터코드</Select.Option>
                    </Select>
                  )}
                  {radio === 'INSTITUTION' && (
                    <Select style={{ width: 150, marginRight: 5 }} placeholder="전체" allowClear>
                      <Select.Option value="institution.name">기관명</Select.Option>
                      <Select.Option value="institution.code">기관코드</Select.Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col flex={1}>
                <Form.Item name={'keyword'} noStyle>
                  <Input placeholder={'검색어를 입력하세요'} />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
        </Card>
        <div style={{ marginTop: 10 }}>
          <Row>
            <Col xs={12}>
              <Button htmlType="button" onClick={getMyExcel} type={'success'}>
                엑셀 다운로드
              </Button>
            </Col>
            <Col style={{ textAlign: 'right' }} xs={12}>
              <Button htmlType="button" onClick={handleReset}>
                초기화
              </Button>{' '}
              <Button htmlType="submit" type="primary">
                검색
              </Button>
            </Col>
          </Row>
        </div>
      </Form>

      <div style={{ marginTop: 25 }} bodystyle={{ padding: 0 }}>
        <Table
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={data?.rows || []}
          pagination={data?.pagination || {}}
          scroll={{ x: fullWidth }}
          onChange={handleTableChange}
          bordered
        />
      </div>
    </div>
  );
};

export default AnalyticsCountIndex;
