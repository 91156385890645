import { fn } from 'moment';

export function distinct(array) {
  return array.filter((v, i, a) => a.indexOf(v) === i);
}

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function lowercase(string) {
  return string.toLowerCase();
}

export function formatPrice(number) {
  const fnumber = parseFloat(number);
  return new Intl.NumberFormat('ko-KR', {
    style: 'currency',
    currency: 'KRW',
  }).format(fnumber);
}

export function formatPrice2(number) {
  //number 파라미터가 undefined이면 NaN이 출력됨
  //파라미터 받아오는 곳에서 undfinded 일 때, 0으로 입력되어야 할 듯?
  // const fnumber = parseFloat(number);
  const fnumber = number;
  if (fnumber === undefined) {
    const ffnumber = 0;
    return ffnumber;
  }

  const ffnumber = parseFloat(fnumber);

  return new Intl.NumberFormat('ko-KR', {
    maximumSignificantDigits: 3,
  }).format(ffnumber);
}

function toCamelCase(str) {
  return str
    .toLowerCase()
    .replace(/[-_]+/g, ' ')
    .replace(/[^\w\s]/g, '')
    .replace(/ (.)/g, function ($1) {
      return $1.toUpperCase();
    })
    .replace(/ /g, '');
}

export function objectToCamelCase(origObj) {
  return Object.keys(origObj).reduce(function (newObj, key) {
    let val = origObj[key];
    let newVal = typeof val === 'object' ? objectToCamelCase(val) : val;
    newObj[toCamelCase(key)] = newVal;
    return newObj;
  }, {});
}
