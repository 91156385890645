/**
 * @flow
 */

import produce from 'immer';
import * as ActionTypes from './actionTypes';
import moment from 'moment';

const initialState = {
  principal: null,
  timediff: 0,
};

export const auth = (state = initialState, action) => {
  switch (action.type) {
    case 'INIT':
      return action.data?.auth || state;
    case ActionTypes.INIT_AUTH:
      return action.data || state;
    case ActionTypes.SET_PRINCIPAL:
      return produce(state, (draft) => {
        draft.principal = action.data;
        draft.timediff = moment().utc().diff(moment(action.data.now), 'seconds');
      });
    case ActionTypes.CLEAR_PRINCIPAL:
      return produce(state, (draft) => {
        draft.principal = null;
      });
    default:
      return state;
  }
};
