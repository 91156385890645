import React, { useCallback, useState, useEffect } from 'react';
import { useWindowsDim } from '@common/hooks/useWindowsDim';
import { Card, Table, Button, Row, Col, message } from 'antd';
import { produce } from 'immer';
import { bsApi } from '@common/api';
import { green } from '@ant-design/colors';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { WANT_TIME } from '@common/constants';

const columnsOrigin = [
  {
    title: '상담번호',
    dataIndex: 'code',
    key: 'code',
    width: 'auto',
    align: 'center',
  },
  {
    title: '고객ID',
    dataIndex: 'username',
    key: 'username',
    width: 160,
    align: 'center',
  },
  {
    title: '고객이름',
    dataIndex: 'name',
    key: 'name',
    width: 160,
    align: 'center',
  },
  {
    title: '고객 전화번호',
    dataIndex: 'mobilePhone',
    key: 'mobilePhone',
    width: 160,
    align: 'center',
  },
  {
    title: '고객 이메일',
    dataIndex: 'email',
    key: 'email',
    width: 100,
    align: 'center',
  },
  {
    title: '희망상담일자',
    dataIndex: 'consultWantDate',
    key: 'consultWantDate',
    width: 100,
    align: 'center',
  },
  {
    title: '희망상담시간',
    dataIndex: 'consultWantTime',
    key: 'consultWantTime',
    width: 100,
    align: 'center',
    render: (v) => WANT_TIME[v],
  },
  {
    title: '상담접수일',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 80,
    align: 'center',
    render: (v) => moment(v).format('YYYY-MM-DD'),
  },
  {
    title: '최종 상담완료일',
    dataIndex: 'updatedOn',
    key: 'updatedOn',
    width: 80,
    align: 'center',
    render: (v) => moment(v).format('YYYY-MM-DD'),
  },
  {
    title: '최근업데이트',
    dataIndex: 'updatedOn',
    key: 'updatedOn',
    width: 80,
    align: 'center',
    render: (v) => moment(v).format('YYYY-MM-DD'),
  },
  {
    title: '정산 확인상태',
    dataIndex: 'isCalculate',
    key: 'isCalculate',
    width: 80,
    align: 'center',
    render: (v) => (v ? '정산완료' : '미정산'),
  },
];

const CacultateDetails = ({ history, match }) => {
  const { params: query } = match || {};
  console.log('pathc:::', query);
  const { width } = useWindowsDim();
  const tableWidth = width - 40;
  const [data, setData] = useState(null);
  const [allCounts, setAllCounts] = useState(null);
  const [basic, setBasic] = useState(null);
  const [updateList, setUpdateList] = useState([]);
  const historyPush = useHistory();
  const fetchData = useCallback(async () => {
    const { status, data: response } = await bsApi.get(`/calculate/by-user/${query?.id}`);
    console.log('response:::', response);
    setData({
      rows: response.lists || [],
    });
    setAllCounts(response.allCounts);
    setBasic(response.basic);
  }, [query]);

  const getMyExcel = async () => {
    const { data: response } = await bsApi.get('/consultations/file-download', {
      params: query,
      headers: { Accept: 'application/hal+json' },
      responseType: 'blob',
    });
    FileSaver.saveAs(response, '상담관리.xlsx');
  };

  const columns = produce(columnsOrigin, (draft) => {
    const cwidths = draft.reduce((a, c) => {
      if (c.width !== 'auto') {
        return a + c.width;
      } else {
        return a + 300;
      }
    }, 0);

    if (cwidths > tableWidth) {
      draft.forEach((v) => {
        if (v.width === 'auto') {
          v.width = 300;
        }
      });
    }
  });

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setUpdateList(selectedRows);
    },
  };

  const saveAgreeCalculate = async () => {
    if (updateList.length > 0) {
      await bsApi.put(`/calculate/update/${query?.id}`, updateList);
      alert('정산이 완료되었습니다.');
      setUpdateList([]);
      setData(null);
    } else {
      alert('선택 된 정산이 없습니다.');
    }
  };

  useEffect(() => {
    if (!data) {
      fetchData().catch(console.log);
    }
  }, [data]);

  let fullWidth = 0;
  columns.forEach((v) => (fullWidth += v.width));

  return (
    <div className={'pageWrapper'}>
      <h2>정산 관리</h2>
      <Card>
        <h5>기본정보</h5>
        <table className={'details-table'}>
          <tbody>
            <tr>
              <th>아이디</th>
              <td>{basic?.username} </td>
              <th>이름</th>
              <td>{basic?.name} </td>
            </tr>
            <tr>
              <th>이메일</th>
              <td>{basic?.email} </td>
              <th>전화번호</th>
              <td>{basic?.mobilePhone} </td>
            </tr>
            <tr>
              <th>센터명</th>
              <td>{basic?.centerName} </td>
              <th>센터코드</th>
              <td>{basic?.centerCode} </td>
            </tr>
          </tbody>
        </table>
        <br />
        <Row style={{ marginTop: 25 }}>
          <Col xs={24}>
            <table className={'details-table'}>
              <thead>
                <tr>
                  <th colSpan={4}>{moment().format('YYYY-MM-DD')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>총 상담건수</th>
                  <td>{allCounts?.allCount} </td>
                  <th>총 합계</th>
                  <td>{allCounts?.allPrice} </td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
        <Row style={{ marginTop: 25 }}>
          <Col xs={12}>
            <Button
              htmlType="button"
              onClick={getMyExcel}
              type={'success'}
              style={{
                backgroundColor: green[6],
                borderColor: green[7],
                color: 'white',
                fontWeight: 'bold',
              }}
            >
              엑셀 다운로드
            </Button>
          </Col>
          <Col xs={12} style={{ textAlign: 'right' }}>
            <Button onClick={saveAgreeCalculate}>정산동의 확인</Button>
          </Col>
        </Row>
        <Row style={{ marginTop: 25 }}>
          <Col xs={24}>
            <Table
              rowSelection={{
                ...rowSelection,
              }}
              rowKey={(record) => record.id}
              columns={columns}
              dataSource={data?.rows || []}
              scroll={{ x: fullWidth }}
              bordered
            />
          </Col>
        </Row>
      </Card>
    </div>
  );
};
export default CacultateDetails;
