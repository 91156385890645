import {
  ApartmentOutlined,
  AppstoreOutlined,
  AreaChartOutlined,
  BellOutlined,
  CarryOutOutlined,
  DashboardFilled,
  FormatPainterOutlined,
  MessageOutlined,
  PhoneOutlined,
  UserOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import MemberLeaveIndex from '~/src/pages/members/leave';
import MemberIndex from '~/src/pages/members';
import MemberDetails from '~/src/pages/members/details';
import ConsultationDetails from '~/src/pages/consultations/details';
import ConsultationIndex from '~/src/pages/consultations';
import ManagerIndex from '~/src/pages/managers';
import ManagerDetails from '~/src/pages/managers/details';
import NoticeIndex from '~/src/pages/notices';
import NoticeDetails from '~/src/pages/notices/details';
import AnalyticsCountIndex from '~/src/pages/analytics/count';
import AnalyticsRatingIndex from '~/src/pages/analytics/rating';
import CodesRmForm from '~/src/pages/codes/rm/form';
import CodeRMIndex from '~/src/pages/codes/rm';
import CodesCenterForm from '~/src/pages/codes/center/form';
import CodeCenterIndex from '~/src/pages/codes/center';
import CalculatesIndex from '~/src/pages/calculates';
import CacultateDetails from '~/src/pages/calculates/details';
import FacilityIndex from '~/src/pages/facilities';
import FacilityDetails from '~/src/pages/facilities/details';
import PushIndex from '~/src/pages/pushes';
import PushDetails from '~/src/pages/pushes/details';
import CallIndex from '~/src/pages/calls';
import TodoRiskIndex from '~/src/pages/todo/risk';
import TodoRiskDetails from '~/src/pages/todo/risk/details';

import TodoMessageIndex from '~/src/pages/todo/message';
import TodoMessageDetails from '~/src/pages/todo/message/details';

import TodoResourceIndex from '~/src/pages/todo/resource';
import TodoResourceDetails from '~/src/pages/todo/resource/details';
import Categorys from '~/src/pages/todo/detail/category';
import CategorySub from '~/src/pages/todo/detail/categorySub';

export default [
  {
    path: '/members',
    name: '회원관리',
    icon: UserOutlined,
    roles: ['ADMIN'],
    component: MemberIndex,
    child: [
      {
        path: '/members/active',
        name: '회원관리',
        roles: ['ADMIN'],
        component: MemberIndex,
      },
      {
        path: '/members/leave',
        name: '탈퇴회원관리',
        roles: ['ADMIN'],
        component: MemberLeaveIndex,
      },
      {
        path: '/members/:id',
        name: '회원상세조회',
        roles: ['ADMIN'],
        component: MemberDetails,
        hide: true,
      },
    ],
  },
  {
    path: '/consultations',
    name: '상담관리',
    icon: DashboardFilled,
    roles: ['ADMIN', 'CENTER_MANAGER', 'CONSULTANT', 'RELATIONSHIP_MANAGER', 'PRO_CONSULTANT'],
    component: ConsultationIndex,
    child: [
      {
        path: '/consultations/:id',
        name: '상담관리 상세조회',
        roles: ['ADMIN', 'CENTER_MANAGER', 'CONSULTANT', 'RELATIONSHIP_MANAGER', 'PRO_CONSULTANT'],
        component: ConsultationDetails,
        hide: true,
      },
    ],
  },
  {
    name: '상담원 관리',
    path: '/managers',
    icon: ApartmentOutlined,
    roles: ['ADMIN', 'CENTER_MANAGER', 'RELATIONSHIP_MANAGER'],
    child: [
      {
        route: '/managers/:type/create',
        name: '상담원 생성',
        roles: ['ADMIN', 'CENTER_MANAGER'],
        component: ManagerDetails,
        hide: true,
      },
      {
        route: '/managers/:type/:id',
        name: '상담원 상세',
        roles: ['ADMIN', 'CENTER_MANAGER'],
        component: ManagerDetails,
        hide: true,
      },
      {
        route: '/managers/:types',
        path: '/managers/basic',
        name: '기본상담원',
        roles: ['ADMIN', 'CENTER_MANAGER'],
        component: ManagerIndex,
      },
      {
        route: '/managers/:types',
        path: '/managers/pro',
        name: '전문상담원',
        roles: ['ADMIN', 'RELATIONSHIP_MANAGER'],
        component: ManagerIndex,
      },
    ],
  },
  {
    path: '/notices',
    name: '공지사항',
    icon: BellOutlined,
    roles: ['ADMIN'],
    component: NoticeIndex,
    child: [
      {
        path: '/notices/create',
        name: '공지사항 등록',
        icon: BellOutlined,
        roles: ['ADMIN'],
        hide: true,
        component: NoticeDetails,
      },
      {
        route: '/notices/:id',
        name: '공지사항 상세',
        icon: BellOutlined,
        roles: ['ADMIN'],
        hide: true,
        component: NoticeDetails,
      },
    ],
  },
  {
    path: '/analytics',
    name: '통계',
    icon: AreaChartOutlined,
    roles: ['ADMIN', 'CENTER_MANAGER', 'RELATIONSHIP_MANAGER'],
    child: [
      {
        id: 51,
        parentid: 5,
        path: '/analytics/count',
        name: '상담건수',
        icon: 'dashboard',
        roles: ['ADMIN'],
        component: AnalyticsCountIndex,
      },
      {
        id: 52,
        parentid: 5,
        path: '/analytics/rating',
        name: '상담원 평점',
        icon: 'dashboard',
        roles: ['ADMIN', 'CENTER_MANAGER', 'RELATIONSHIP_MANAGER'],
        component: AnalyticsRatingIndex,
      },
    ],
  },
  {
    path: '/codes',
    name: '코드관리',
    icon: AppstoreOutlined,
    roles: ['ADMIN'],
    child: [
      {
        path: '/codes/rm',
        name: '기관 관리',
        roles: ['ADMIN'],
        component: CodeRMIndex,
      },
      {
        route: '/codes/rm/create',
        name: '기관 등록',
        roles: ['ADMIN'],
        hide: true,
        component: CodesRmForm,
      },
      {
        route: '/codes/rm/:id',
        name: '기관 조회',
        roles: ['ADMIN'],
        hide: true,
        component: CodesRmForm,
      },
      {
        path: '/codes/center',
        name: '센터 관리',
        icon: 'dashboard',
        roles: ['ADMIN'],
        child: [],
        component: CodeCenterIndex,
      },
      {
        route: '/codes/center/create',
        name: '센터 등록',
        roles: ['ADMIN'],
        hide: true,
        component: CodesCenterForm,
      },
      {
        route: '/codes/center/:id',
        name: '센터 조회',
        roles: ['ADMIN'],
        hide: true,
        component: CodesCenterForm,
      },
    ],
  },
  {
    path: '/calculates',
    name: '정산관리',
    icon: CarryOutOutlined,
    roles: ['ADMIN', 'CENTER_MANAGER', 'CONSULTANT'],
    child: [
      {
        route: '/calculates/:id',
        name: '정산관리 상세',
        roles: ['ADMIN', 'CENTER_MANAGER', 'CONSULTANT'],
        component: CacultateDetails,
        hide: true,
      },
    ],
    component: CalculatesIndex,
  },
  {
    path: '/facilities',
    name: '시설관리',
    icon: FormatPainterOutlined,
    roles: ['ADMIN', 'CENTER_MANAGER'],
    component: FacilityIndex,
    child: [
      {
        route: '/facilities/create',
        name: '시설관리 등록',
        roles: ['ADMIN', 'CENTER_MANAGER'],
        component: FacilityDetails,
        hide: true,
      },
      {
        route: '/facilities/:id',
        name: '시설관리 수정',
        roles: ['ADMIN', 'CENTER_MANAGER'],
        component: FacilityDetails,
        hide: true,
      },
    ],
  },
  {
    path: '/pushes',
    name: 'PUSH발송',
    icon: MessageOutlined,
    roles: ['ADMIN'],
    component: PushIndex,
    child: [
      {
        path: '/pushes/create',
        name: 'PUSH 등록',
        roles: ['ADMIN'],
        component: PushDetails,
      },
      {
        route: '/pushes/:id',
        name: 'PUSH 상세',
        roles: ['ADMIN'],
        component: PushDetails,
      },
    ],
  },
  {
    path: '/calls',
    name: '통화이력',
    icon: PhoneOutlined,
    roles: ['ADMIN'],
    component: CallIndex,
  },
  {
    path: '/todo',
    name: '취약점,투두',
    icon: UnorderedListOutlined,
    roles: ['ADMIN'],
    child: [
      {
        path: '/todo/risk',
        name: '취약점',
        roles: ['ADMIN'],
        component: TodoRiskIndex,
      },
      {
        route: '/todo/risk/:id',
        name: '취약점 상세',
        roles: ['ADMIN'],
        component: TodoRiskDetails,
        hide: true,
      },
      {
        path: '/todo/message',
        name: '투두 메세지',
        roles: ['ADMIN'],
        component: TodoMessageIndex,
      },
      {
        route: '/todo/message/:id',
        name: '투두 메세지 상세',
        roles: ['ADMIN'],
        component: TodoMessageDetails,
        hide: true,
      },
      {
        path: '/todo/resource',
        name: '투두 리소스',
        roles: ['ADMIN'],
        component: TodoResourceIndex,
      },
      {
        route: '/todo/resource/:id',
        name: '투두 리소스 상세',
        roles: ['ADMIN'],
        component: TodoResourceDetails,
        hide: true,
      },
      {
        path: '/todo/category',
        name: '투두 카테고리',
        roles: ['ADMIN'],
        component: Categorys,
      },
      {
        route: '/todo/category/sub/:id/:name',
        name: '투두 카테고리 상세',
        roles: ['ADMIN'],
        component: CategorySub,
        hide: true,
      },
    ],
  },
];
