import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useWindowsDim } from '@common/hooks/useWindowsDim';
import { produce } from 'immer';
import { Button, Card, Col, DatePicker, Form, Input, Radio, Row, Select, Table, Tabs } from 'antd';
import { bsApi } from '@common/api';
import { searchItemLayout, ORGARNIZATION } from '@common/constants';
import moment from 'moment';
import FileSaver from 'file-saver';

const { TabPane } = Tabs;

const columnsOrigin = [
  {
    title: '순위',
    dataIndex: 'id',
    key: 'id',
    width: 80,
    fixed: 'left',
    render: (text, record, index) => index + 1,
  },
  {
    title: '구분',
    dataIndex: 'type',
    key: 'type',
    width: 100,
    render: (v) => ORGARNIZATION[v],
  },
  {
    title: '명칭',
    dataIndex: 'centerName',
    key: 'centerName',
    width: 150,
  },
  {
    title: '상담원 ID',
    dataIndex: 'userId',
    key: 'userId',
    width: 150,
  },
  {
    title: '상담원 이름',
    dataIndex: 'userName',
    key: 'userName',
    width: 150,
  },
  {
    title: '상담건수',
    dataIndex: 'allConsultations',
    key: 'allConsultations',
    width: 150,
    sorter: (a, b) => a.allConsultations - b.allConsultations,
    render: (v) => <span>{v}회</span>,
  },
  {
    title: '상담평점',
    dataIndex: 'avgEvaluation',
    key: 'avgEvaluation',
    width: 210,
    sorter: (a, b) => a.avgEvaluation - b.avgEvaluation,
  },
  {
    title: '상담비율',
    dataIndex: 'avgRate',
    key: 'avgRate',
    width: 100,
    render: (v) => <span>{v}%</span>,
  },
];
const initialQuery = {
  searchType: '',
  periodType: 'createdAt',
  keyword: '',
  fromDate: null,
  toDate: null,
  type: '',
};
const AnalyticsRatingIndex = () => {
  const { width } = useWindowsDim();
  const tableWidth = width - 280;
  const form = useRef();
  const columns = produce(columnsOrigin, (draft) => {
    const cwidths = draft.reduce((a, c) => {
      if (c.width !== 'auto') {
        return a + c.width;
      } else {
        return a + 300;
      }
    }, 0);

    if (cwidths > tableWidth) {
      draft.forEach((v) => {
        if (v.width === 'auto') v.width = 300;
      });
    }
  });

  const [data, setData] = useState([]);
  const [query, setQuery] = useState(initialQuery);

  const handleReset = async () => {
    form?.current?.setFieldsValue(initialQuery);
    await handleSubmit(initialQuery);
  };
  //라디오버튼
  const [radio, setRadio] = useState();
  const onChangeOrgani = (e) => {
    setRadio(e.target.value);
  };

  const getMyExcel = async () => {
    const { data: response } = await bsApi.get('/evaluation/excel', {
      params: query,
      headers: { Accept: 'application/hal+json' },
      responseType: 'blob',
    });
    FileSaver.saveAs(response, '상담사별점통계별.xls');
  };

  const fetchData = useCallback(async () => {
    console.log('query:::', query);
    const { status, data: response } = await bsApi.get('/evaluation', { params: query });
    console.log('response::', response);
    setData(response);
  }, [query]);

  const handleSubmit = async (data) => {
    console.log('e....', data);
    setQuery(
      produce((draft) => {
        draft.fromDate = data.dateRange?.[0]?.format('YYYY-MM-DD');
        draft.toDate = data.dateRange?.[1]?.format('YYYY-MM-DD');
        draft.keyword = data.keyword;
        draft.searchType = data.searchType;
        draft.type = data.type === 'all' ? null : data.type;
      }),
    );
  };

  const handleDateRangeSet = (date) => {
    const date1 = moment().add(-date, 'days');
    const date2 = moment();
    form?.current?.setFieldsValue({
      dateRange: [date1, date2],
    });
    form?.current?.submit();
  };

  useEffect(() => {
    fetchData().catch((e) => console.log(e));
  }, [query]);

  let fullWidth = 0;
  columns.forEach((v) => (fullWidth += v.width));

  return (
    <div>
      <h2>통계</h2>
      <Form ref={form} onFinish={handleSubmit}>
        <Card>
          <Form.Item {...searchItemLayout} label="기간검색">
            <Row type={'flex'} gutter={[5, 5]}>
              <Col flex={1}>
                <Form.Item name="dateRange" noStyle initialValue={null}>
                  <DatePicker.RangePicker
                    style={{ marginRight: 5 }}
                    disabled={[false, false]}
                    allowClear
                    allowEmpty={[true, true]}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Button.Group>
                  <Button onClick={() => handleDateRangeSet(0)}>오늘</Button>
                  <Button onClick={() => handleDateRangeSet(7)}>7일</Button>
                  <Button onClick={() => handleDateRangeSet(30)}>30일</Button>
                  <Button onClick={() => handleDateRangeSet(365)}>1년</Button>
                </Button.Group>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item {...searchItemLayout} label="구분검색">
            <Row>
              <Col flex={1}>
                <Form.Item name="type" initialValue="" noStyle>
                  <Radio.Group defaultValue={''} onChange={onChangeOrgani}>
                    <Radio value={''}>전체</Radio>
                    <Radio value={'CENTER'}>센터명</Radio>
                    <Radio value={'INSTITUTION'}>기관별</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item {...searchItemLayout} label="상세검색">
            <Row>
              <Col>
                <Form.Item name="searchType" noStyle>
                  {/* <Select style={{ width: 150, marginRight: 5 }}>
                    <Select.Option value="">전체</Select.Option>
                    <Select.Option value="center.name">센터명</Select.Option>
                    <Select.Option value="center.code">센터코드</Select.Option>
                    <Select.Option value="institution.name">기관명</Select.Option>
                    <Select.Option value="institution.code">기관코드</Select.Option>
                  </Select> */}
                  {radio === 'CENTER' && (
                    <Select style={{ width: 150, marginRight: 5 }} placeholder="전체" allowClear>
                      <Select.Option value="center.name">센터명</Select.Option>
                      <Select.Option value="center.code">센터코드</Select.Option>
                    </Select>
                  )}
                  {radio === 'INSTITUTION' && (
                    <Select style={{ width: 150, marginRight: 5 }} placeholder="전체" allowClear>
                      <Select.Option value="institution.name">기관명</Select.Option>
                      <Select.Option value="institution.code">기관코드</Select.Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col flex={1}>
                <Form.Item name="keyword" initialValue="">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
        </Card>
        <div style={{ marginTop: 10 }}>
          <Row>
            <Col xs={12}>
              <Button htmlType="button" type={'success'} onClick={getMyExcel}>
                엑셀 다운로드
              </Button>
            </Col>
            <Col style={{ textAlign: 'right' }} xs={12}>
              <Button htmlType="button" onClick={handleReset}>
                초기화
              </Button>{' '}
              <Button htmlType="submit" type="primary">
                검색
              </Button>
            </Col>
          </Row>
        </div>
        <div style={{ marginTop: 25 }} bodystyle={{ padding: 0 }}>
          <Table
            rowKey={(record) => record.id}
            columns={columns}
            dataSource={data}
            scroll={{ x: fullWidth }}
            bordered
          />
        </div>
      </Form>
    </div>
  );
};

export default AnalyticsRatingIndex;
