import React from 'react';

import { Button, Col, Form, Input, message as Message, Row } from 'antd';
import nookies from 'nookies';

import { Eye, User } from 'react-feather';
import styled from 'styled-components';

import { useDispatch } from 'react-redux';
import * as ActionAuth from '@data/auth/actions';

import { authApi, setToken } from '@common/api';
import { Link } from 'react-router-dom';

const Content = styled.div`
  max-width: 800px;
  z-index: 2;
  flex: 1;
  overflow: hidden;
`;

const LoginCard = styled.div`
  background: #fff;
  border-radius: 0px;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.3s ease-in-out;
  display: flex;
  width: 90%;
  max-width: 800px;
`;

const Signin = ({ setPrincipal }) => {
  const dispatch = useDispatch();

  const handleLogin = async (params) => {
    try {
      const args = new FormData();
      args.append('username', params.username);
      args.append('password', params.password);
      args.append('grant_type', 'password');

      const { data } = await authApi.post('/api/auth/login', args);
      if (data.accessToken) {
        setToken({
          accessToken: data.accessToken,
        });

        const options = {
          maxAge: 30 * 24 * 60 * 60,
          path: '/',
        };

        nookies.set(null, 'accessToken', data.accessToken, options);

        await dispatch(ActionAuth.me());
        alert('Welcome.');
      }
    } catch (e) {
      if (e?.response?.status === 500) {
        alert('Network Error');
      }
      alert('아이디 혹은 비밀번호가 일치하지 않습니다.');
    }
  };

  return (
    <div
      className="px-3 signin-bg mh-page"
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        borderWidth: 1,
        overflow: 'hidden',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <LoginCard>
        <Row type={'flex'} style={{ width: '100%' }}>
          <Col span={12} xs={24} sm={12} className={'deco'} />
          <Col span={12} xs={24} sm={12} style={{ padding: '50px 30px', minHeight: 450 }}>
            <div style={{ marginBottom: 40 }}>
              <img style={{ maxHeight: 20 }} src={'/bi_dark.png'} />
              <p style={{ fontSize: 12, paddingLeft: 5, margin: '5px 0px' }}>상담원 로그인이 필요합니다.</p>
            </div>

            <Form layout="vertical" onFinish={handleLogin} style={{ paddingLeft: 5, paddingRight: 5 }}>
              <Form.Item
                style={{ marginBottom: 15 }}
                label="사용자 아이디"
                name="username"
                rules={[{ required: true, message: '아이디를 입력하세요' }]}
              >
                <Input
                  size="large"
                  prefix={<User size={16} strokeWidth={1} style={{ color: 'rgba(0,0,0,.25)' }} />}
                  type="text"
                />
              </Form.Item>

              <Form.Item
                style={{ marginBottom: 25 }}
                label="비밀번호"
                name="password"
                rules={[{ required: true, message: '비밀번호를 입력하세요.' }]}
              >
                <Input
                  size="large"
                  prefix={<Eye size={16} strokeWidth={1} style={{ color: 'rgba(0,0,0,.25)' }} />}
                  type="password"
                />
              </Form.Item>

              <Row type={'flex'} align={'middle'} style={{ paddingBottom: 15 }}>
                <Col flex={1}>
                  <Link to={'/find'}>비밀번호가 기억나지 않나요?</Link>
                </Col>
                <Col>
                  <Button type="default" htmlType="submit" size={'large'} style={{ fontWeight: 'bold' }}>
                    로그인
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </LoginCard>
    </div>
  );
};

export default Signin;
