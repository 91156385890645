import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { GlobalStyles } from '@components/styles/GlobalStyles';
import { Provider } from 'react-redux';
import AppProvider from '@components/shared/AppProvider';
import { applyMiddleware, createStore } from 'redux';
import AppReducer from '~/src/reducers';
import thunkMiddleware from 'redux-thunk';
import nookies from 'nookies';
import { setToken } from '@common/api';
import * as ActionAuth from '@data/auth/actions';
import { CLEAR_PRINCIPAL } from '@data/auth/actionTypes';
import Routes from '~/src/lib/routes';
import Page from '@components/Page';
import { BrowserRouter } from 'react-router-dom';

const store = createStore(AppReducer, applyMiddleware(...[thunkMiddleware]));
const MainModule = hot(Routes);

const App = (props) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      const cookies = nookies.get(null);
      setToken(cookies);

      try {
        await store.dispatch(ActionAuth.me());
      } catch (e) {
        if (e?.response?.status === 400 || e?.response?.status === 401) {
          setToken({});
          const options = {
            maxAge: 30 * 24 * 60 * 60,
            path: '/',
          };
          nookies.set(null, 'accessToken', '', options);
          store.dispatch({ type: CLEAR_PRINCIPAL });
        }
      }
      setLoading(false);
    };
    loadData().catch(console.log);
  }, []);

  return (
    <>
      <GlobalStyles />
      <Provider store={store}>
        {loading ? (
          <div />
        ) : (
          <AppProvider>
            <BrowserRouter>
              <MainModule {...props} />
            </BrowserRouter>
          </AppProvider>
        )}
      </Provider>
    </>
  );
};

export default App;
//
