import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useWindowsDim } from '@common/hooks/useWindowsDim';
import { Table, Row, Col, Button, Form, Input, Card, Select } from 'antd';
import produce from 'immer';
import { bsApi } from '@common/api';
import { useAppState } from '@components/shared/AppProvider';
import querystring from 'querystring';
import { Link } from 'react-router-dom';
import { searchItemLayout } from '@common/constants';
import useListForm from '@common/hooks/useListForm';
const columnsOrigin = [
  {
    title: 'NO',
    dataIndex: 'rowNum',
    key: 'rowNum',
    align: 'center',
    width: 60,
    fixed: 'left',
  },
  {
    title: '대분류',
    dataIndex: 'bigCategory',
    key: 'bigCategory',
    width: 130,
    fixed: 'left',
    render: (text, record, index) => {
      return record?.bigCategory?.bigCategory;
    },
  },
  {
    title: '소분류',
    dataIndex: 'smallCategory',
    key: 'smallCategory',
    width: 130,
    render: (text, record, index) => {
      return record?.smallCategory?.smallCategory;
    },
  },
  {
    title: '명칭',
    dataIndex: 'name',
    key: 'name',
    align: 'left',
    width: 550,
  },
  {
    title: '기관명',
    dataIndex: 'organizationName',
    key: 'organizationName',
    align: 'left',
    width: 180,
    render: (text) => {
      return (
        <div style={{ whiteSpace: 'nowrap', width: '160px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {' '}
          {text}
        </div>
      );
    },
  },
  {
    title: 'URL/설치주소',
    dataIndex: 'serviceUrl',
    key: 'serviceUrl',
    align: 'left',
    width: 180,
    render: (text) => {
      return (
        <div style={{ whiteSpace: 'nowrap', width: '150px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {' '}
          {text}
        </div>
      );
    },
  },
  {
    title: '대표전화',
    dataIndex: 'mainCall',
    key: 'mainCall',
    align: 'center',
    width: 180,
  },
  {
    title: '주소',
    dataIndex: 'address',
    key: 'address',
    align: 'left',
    width: 180,
    render: (text) => {
      return (
        <div style={{ whiteSpace: 'nowrap', width: '150px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {' '}
          {text}
        </div>
      );
    },
  },
  {
    title: '서비스개요',
    dataIndex: 'serviceSummary',
    key: 'serviceSummary',
    align: 'left',
    width: 180,
    render: (text) => {
      return (
        <div style={{ whiteSpace: 'nowrap', width: '150px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {' '}
          {text}
        </div>
      );
    },
  },
  {
    title: '관리',
    key: 'operation',
    align: 'center',
    width: 80,
    fixed: 'right',
  },
];

const initialQuery = {
  page: 0,
  limit: 10,
  searchType: '',
  periodType: 'createdAt',
  keyword: '',
  bigCategoryId: undefined,
  smallCategoryId: undefined,
};

const TodoResourceIndex = (props) => {
  const fetchData = useCallback(async (query) => {
    const { status, data: response } = await bsApi.get('/todo/todo-resources', { params: query });

    return {
      rows: response?.content || [],
      pagination: {
        current: response?.number + 1,
        pageSize: response?.size,
        total: response?.totalElements || 0,
      },
    };
  }, []);

  const { form, tableProps, onFormFinish, onFormReset } = useListForm({
    ...props,
    fetchData,
    initialQuery,
    columnsOrigin,
    applyColumns: (draft, data) => {
      draft[0].render = (text, record, index) => {
        const page = data?.pagination;
        return page?.total - page?.pageSize * (page?.current - 1) - index;
      };
      draft[columnsOrigin?.length - 1].render = (record) => (
        <Link to={`/todo/resource/${record.id}`}>
          <Button htmlType={'button'} size={'small'}>
            상세
          </Button>
        </Link>
      );
    },
  });

  return (
    <div>
      <h2>투두 리소스 관리</h2>
      <Form ref={form} onFinish={onFormFinish}>
        <Card>
          {/*<Form.Item {...searchItemLayout} label="분류별">*/}
          {/*  <Row type={'flex'} gutter={[5, 5]}>*/}
          {/*    <Col>*/}
          {/*      <Form.Item name="bigCategoryId" noStyle>*/}
          {/*        <Select style={{ width: 150, marginRight: 5 }} placeholder={'대분류'} allowClear>*/}
          {/*          <Select.Option value="name">대분류</Select.Option>*/}
          {/*        </Select>*/}
          {/*      </Form.Item>*/}
          {/*    </Col>*/}
          {/*    <Col flex={1}>*/}
          {/*      <Form.Item name="smallCategoryId" noStyle>*/}
          {/*        <Select style={{ width: 150, marginRight: 5 }} placeholder={'소분류'} allowClear>*/}
          {/*          <Select.Option value="name">대분류</Select.Option>*/}
          {/*        </Select>*/}
          {/*      </Form.Item>*/}
          {/*    </Col>*/}
          {/*  </Row>*/}
          {/*</Form.Item>*/}
          <Form.Item {...searchItemLayout} label="직접검색">
            <Row type={'flex'} gutter={[5, 5]}>
              <Col>
                <Form.Item name="searchType" noStyle>
                  <Select style={{ width: 150, marginRight: 5 }} placeholder={'전체'} allowClear>
                    <Select.Option value="name">명칭</Select.Option>
                    <Select.Option value="organizationName">기관명</Select.Option>
                    <Select.Option value="mainCall">대표전화</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col flex={1}>
                <Form.Item name="keyword" noStyle>
                  <Input placeholder="검색어를 입력하세요." />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
        </Card>
        <div style={{ marginTop: 10 }}>
          <Row>
            <Col style={{ textAlign: 'right' }} xs={24}>
              <Button htmlType="button" onClick={onFormReset}>
                초기화
              </Button>{' '}
              <Button htmlType="submit" type="primary">
                검색
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
      <br />
      <br />
      <Table
        {...tableProps}
        rowKey={(record) => record.id}
        footer={() => (
          <Row>
            <Col>
              <Link to={`/todo/resource/add`}>
                <Button style={{ marginLeft: 5 }} type={'primary'}>
                  등록
                </Button>
              </Link>
            </Col>
            <Col>
              <Link to={`/todo/category`}>
                <Button style={{ marginLeft: 5 }}>카테고리 정보수정</Button>
              </Link>
            </Col>
          </Row>
        )}
      />
    </div>
  );
};
export default TodoResourceIndex;
