import React, { useEffect, useState, useCallback } from 'react';
import { bsApi } from '@common/api';
import { formatPrice2 } from '@common/helpers';

import { Card, Descriptions, Row, Col } from 'antd';
import styled from 'styled-components';
import moment from 'moment';
import { ERROR_MSG, HOUSE_MODE, OLD_COST } from '@common/constants';

const VerticalDescritions = styled(Descriptions)`
  .ant-descriptions-item-content:first-child {
    padding: 0;
  }
`;

const 재무달성결과 = ({ 재무달성도 }) => {
  return (
    <Card title="고객 속성 정보(재무목표달성도 진행시)">
      <Descriptions bordered>
        <Descriptions.Item label="속성 등록일">{moment(재무달성도?.createdBy).format('YYYY-MM-DD')} </Descriptions.Item>
      </Descriptions>
      <br />
      <VerticalDescritions bordered layout="vertical">
        <Descriptions.Item span={4} label="기본 진단항목">
          <Descriptions bordered layout="horizontal">
            {재무달성도?.answerList?.map((v, v_i) => {
              return (
                <Descriptions.Item label={`${v.question}`} span={4} key={`finances-goal-${v.key}`}>
                  {v.key !== 2 ? (
                    <>
                      {v?.answers?.map((x, x_i) => {
                        if (v.answers.length - 1 === x_i) {
                          return x.answer;
                        }
                        return x.answer + ', ';
                      })}
                    </>
                  ) : (
                    <Descriptions bordered layout="horizontal">
                      {v?.answers?.filter((x) => x.answer === '자녀') && (
                        <Descriptions.Item label={`자녀 학령기`} span={4}>
                          {v?.answers?.map((g, g_i) => {
                            let result = null;
                            if (g.subAnswers != null) {
                              result = g?.subAnswers?.map((h, h_i) => {
                                if (g.subAnswers.length - 1 === h_i) {
                                  return h.answer;
                                }
                                return h.answer + ', ';
                              });
                            }
                            return result;
                          })}
                        </Descriptions.Item>
                      )}
                      <Descriptions.Item label={`가족 구성원`} span={4}>
                        {v?.answers?.map((c, c_i) => {
                          if (c?.answer !== '자녀') {
                            if (v?.answers.length === c_i) {
                              return c?.answer;
                            } else {
                              return c?.answer + ', ';
                            }
                          } else {
                            return c.answer + '(' + c.subAnswers.length + ')';
                          }
                        })}
                      </Descriptions.Item>
                    </Descriptions>
                  )}
                </Descriptions.Item>
              );
            })}
          </Descriptions>
        </Descriptions.Item>
      </VerticalDescritions>
    </Card>
  );
};

const 금융건강진단결과 = ({ 금융건강진단 }) => {
  return (
    <Card title="기본 진단정보" style={{ marginTop: 25 }}>
      <VerticalDescritions bordered layout="vertical">
        <Descriptions.Item span={4} label="기본 진단항목">
          <Descriptions bordered layout="horizontal">
            {금융건강진단 &&
              금융건강진단?.answerList?.map((v, index) => {
                return (
                  <Descriptions.Item label={`${v.question}`} span={4} key={`question_${v.id}`}>
                    <Row>
                      <Col xs={24}>
                        {v?.answers
                          ? v?.answers?.map((x, i) => {
                              if (v.answers.length - 1 === i) {
                                return x.answer;
                              }
                              return x.answer + ', ';
                            })
                          : '진단 결과 정보 없음.'}
                      </Col>
                      <Col xs={24}>
                        {v?.answers &&
                          v?.answers?.map((x, i) => {
                            if (x.etc != null) {
                              return x.etc;
                            }
                          })}
                      </Col>
                    </Row>
                  </Descriptions.Item>
                );
              })}
          </Descriptions>
        </Descriptions.Item>
      </VerticalDescritions>
      <br />
      <VerticalDescritions bordered layout="vertical">
        <Descriptions.Item span={4} label="기본 진단 결과">
          <Descriptions bordered layout="horizontal">
            <Descriptions.Item label="진단결과 유형" span={4}>
              {금융건강진단?.result}
            </Descriptions.Item>
            <Descriptions.Item label="진단결과 설명" span={4}>
              {`(${금융건강진단?.result})으로 다음과 같은 금융문제와 니즈를 답변하였습니다.`} <br />
              {(금융건강진단?.result !== '기타 문제 형' ||
                금융건강진단?.result !== '금융 회피 형' ||
                금융건강진단?.result !== '판정불가') &&
                금융건강진단?.answerList?.map((v, v_i) => {
                  v?.answers?.map((x, x_i) => {
                    if (v.length - 1 === v_i && v.answers.length - 1 === x_i) {
                      return v.answer;
                    } else {
                      return v.answer + ',';
                    }
                  });
                })}{' '}
              <br />좀 더 정확한 진단을 위해 정밀진단을 시작하시기 바랍니다.
            </Descriptions.Item>
          </Descriptions>
        </Descriptions.Item>
      </VerticalDescritions>
    </Card>
  );
};

const BasicInfo = (props) => {
  const { queryId } = props;
  const [data, setData] = useState(null);
  const [재무달성도, set재무달성도] = useState(null);
  const [금융건강진단, set금융건강진단] = useState(null);
  const [희망노후생활비, set희망노후생활비] = useState(null);
  const [myHouse, setMyHouse] = useState(null);
  const fetchData = useCallback(async (queryId) => {
    const { status, data: response } = await bsApi.get('/consultations/' + queryId);
    // response 데이터에 재무달성도, 희망노후생활비 항목 없음

    console.log('>>>>', response);

    setData(response);
    set재무달성도(response.재무달성계획);
    set금융건강진단(response.surveyBasic);
    set희망노후생활비(response.wantWornOutCost);
    setMyHouse(response.houses);
  }, []);
  useEffect(() => {
    if (data === null) {
      fetchData(queryId);
    }
  }, [data, 재무달성도, 금융건강진단, 희망노후생활비]);

  return (
    <div>
      {data ? (
        <>
          <div className={'pageWrapper'}>
            <Card>
              <h5>기본 정보</h5>
              <table className={'details-table'}>
                <colgroup>
                  <col width={'150'} />
                  <col width={'auto'} />
                  <col width={'150'} />
                  <col width={'auto'} />
                </colgroup>
                <tbody>
                  <tr>
                    <th>아이디</th>
                    <td>{data.createdBy.username}</td>
                    <th>이름</th>
                    <td>{data.createdBy.name}</td>
                  </tr>
                  <tr>
                    <th>생년월일</th>
                    <td>{data.createdBy.birthday}</td>
                    <th>이메일</th>
                    <td>{data.createdBy.email}</td>
                  </tr>
                  <tr>
                    <th>휴대폰 번호</th>
                    <td colSpan={3}>{data.createdBy.mobilePhone}</td>
                  </tr>
                </tbody>
              </table>

              <h5 style={{ marginTop: 40 }}>기본 정보</h5>
              <table className={'details-table'}>
                <colgroup>
                  <col width={'150'} />
                  <col width={'auto'} />
                  <col width={'150'} />
                  <col width={'auto'} />
                </colgroup>
                <tbody>
                  <tr>
                    <th>진단 완료일</th>
                    <td colSpan={3}>{moment(data?.금융건강진단표?.createdBy).format('YYYY-MM-DD hh:mm')} </td>
                  </tr>
                </tbody>
              </table>

              <h5 style={{ marginTop: 40 }}>기본 진단정보</h5>
              <table className={'details-table'}>
                <colgroup>
                  <col width={'350'} />
                  <col width={'auto'} />
                </colgroup>
                <tbody>
                  <tr>
                    <th colSpan={2} style={{ textAlign: 'center' }}>
                      기본 진단항목
                    </th>
                  </tr>

                  {금융건강진단 &&
                    금융건강진단?.answerList?.map((v, index) => {
                      return (
                        <>
                          <tr>
                            <th rowSpan={2}>{v.question}</th>
                            <td>
                              {v?.answers
                                ? v?.answers?.map((x, i) => {
                                    if (v.answers.length - 1 === i) {
                                      return x.answer;
                                    }
                                    return x.answer + ', ';
                                  })
                                : '진단 결과 정보 없음.'}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {v?.answers &&
                                v?.answers?.map((x, i) => {
                                  if (x.etc != null) {
                                    return x.etc;
                                  }
                                })}
                            </td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </table>

              <table className={'details-table'} style={{ marginTop: 10 }}>
                <colgroup>
                  <col width={'150'} />
                  <col width={'auto'} />
                </colgroup>
                <tbody>
                  <tr>
                    <th colSpan={2} style={{ textAlign: 'center' }}>
                      기본 진단 결과
                    </th>
                  </tr>
                  <tr>
                    <th>진단결과 유형</th>
                    <td>{금융건강진단?.result}</td>
                  </tr>
                  <tr>
                    <th>진단결과 설명</th>
                    <td>
                      {`(${금융건강진단?.result})으로 다음과 같은 금융문제와 니즈를 답변하였습니다.`} <br />
                      {(금융건강진단?.result !== '기타 문제 형' ||
                        금융건강진단?.result !== '금융 회피 형' ||
                        금융건강진단?.result !== '판정불가') &&
                        금융건강진단?.answerList?.map((v, v_i) => {
                          v?.ƒ?.map((x, x_i) => {
                            if (v.length - 1 === v_i && v.answers.length - 1 === x_i) {
                              return v.answer;
                            } else {
                              return v.answer + ',';
                            }
                          });
                        })}{' '}
                      <br />좀 더 정확한 진단을 위해 정밀진단을 시작하시기 바랍니다.
                    </td>
                  </tr>
                </tbody>
              </table>

              {data?.report && (
                <>
                  <h5 style={{ marginTop: 40 }}>금융건강진단 결과</h5>
                  <table className={'details-table'}>
                    <colgroup>
                      <col width={'150'} />
                      <col width={'auto'} />
                      <col width={'150'} />
                      <col width={'auto'} />
                    </colgroup>
                    <tbody>
                      <tr>
                        <th>진단결과</th>
                        <td colSpan={3}>
                          <a
                            target={'_blank'}
                            onClick={() => alert('PDF생성과정에 시간이 걸릴 수 있습니다.\n잠시만 기다려주세요')}
                            href={`https://bankscan.io/api/pdf/${data?.report?.id}`}
                          >
                            진단결과.PDF
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </>
              )}

              <h5 style={{ marginTop: 40 }}>추가 정보</h5>
              <table className={'details-table'}>
                <colgroup>
                  <col width={'150'} />
                  <col width={'auto'} />
                </colgroup>
                <tbody>
                  <tr>
                    <th colSpan={2}>추가 입력 사항</th>
                  </tr>
                  <tr>
                    <th>희망 노후 생활비</th>
                    <td>
                      {희망노후생활비?.decrepitudeType === 'two' ? '2인가구' : '1인가구'} (
                      {OLD_COST[희망노후생활비?.cost]}
                      {`월 ${formatPrice2(희망노후생활비?.cost)} 원`})
                    </td>
                  </tr>
                  <tr>
                    <th>소유 부동산</th>
                    <td>
                      {myHouse &&
                        myHouse?.map((house) => {
                          return (
                            <div key={`house_data_${house.id}`}>
                              <span>
                                {HOUSE_MODE.map((v) => {
                                  if (v.mode === house?.mode && v.category === house?.category) {
                                    return v.label;
                                  }
                                })}{' '}
                                {`(${formatPrice2(house?.price)} 원)`}
                              </span>
                              <br />
                              <span style={{ color: '#898989' }}>{house.name}</span>
                            </div>
                          );
                        })}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Card>
          </div>
        </>
      ) : (
        <p> {ERROR_MSG['NONE_DATA']} </p>
      )}
    </div>
  );
};
export default BasicInfo;
