import React from 'react';

import { Button, Col, Form, Input, message as Message, Row } from 'antd';
import nookies from 'nookies';

import { Eye, User } from 'react-feather';
import styled from 'styled-components';

import { useDispatch } from 'react-redux';
import * as ActionAuth from '@data/auth/actions';

import { authApi, setToken } from '@common/api';
import { Link } from 'react-router-dom';

const Content = styled.div`
  max-width: 800px;
  z-index: 2;
  flex: 1;
`;

const LoginCard = styled.div`
  background: #fff;
  border-radius: 0px;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.3s ease-in-out;
`;

const Find = ({ setPrincipal }) => {
  const dispatch = useDispatch();

  const handleLogin = async (params) => {
    try {
      const { data } = await authApi.post('/api/accounts/find', {
        email: params.email,
        username: params.username,
      });

      alert('이메일이 전송되었습니다.');
      location.href = '/';
    } catch (e) {
      if ([400, 401].includes(e.response?.status)) {
        alert('아이디 혹은 이메일이 일치하지 않습니다.');
      } else {
        alert(e?.response);
      }
    }
  };

  return (
    <Row
      type="flex"
      align="middle"
      justify={'center'}
      className="px-3 signin-bg mh-page"
      style={{ minHeight: '100vh', borderWidth: 1 }}
    >
      <Content>
        <LoginCard>
          <Row type={'flex'}>
            <Col span={12} xs={24} sm={12} className={'deco'} />
            <Col span={12} xs={24} sm={12} style={{ padding: '50px 30px', minHeight: 450 }}>
              <div style={{ marginBottom: 40 }}>
                <img style={{ maxHeight: 20 }} src={'/bi_dark.png'} />
                <p style={{ fontSize: 12, paddingLeft: 0, margin: '15px 0px 5px' }}>
                  회원정보에 등록된 이메일과 아이디를 입력하세요.
                  <br />
                  임시 비밀번호를 이메일로 발송해 드립니다.
                </p>
              </div>

              <Form layout="vertical" onFinish={handleLogin} style={{ paddingLeft: 5, paddingRight: 5 }}>
                <Form.Item
                  style={{ marginBottom: 15 }}
                  label="사용자 아이디"
                  name="username"
                  rules={[{ required: true, message: '아이디를 입력하세요' }]}
                >
                  <Input
                    size="large"
                    prefix={<User size={16} strokeWidth={1} style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="text"
                  />
                </Form.Item>

                <Form.Item
                  style={{ marginBottom: 25 }}
                  label="이메일"
                  name="email"
                  rules={[{ required: true, message: '이메일주소를 입력하세요.' }]}
                >
                  <Input size="large" prefix={<Eye size={16} strokeWidth={1} style={{ color: 'rgba(0,0,0,.25)' }} />} />
                </Form.Item>

                <Row type={'flex'} align={'middle'} style={{ paddingBottom: 15 }}>
                  <Col>
                    <Button type="default" htmlType="submit" size={'large'} style={{ fontWeight: 'bold' }}>
                      확인
                    </Button>
                  </Col>
                  <Col flex={1} style={{ textAlign: 'right' }}>
                    <Link to={'/'}>로그인 페이지로 이동</Link>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </LoginCard>
      </Content>
    </Row>
  );
};

export default Find;
