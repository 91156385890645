import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Input, message, Radio, Row, Alert } from 'antd';
import { bsApi } from '@common/api';
import moment from 'moment';
import TextArea from 'antd/es/input/TextArea';

const MemberDetails = ({ history, match }) => {
  const { params } = match || {};
  const [isLeaved, setLeaved] = useState(false); //탈퇴 여부
  const handleSubmit = async (data) => {
    try {
      if (data.isLeaved === true) {
        if (confirm('탈퇴 처리를 진행하시겠습니까?') === true) {
          await bsApi.put(`/members/delete/${params?.id}`, data);
          alert('회원 탈퇴가 정상적으로 완료되었습니다.');
          handleBack();
          return;
        } else {
          //취소
          return;
        }
      }
      await bsApi.put(`/members/${params?.id}`, data);
      alert('저장되었습니다.');
      handleBack();
    } catch (e) {
      console.log('e:::', e);
      alert('서버 오류입니다. 관리자에게 문의해 주세요');
    }
  };

  const handleBack = async () => {
    if (confirm('게시물을 저장하지 않고 목록으로 돌아갑니다.') === true) {
      await history.goBack();
    } else {
      return;
    }
  };

  const [data, setData] = useState();

  //탈퇴 선택. 탈퇴 선택이면 true
  const onLeaveChange = (e) => {
    console.log(e.target.value);
    let choice = e.target.value;
    // setData('탈퇴 타입, 탈퇴 이유');
    setData({ ...data, leaveType: '', leaveReason: '' });

    //정상 버튼 선택하면
    if (choice === false) {
      console.log('ddd');
      setLeaved(!isLeaved);
    }
    // setData();
    setLeaved(e.target.value);
  };
  console.log(data);

  useEffect(() => {
    const loadData = async () => {
      const { data } = await bsApi.get(`/members/${params?.id}`);
      setData(data);
      setLeaved(data?.isLeaved);
      console.log(data);
    };

    loadData().catch((e) => {
      history.goBack();
    });
  }, []);

  return (
    <div>
      <h2>회원 관리</h2>
      {data && (
        <Form onFinish={handleSubmit}>
          <div className={'pageWrapper'}>
            <Card>
              <h5>기본정보</h5>
              <table className={'details-table'}>
                <colgroup>
                  <col width={'150'} />
                  <col width={'auto'} />
                  <col width={'150'} />
                  <col width={'auto'} />
                </colgroup>
                <tbody>
                  <tr>
                    <th>아이디</th>
                    <td>{data?.username}</td>
                    <th>이름</th>
                    <td>{data?.name}</td>
                  </tr>
                  <tr>
                    <th>생년월일</th>
                    <td>{data?.birthday}</td>
                    <th>이메일</th>
                    <td>{data?.email}</td>
                  </tr>
                  <tr>
                    <th>휴대폰번호</th>
                    <td colSpan={3}>
                      [{data?.mobilePhoneCorp}] {data?.mobilePhone}
                    </td>
                  </tr>
                  <tr>
                    <th>주소</th>
                    <td colSpan={3} style={{ paddingTop: 10, paddingBottom: 10 }}>
                      {data?.address?.address} <br />
                      {data?.address?.detailedAddress}
                    </td>
                  </tr>
                  <tr>
                    <th>이메일 수신여부</th>
                    <td>
                      <Form.Item name={'isReceiveMail'} initialValue={!!data?.isReceiveMail}>
                        <Radio.Group disabled>
                          <Radio value={true}>동의</Radio>
                          <Radio value={false}>동의안함</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </td>
                    <th>SMS 수신여부</th>
                    <td>
                      <Form.Item name={'isReceivePhone'} initialValue={!!data?.isReceivePhone}>
                        <Radio.Group disabled>
                          <Radio value={true}>동의</Radio>
                          <Radio value={false}>동의안함</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </td>
                  </tr>
                  <tr>
                    <th>PUSH 수신여부</th>
                    <td colSpan={3}>
                      <Form.Item name={'isReceivePush'} initialValue={!!data?.isReceivePush}>
                        <Radio.Group disabled>
                          <Radio value={true}>동의</Radio>
                          <Radio value={false}>동의안함</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </td>
                  </tr>
                </tbody>
              </table>
              <h5 style={{ marginTop: 40 }}>운영정보</h5>
              <table className={'details-table'}>
                <colgroup>
                  <col width={'150'} />
                  <col width={'auto'} />
                  <col width={'150'} />
                  <col width={'auto'} />
                </colgroup>
                <tbody>
                  <tr>
                    <th>가입일자</th>
                    <td>{moment(data?.createdAt).format('YYYY-MM-DD HH:mm')}</td>
                    <th>최종접속일자</th>
                    <td>{data?.lastAccessTime && moment(data?.lastAccessTime).format('YYYY-MM-DD HH:mm')}</td>
                  </tr>
                  <tr>
                    <th>회원상태</th>
                    <td>
                      <Form.Item name={'isLeaved'} initialValue={!!data?.isLeaved}>
                        {/* <Radio.Group onChange={(e) => setLeaved(e.target.value)}> */}
                        <Radio.Group onChange={onLeaveChange}>
                          <Radio value={false}>정상</Radio>
                          <Radio value={true}>탈퇴</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </td>
                    <th>탈퇴사유</th>
                    <td>
                      <Form.Item
                        name={'leaveType'}
                        initialValue={data?.leaveType}
                        rules={[
                          {
                            required: true,
                            message: '탈퇴 사유를 선택해 주세요.',
                          },
                        ]}
                      >
                        <Radio.Group disabled={!isLeaved} initialValues={[data?.leaveType || 'THROW_OUT']}>
                          <Radio value={'THROW_OUT'}>관리자탈퇴</Radio>
                          <Radio value={'LEAVE'}>일반탈퇴</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </td>
                  </tr>
                  <tr>
                    <th>탈퇴 상세내용</th>
                    <td colSpan={3}>
                      <Form.Item name={'leaveReason'} initialValue={data?.leaveReason}>
                        <TextArea disabled={!isLeaved} style={{ height: 200 }} />
                      </Form.Item>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Card>
            <div>
              <Row style={{ marginTop: 10 }}>
                <Col flex={1}></Col>
                <Col flex={1} style={{ textAlign: 'right' }}>
                  <Button htmlType="button" onClick={handleBack}>
                    목록으로
                  </Button>{' '}
                  <Button htmlType="submit" type="primary">
                    수정
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </Form>
      )}
    </div>
  );
};

export default MemberDetails;
