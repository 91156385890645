import React, { useState, useEffect } from 'react';

import {
  FlexibleWidthXYPlot,
  VerticalBarSeries,
  VerticalBarSeriesCanvas,
  HorizontalGridLines,
  LabelSeries,
  VerticalGridLines,
  XAxis,
  YAxis,
} from 'react-vis';
import moment from 'moment';

const Example = ({ myDATA }) => {
  const [useCanvas, setUseCanvas] = useState(false);
  let BarSeries = VerticalBarSeries;

  useEffect(() => {
    if (useCanvas) {
      BarSeries = VerticalBarSeriesCanvas;
    }
  }, [useCanvas]);

  const data = [...myDATA];
  if (myDATA.length < 6) {
    const start = myDATA?.[0]?.x || moment().month();
    const loop = 6 - myDATA.length;
    for (let i = 0; i < loop; i++) {
      const i2 = start - loop + i;
      data.push({ x: i2, y: 0 });
    }
    data.sort((a, b) => (a.x > b.x ? 1 : -1));
  }

  return (
    <FlexibleWidthXYPlot xType="ordinal" height={300} xDistance={5}>
      <VerticalGridLines style={{ strokeWidth: 0.5 }} />
      <HorizontalGridLines style={{ strokeWidth: 0.5 }} />
      <XAxis style={{ strokeWidth: 0.5 }} />
      <YAxis style={{ strokeWidth: 0.5 }} />
      <BarSeries color="#007bff" data={data} />
      {/*<LabelSeries data={data} getLabel={(d) => d.y} />*/}
    </FlexibleWidthXYPlot>
  );
};

export default Example;
