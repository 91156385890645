import React, { useEffect, useState, useRef } from 'react';
import { Button, Card, Col, Form, Input, message, Row } from 'antd';
import { bsApi } from '@common/api';
import UploadFile from '@components/UploadFile';
import { green } from '@ant-design/colors';
import PostcodeFinder from '@components/FindPostcode';
import UploadImage from '@components/UploadImage';
import { useHistory } from 'react-router-dom';

const CodesRmForm = ({ match, history }) => {
  const { params } = match || {};
  const rmForm = useRef();
  const [logo, setLogo] = useState();
  const [url, setUrl] = useState();
  const [urlUpdate, setUrlUpdate] = useState(false);
  const [businessLicense, setBusinessLicense] = useState();
  const [duplicateCheck, setDuplicateCheck] = useState(true);
  const [availbleText, setAvailbleText] = useState(false);
  const [code, setCode] = useState();
  const [data, setData] = useState({});

  //저장
  const historyPush = useHistory();
  const handleSubmit = async () => {
    const dataForm = rmForm?.current?.getFieldsValue();

    const args = { ...dataForm };
    if (!params?.id) {
      if (duplicateCheck && !code) {
        alert('반드시 중복 체크 후 저장 가능합니다.');
        return;
      }
    }

    args.code = code;
    args.businessLicense = businessLicense;
    args.logo = logo;

    try {
      if (!params.id) {
        await bsApi.post(`/institutions`, args);
        alert('저장되었습니다.');
        history.push('/codes/rm');
      } else {
        await bsApi.put(`/institutions/${params.id}`, args);
        alert('저장되었습니다');
        history.push('/codes/rm');
      }
    } catch (e) {
      if (e?.response?.status === 400) {
        if (e.response?.data?.fieldError) {
          const erros = e.response?.data?.fieldError.map((v) => {
            return (defines[v.field] || v.field) + '항목 :: ' + v.defaultMessage;
          });
          alert('아래의 입력값을 확인해주세요.\n----------\n' + erros.join('\n'));
        } else {
          alert(e.response?.data?.message || '서버와 연결이 올바르지 않습니다..');
        }
      } else if (e?.response?.status === 401 || e?.response?.status === 403) {
        alert('권한 문제가 발생하였습니다.');
      } else {
        alert('서버와 연결이 올바르지 않습니다.');
      }
    }
  };

  const handleUrl = () => {
    setUrlUpdate(!urlUpdate);
  };

  //코드 6자리면 중복체크 버튼 활성화
  const onChangeCode = (e) => {
    const dataForm = rmForm?.current?.getFieldsValue();
    const code1 = dataForm?.code1;
    const code2 = dataForm?.code2;

    setCode(code1 + code2);
    console.log(code);
    if (code) {
      if (code.length < 6) {
        //사용가능한 코드입니다. 문구 안뜨게
        console.log(code.length);
        setAvailbleText(false);
      } else if (code.length === 6) {
        setDuplicateCheck(!duplicateCheck);
      }
    }
  };
  useEffect(onChangeCode, [code]);

  //기관코드 중복체크
  const handleDuplicate = async () => {
    const dataForm = rmForm?.current?.getFieldsValue();
    const code1 = dataForm?.code1;
    const code2 = dataForm?.code2;

    if (code1 && code2) {
      try {
        await bsApi.get(`/institutions/${code1 + code2}/check`);
        setCode(code1 + code2);
        setAvailbleText(true);
      } catch (e) {
        setCode(undefined);
        message.error('중복된 코드입니다.');
      }
    } else {
      alert('코드가 입력되지 않았습니다.');
    }
  };

  const handleBack = async () => {
    history.goBack();
  };

  const loadData = async () => {
    const { data } = await bsApi.get(`/institutions/${params?.id}`);
    console.log('Response::-------', data);
    if (data) {
      rmForm?.current?.setFieldsValue({
        name: data?.name,
        code1: data?.code?.substring(0, 3),
        code2: data?.code?.substring(3),
        phoneNumber: data?.phoneNumber,
        crn: data?.crn,
        businessLicense: data?.businessLicense,
        url: data?.url,
        zipCode: data?.zipCode,
        address: data?.address,
        detailedAddress: data?.detailedAddress,
        description: data?.description,
        logo: data?.logo,
        memo: data?.memo,
      });
      setCode(data?.code);
      setUrl(data?.url);
      setLogo(data?.logo);
      setBusinessLicense(data?.businessLicense);
      setData(data);
    }
  };

  useEffect(() => {
    if (params?.id && Object.keys(data).length === 0) {
      loadData().catch((e) => {
        history.goBack();
      });
    }
  }, [data, code]);

  const handleComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    rmForm?.current?.setFieldsValue({
      zipCode: data?.zonecode,
      address: fullAddress,
    });
  };

  const duplicatedBtnController = (value) => {
    const currentForm = rmForm?.current?.getFieldsValue();
    const findCodeType = rmForm?.current?.getFieldsError().find((v) => v.name[0] === value);
    const isValid = findCodeType?.errors.length > 0;
    if (!isValid) {
      if (currentForm?.code1?.length === 3 && currentForm?.code2?.length === 3) {
        setDuplicateCheck(false);
      }
    } else {
      setDuplicateCheck(true);
    }
  };

  return (
    <div>
      <h2>기관 관리</h2>
      {data && (
        <Form ref={rmForm} onFinish={handleSubmit}>
          <div className={'pageWrapper'}>
            <Card>
              <table className={'details-table'}>
                <colgroup>
                  <col width={'150'} />
                  <col width={'auto'} />
                  <col width={'150'} />
                  <col width={'auto'} />
                </colgroup>
                <tbody>
                  <tr>
                    <th>기관명</th>
                    <td>
                      <Form.Item
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: '기관명은 필수 입니다.',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </td>
                    <th>기관 코드</th>
                    <td>
                      <Row gutter={5}>
                        <Col style={{ width: 100 }}>
                          <Form.Item
                            name="code1"
                            rules={[
                              { pattern: /^[A-Z]*$/, message: '대문자 영문 문자만 입력 가능합니다' },
                              { len: 3, message: '3글자까지 입력 가능합니다' },
                              { required: true, message: '기관 코드는 필수 입니다.' },
                            ]}
                            normalize={(input) => input.toUpperCase()}
                          >
                            {/* <Input disabled={!!params?.id} onBlur={() => duplicatedBtnController('code1')} /> */}
                            <Input
                              disabled={!!params?.id}
                              onBlur={() => duplicatedBtnController('code1')}
                              onChange={onChangeCode}
                            />
                          </Form.Item>
                        </Col>

                        <Col style={{ width: 100 }}>
                          <Form.Item
                            name={'code2'}
                            rules={[
                              { pattern: /^[0-9]*$/, message: '숫자만 입력 가능합니다' },
                              { len: 3, message: '3글자 형태로만 입력 가능합니다' },
                              { required: true, message: '기관 코드는 필수 입니다.' },
                            ]}
                          >
                            <Input
                              disabled={!!params?.id}
                              onBlur={() => duplicatedBtnController('code2')}
                              onChange={onChangeCode}
                            />
                          </Form.Item>
                        </Col>
                        {!params?.id && (
                          <Col flex={1}>
                            <Button onClick={handleDuplicate} disabled={duplicateCheck}>
                              중복체크
                            </Button>
                            {availbleText && (
                              <span style={{ color: green.primary, fontWeight: 'bold', marginLeft: 10 }}>
                                사용가능한 코드입니다.
                              </span>
                            )}
                          </Col>
                        )}
                      </Row>
                    </td>
                  </tr>
                  <tr>
                    <th>사업자등록번호</th>
                    <td>
                      <Form.Item
                        name={'crn'}
                        rules={[
                          { pattern: /^[0-9]*$/, message: '숫자만 입력 가능합니다' },
                          { len: 10, message: '사업자번호는 10자리 형태로만 입력 가능합니다' },
                          { required: true, message: '사업자 등록번호는 필수 입니다.' },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </td>
                    <th rowSpan={2}>사업자등록증</th>
                    <td rowSpan={2} style={{ paddingTop: 10 }}>
                      <UploadFile
                        single={true}
                        ids={[businessLicense]}
                        onChange={(items) => {
                          setBusinessLicense(items?.[0]);
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>기관장</th>
                    <td>
                      {data?.manager
                        ? `${data?.manager?.name}(${data?.manager?.username})`
                        : '지정된 사용자가 없습니다.'}
                    </td>
                  </tr>

                  <tr>
                    <th>센터주소</th>
                    <td colSpan={3}>
                      <>
                        <Row gutter={5} style={{ paddingTop: 5 }}>
                          <Col width={150}>
                            <Form.Item
                              name="zipCode"
                              rules={[
                                { pattern: /^[0-9]*$/, message: '숫자만 입력 가능합니다' },
                                { len: 5, message: '우편번호는 5자리 형태로만 입력 가능합니다' },
                                { required: true, message: '우편번호는 필수 입니다.' },
                              ]}
                            >
                              <Input style={{ marginBottom: 5 }} disabled />
                            </Form.Item>
                          </Col>
                          <Col>
                            <PostcodeFinder onComplete={handleComplete}>
                              <Button>우편번호 찾기</Button>
                            </PostcodeFinder>
                          </Col>
                        </Row>
                        <Row gutter={[5, 5]}>
                          <Col xs={24} sm={12}>
                            <Form.Item name="address" rules={[{ required: true, message: '센터주소는 필수 입니다.' }]}>
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={12}>
                            <Form.Item
                              name="detailedAddress"
                              rules={[{ required: true, message: '상세주소는 필수 입니다.' }]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                        </Row>
                      </>
                    </td>
                  </tr>

                  <tr>
                    <th>대표 전화번호</th>
                    <td colSpan={3}>
                      <Form.Item
                        name="phoneNumber"
                        rules={[
                          { pattern: /^[0-9]*$/, message: '숫자만 입력 가능합니다' },
                          { required: true, message: '전화번호는 필수 입니다.' },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </td>
                  </tr>

                  <tr>
                    <th>로고파일</th>
                    <td style={{ verticalAlign: 'top' }}>
                      <UploadImage
                        single={true}
                        ids={[logo]}
                        onChange={(items) => {
                          setLogo(items?.[0]);
                        }}
                      />
                    </td>
                    <th>웹 주소</th>
                    <td style={{ verticalAlign: 'top' }}>
                      {urlUpdate ? (
                        <Form.Item
                          name="url"
                          rules={[
                            {
                              pattern: /(http(s)?:\/\/)([a-z0-9\w]+\.*)+[a-z0-9]{2,4}/,
                              message: '웹 주소를 정확하게 입력하여 주세요.',
                            },
                            { required: true, message: '웹 주소는 반드시 입력되어야합니다.' },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      ) : (
                        <a href={url} target="_blank">
                          {url}{' '}
                        </a>
                      )}
                      <Button htmlType="button" onClick={handleUrl}>
                        {urlUpdate ? '취소' : '주소 변경'}
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <th>설명</th>
                    <td colSpan={3}>
                      <Form.Item
                        name="description"
                        rules={[{ required: true, message: '상세 설명을 반드시 입력되어야합니다.' }]}
                      >
                        <Input.TextArea autoSize={{ minRows: 10 }} />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr>
                    <th>기관메모</th>
                    <td colSpan={3}>
                      <Form.Item name="memo">
                        <Input.TextArea autoSize={{ minRows: 10 }} />
                      </Form.Item>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Card>
            <div>
              <Row style={{ marginTop: 10 }}>
                <Col flex={1}></Col>
                <Col flex={1} style={{ textAlign: 'right' }}>
                  <Button htmlType="button" onClick={handleBack}>
                    목록으로
                  </Button>{' '}
                  <Button htmlType="submit" type="primary">
                    저장
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </Form>
      )}
    </div>
  );
};

export default CodesRmForm;
