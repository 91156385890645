import 'core-js';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './assets/styles.less';
import 'react-vis/dist/style.css';
import 'moment/locale/ko';
import { enableES5 } from 'immer'

import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import { CookiesProvider } from 'react-cookie';


enableES5();

ReactDOM.render(
  <CookiesProvider>
    <App />
  </CookiesProvider>,
  document.getElementById('root'),
);
