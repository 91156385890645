import React from 'react';
import { Button, Card, Col, Form, Row, Table, DatePicker } from 'antd';
import { searchItemLayout } from '@common/constants';
import { useWindowsDim } from '@common/hooks/useWindowsDim';
import { produce } from 'immer';

const columnsOrigin = [
  {
    title: 'NO',
    dataIndex: 'rowNum',
    key: 'rowNum',
    width: 160,
    fixed: 'left',
    align: 'center',
    render: (text, record, index) => index + 1,
  },
  {
    title: '월',
    dataIndex: 'organizationName',
    key: 'organizationName',
    width: 'auto',
    align: 'center',
    //    render: (v) => v.organization.name,
  },
  {
    title: '총 건수',
    dataIndex: 'code',
    key: 'code',
    width: 160,
    align: 'center',
    //    render: (v) => v.organization.code,
  },
  {
    title: '미확인 건수',
    dataIndex: 'username',
    key: 'username',
    width: 160,
    align: 'center',
    //   render: (v) => v.username,
  },
  {
    title: '확인 건수',
    dataIndex: 'name',
    key: 'name',
    width: 160,
    align: 'center',
    //  render: (v) => v.name,
  },
  {
    title: '상담건수',
    dataIndex: 'allCount',
    key: 'allCount',
    width: 100,
    align: 'center',
  },
  {
    title: (
      <span>
        총 합계 <br /> (확인건수 + 상담 건당 비용)
      </span>
    ),
    dataIndex: 'consultation_price',
    key: 'consultation_price',
    width: 300,
    align: 'center',
    //   render: (text, record, index) => moment(text).format('YYYY-MM-DD HH:mm'),
  },
];
const ConsultantMain = ({ data, handleSubmit, handleDateRangeSet, form }) => {
  const { width } = useWindowsDim();
  const tableWidth = width - 280;

  const columns = produce(columnsOrigin, (draft) => {
    const cwidths = draft.reduce((a, c) => {
      if (c.width !== 'auto') {
        return a + c.width;
      } else {
        return a + 300;
      }
    }, 0);

    if (cwidths > tableWidth) {
      draft.forEach((v) => {
        if (v.width === 'auto') v.width = 300;
      });
    }
  });

  let fullWidth = 0;
  columns.forEach((v) => (fullWidth += v.width));

  return (
    <div>
      <Form ref={form} onFinish={handleSubmit}>
        <Card>
          <Form.Item {...searchItemLayout} label="기간검색">
            <Row type={'flex'} gutter={[5, 5]}>
              <Col flex={1}>
                <Form.Item name="dateRange" noStyle initialValue={null}>
                  <DatePicker.RangePicker
                    style={{ marginRight: 5 }}
                    disabled={[false, false]}
                    allowClear
                    allowEmpty={[true, true]}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Button.Group>
                  <Button onClick={() => handleDateRangeSet(0)}>오늘</Button>
                  <Button onClick={() => handleDateRangeSet(7)}>7일</Button>
                  <Button onClick={() => handleDateRangeSet(30)}>30일</Button>
                  <Button onClick={() => handleDateRangeSet(365)}>1년</Button>
                </Button.Group>
              </Col>
            </Row>
          </Form.Item>
        </Card>
        <div style={{ marginTop: 10 }}>
          <Row>
            <Col style={{ textAlign: 'right' }} xs={24}>
              <Button htmlType="button">초기화</Button>{' '}
              <Button htmlType="submit" type="primary">
                검색
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
      <div style={{ marginTop: 25 }} bodystyle={{ padding: 0 }}>
        <Table
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={data?.rows || []}
          //  pagination={data?.pagination || {}}
          scroll={{ x: fullWidth }}
          //      onChange={handleTableChange}
          bordered
        />
      </div>
    </div>
  );
};
export default ConsultantMain;
