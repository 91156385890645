import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, Input, Modal, Row, Select, Table } from 'antd';
import { searchItemLayout, CONSULTANT_TYPE, PROFESSIONAL_FILELD_SUB } from '@common/constants';
import { Link } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import moment from 'moment';
import { useWindowsDim } from '@common/hooks/useWindowsDim';
import { produce } from 'immer';
import { bsApi } from '@common/api';

const columnsOrigin = [
  {
    title: 'NO',
    dataIndex: 'rowNum',
    key: 'rowNum',
    width: 80,
    fixed: 'left',
    align: 'center',
  },
  {
    title: '센터명',
    dataIndex: 'organization',
    key: 'centerName',
    width: 'auto',
    minWidth: 300,
    render: (v) => {
      return v === null ? '' : v.name;
    },
  },
  {
    title: '센터코드',
    dataIndex: 'organization',
    key: 'code',
    width: 150,
    align: 'center',
    render: (v) => {
      return v === null ? '' : v.code;
    },
  },
  {
    title: '상담원ID',
    dataIndex: 'username',
    key: 'username',
    width: 150,
    align: 'center',
  },
  {
    title: '상담원명',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    width: 150,
  },
  {
    title: '상담원레벨',
    dataIndex: 'consultantLevel',
    key: 'consultantLevel',
    width: 100,
  },
  {
    title: '전문분야',
    dataIndex: 'professionalField',
    key: 'professionalField',
    width: 100,
  },
  {
    title: '회원구분',
    dataIndex: '',
    key: 'type',
    width: 150,
    render: (v) => {
      const a = v.authorities.find((val) => val === 'RELATIONSHIP_MANAGER');
      if (a) {
        return '센터장(겸직)';
      } else {
        return CONSULTANT_TYPE[v.type];
      }
    },
  },

  {
    title: ' ',
    key: 'operation',
    width: 80,
    align: 'center',
    fixed: 'right',
  },
];

const initialQuery = {
  page: 0,
  size: 10,
  display: undefined,
  searchType: undefined,
  keyword: '',
  educationCompletionLevel: null,
  consultantLevel: null,
  professionalField2: null,
  type: 'PRO_CONSULTANT',
  currentConsultationType: 'PRO_CONSULTATION',
};

const FindProConsultant = ({ children, onComplete, accoutType, viewType, addQuery }) => {
  const [isModal, setModal] = useState(false);
  const { principal } = useSelector((s) => s.auth, shallowEqual);
  const handleComplete = (data) => {
    onComplete?.(data);
    setModal(false);
  };

  const childrenArray = React.useMemo(() => {
    return React.Children.toArray(children);
  }, [children]);

  const { width } = useWindowsDim();
  const tableWidth = 900 - 48;

  const columns = produce(columnsOrigin, (draft) => {
    const cwidths = draft.reduce((a, c) => {
      if (c.width !== 'auto') {
        return a + c.width;
      } else {
        return a + 300;
      }
    }, 0);

    if (cwidths > tableWidth) {
      draft.forEach((v) => {
        if (v.width === 'auto') v.width = v.minWidth || 300;
      });
    }

    draft[0].render = (text, record, index) => {
      const page = data?.pagination;
      return page?.total - page?.pageSize * (page?.current - 1) - index;
    };

    draft[8].render = (record) => (
      <Button htmlType={'button'} size={'small'} onClick={() => handleComplete(record)}>
        선택
      </Button>
    );
  });

  const form = useRef();
  const [query, setQuery] = useState(initialQuery);
  const [data, setData] = useState({});

  const fetchData = useCallback(async () => {
    console.log('addQuery::',addQuery);
    if (isModal) {
      const mergeData = Object.assign({ ...query }, { institution: addQuery.institution.id });
      console.log(mergeData);
      const { data: response } = await bsApi.get('/accounts', { params: mergeData });
      console.log(response);
      setData({
        rows: response?._embedded?.responseList || [],
        pagination: {
          current: response?.page?.number + 1,
          pageSize: response?.page?.size,
          total: response?.page?.totalElements || 0,
        },
      });
    }
  }, [query, isModal]);

  const handleSubmit = async (data) => {
    data.educationCompletionLevel;
    data.professionalField2;
    data.type = 'PRO_CONSULTATION';
    data.currentConsultationType = 'PRO_CONSULTATION';

    if (data.educationCompletionLevel === 'all') {
      data.educationCompletionLevel = null;
    }
    if (data.consultantLevel === 'all') {
      data.consultantLevel = null;
    }
    if (data.professionalField2 === 'all') {
      data.professionalField2 = null;
    }
    if (data.type === 'all') {
      data.type = null;
    }

    setQuery(
      produce((draft) => {
        draft.page = 0;
        draft.keyword = data.keyword;
        draft.searchType = data.searchType;
        draft.educationCompletionLevel = data.educationCompletionLevel;
        draft.consultantLevel = data.consultantLevel;
        draft.professionalField2 = data.professionalField2;
        draft.currentConsultationType = data.currentConsultationType;
        data.type = data.type;
      }),
    );
  };

  const handleReset = async () => {
    form?.current?.setFieldsValue(initialQuery);
    await handleSubmit(initialQuery);
  };

  const handleTableChange = async (pager) => {
    setQuery(
      produce((draft) => {
        draft.page = pager?.current - 1;
      }),
    );
  };

  useEffect(() => {
    fetchData().catch(console.log);
  }, [query, isModal]);

  let fullWidth = 0;
  columns.forEach((v) => (fullWidth += v.width));
  return (
    <>
      <Modal title={'RM 상담원 검색'} visible={isModal} width={900} footer={null} onCancel={() => setModal(false)}>
        {isModal && (
          <div>
            <Form ref={form} onFinish={handleSubmit}>
              <Card>
                <Form.Item {...searchItemLayout} label="선택검색">
                  <Row type={'flex'} gutter={[5, 5]}>
                    <Col>
                      <Form.Item name="educationCompletionLevel" noStyle>
                        <Select style={{ width: 150, marginRight: 5 }} allowClear placeholder={'교육수료등급'}>
                          <Select.Option value="all">전체</Select.Option>
                          <Select.Option value="BASIC">기본수료</Select.Option>
                          <Select.Option value="INTERMEDIATE">중급수료</Select.Option>
                          <Select.Option value="ADVANCED">고급수료</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item name="professionalField2" noStyle>
                        <Select style={{ width: 150, marginRight: 5 }} allowClear placeholder={'전문분야'}>
                          {Object.keys(PROFESSIONAL_FILELD_SUB).map((data) => {
                            return (
                              <Select.Option value={data} key={PROFESSIONAL_FILELD_SUB[data][0]}>
                                {PROFESSIONAL_FILELD_SUB[data][0]}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item {...searchItemLayout} label="상세검색">
                  <Row type={'flex'} gutter={[5, 5]}>
                    <Col>
                      <Form.Item name="searchType" noStyle initialValue={'name'}>
                        <Select style={{ width: 150, marginRight: 5 }}>
                          <Select.Option value="name">상담원명</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col flex={1}>
                      <Form.Item name="keyword" noStyle>
                        <Input placeholder="검색어를 입력하세요." />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              </Card>
              <div style={{ marginTop: 10 }}>
                <Row>
                  <Col xs={12}></Col>
                  <Col style={{ textAlign: 'right' }} xs={12}>
                    <Button htmlType="button" onClick={handleReset}>
                      초기화
                    </Button>{' '}
                    <Button htmlType="submit" type="primary">
                      검색
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
            <div style={{ marginTop: 25 }}>
              <Table
                rowKey={(record) => record.id}
                columns={columns}
                dataSource={data?.rows || []}
                pagination={data?.pagination || {}}
                scroll={{ x: fullWidth }}
                onChange={handleTableChange}
                bordered
              />
            </div>
          </div>
        )}
      </Modal>
      {childrenArray.map((v) =>
        React.cloneElement(v, {
          onClick: () => setModal(true),
        }),
      )}
    </>
  );
};

export default React.memo(FindProConsultant);
