import React, { useCallback, useEffect, useState } from 'react';
import { useWindowsDim } from '@common/hooks/useWindowsDim';
import { produce } from 'immer';
import {  Table } from 'antd';
import { bsApi } from '@common/api';
import moment from 'moment';
import {
  ROLE
} from '@common/constants';
import { useAppState } from '@components/shared/AppProvider';

const columnsOrigin = [
  {
    title: 'NO',
    dataIndex: 'rowNum',
    key: 'rowNum',
    fixed: 'left',
    align: 'center',
  },
  {
    title: '통화구분',
    dataIndex: 'callType',
    key: 'callType',
    fixed: 'left',
    align: 'center',
  },
  {
    title: '통화시작',
    dataIndex: 'sCall',
    key: 'sCall',
    align: 'center',
    width: 150,

  },
  {
    title: '통화종료',
    dataIndex: 'eCall',
    key: 'eCall',
    align: 'center',
    width: 150,
  },
  {
    title: '수신번호',
    dataIndex: 'receivePhone',
    key: 'receivePhone',
    align: 'center',
    render: (text) => (text || '').replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, '$1-$2-$3'),
  },
  {
    title: '발신 번호',
    dataIndex: 'senderPhone',
    key: 'senderPhone',
    align: 'center',
    width: 150,
  },
  {
    title: '회원 ID',
    dataIndex: 'receiver',
    key: 'receiver.username',
    align: 'center',
    render: (text) => text?.username
  },
  {
    title: '회원 이름',
    dataIndex: 'receiver',
    key: 'receiver.name',
    align: 'center',
    render: (text) => text?.name
  },
  {
    title: '센터 코드',
    dataIndex: 'sender',
    key: 'sender.organization.code',
    align: 'center',
    render: (text) => text?.organization.code
  },
  {
    title: '상담원 ID',
    dataIndex: 'sender',
    key: 'sender.username',
    align: 'center',
    render: (text) => text?.username
  },
  {
    title: '상담원 명',
    dataIndex: 'sender',
    key: 'sender.name',
    align: 'center',
    render: (text) => text?.name
  },
  {
    title: '상담원 구분',
    dataIndex: 'sender',
    key: 'sender.type',
    align: 'center',
    render: (text) =>ROLE[text?.type]
  },

];


const getHistory = (values) => bsApi.get(`/bound/${values}`);
const initialQuery = {
  page: 0,
  size: 10,
};
const CallHistory = ({ match }) => {
  const [state] = useAppState();
  const { params } = match;
  let fullWidth = 0;
  const { width } = useWindowsDim();
  const tableWidth = width - 40;

  const columns = produce(columnsOrigin, (draft) => {
    const cwidths = draft.reduce((a, c) => {
      if (c.width !== 'auto') {
        return a + c.width;
      } else {
        return a + 300;
      }
    }, 0);

    draft.forEach((v) => {
      v.ellipsis = {
        showTitle: false,
      };
    });

    if (cwidths > tableWidth) {
      draft.forEach((v) => {
        if (v.width === 'auto') v.width = v.minWidth || 300;
        if (state.mobile) delete v.fixed;
      });
    }
    draft[0].render = (text, record, index) => data?.totalElements - index;
  });

  const [data, setData] = useState();
  const [query, setQuery] = useState(initialQuery);

  columns.forEach((v) => (fullWidth += v.width));

  const loadData = useCallback(
    async (values) => {
      try {
        const response = await getHistory(values);
        console.log("response::", response);
        setData(response?.data);
      } catch (e) {
        console.error(e);
      }
    },
    [data],
  );

  useEffect(() => {
    if (!data) {
      loadData(params?.id).catch(console.error);
    }
  }, [data]);

  const handleTableChange = async (pager) => {
    setQuery(
      produce((draft) => {
        draft.size = pager?.pageSize;
        draft.page = pager?.current - 1;
      }),
    );
  };

  return (
    <div>
      {data && data?.content?.length > 0 ? (
        <div>
          <Table
            rowKey={(record) => record.id}
            columns={columns}
            dataSource={data?.content || []}
            pagination={data?.pageable || {}}
            scroll={{ x: fullWidth }}
            onChange={handleTableChange}
            bordered
          />
        </div>
      ) : (
        <div> 아직 상담 내역이 존재하지 않는 상담원입니다. </div>
      )}
    </div>
  );
};
export default CallHistory;
