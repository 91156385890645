import React, { useCallback, useEffect, useState } from 'react';
import { useWindowsDim } from '@common/hooks/useWindowsDim';
import { produce } from 'immer';
import {  Table } from 'antd';
import { bsApi } from '@common/api';
import moment from 'moment';
import {
  WANT_TIME,
  CONSULTATION_STATUS
} from '@common/constants';
import { useAppState } from '@components/shared/AppProvider';

const columnsOrigin = [
  {
    title: 'NO',
    dataIndex: 'rowNum',
    key: 'rowNum',
    width: 60,
    fixed: 'left',
    align: 'center',
  },
  {
    title: '고객 ID',
    dataIndex: 'createdBy',
    key: 'createdBy.username',
    width: 150,
    fixed: 'left',
    align: 'center',
    render: (text) => text.username
  },
  {
    title: '고객이름',
    dataIndex: 'createdBy',
    key: 'createdBy.name',
    width: 120,
    fixed: 'left',
    align: 'center',
    render: (text) => text.name
  },
  {
    title: '고객 전화번호',
    dataIndex: 'createdBy',
    key: 'createdBy.mobilePhone',
    align: 'center',
    width: 150,
    render: (text) => (text.mobilePhone || '').replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, '$1-$2-$3'),
  },
  {
    title: '고객 이메일',
    dataIndex: 'createdBy',
    key: 'createdBy.email',
    align: 'center',
    width: 150,
    render: (text) => text.email
  },
  {
    title: '희망 상담 센터',
    dataIndex: 'center',
    key: 'center.name',
    width: 150,
    render: (text) => text.name
  },
  {
    title: '희망 상담 일자',
    dataIndex: 'consultWantDate',
    key: 'consultWantDate',
    width: 150,
    align: 'center',
    render: (text) => text
  },
  {
    title: '희망 상담 시간',
    dataIndex: 'consultWantTime',
    key: 'consultWantTime',
    width: 150,
    align: 'center',
    render: (text) => WANT_TIME[text]
  },
  {
    title: '상담 진행 상태',
    dataIndex: 'status',
    key: 'status',
    width: 150,
    align: 'center',
    render: (text) => CONSULTATION_STATUS[text]
  },
  {
    title: '상담 접수일',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 150,
    align: 'center',
    render: (text) => moment(text).format('YYYY-MM-DD HH:mm')
  },
  {
    title: '최종 상담 완료일',
    dataIndex: 'updatedOn',
    key: 'updatedOn',
    width: 150,
    render: (text) => moment(text).format('YYYY-MM-DD HH:mm')
  },
  {
    title: '최근 업데이트',
    dataIndex: 'updatedOn',
    key: 'updatedOn',
    width: 150,
    align: 'right',
    render: (text) => moment(text).format('YYYY-MM-DD HH:mm')
  },
];


const getHistory = (values) => bsApi.get(`/consultations`, { params: values });
const initialQuery = {
  page: 0,
  size: 10,
};
const ConsultHistory = ({ match }) => {
  const [state] = useAppState();
  const { params } = match;
  let fullWidth = 0;
  const { width } = useWindowsDim();
  const tableWidth = width - 40;

  const columns = produce(columnsOrigin, (draft) => {
    const cwidths = draft.reduce((a, c) => {
      if (c.width !== 'auto') {
        return a + c.width;
      } else {
        return a + 300;
      }
    }, 0);

    draft.forEach((v) => {
      v.ellipsis = {
        showTitle: false,
      };
    });

    if (cwidths > tableWidth) {
      draft.forEach((v) => {
        if (v.width === 'auto') v.width = v.minWidth || 300;
        if (state.mobile) delete v.fixed;
      });
    }
    draft[0].render = (text, record, index) => data?.totalElements - index;
  });

  const [data, setData] = useState();
  const [query, setQuery] = useState(initialQuery);

  columns.forEach((v) => (fullWidth += v.width));

  const loadData = useCallback(
    async (values) => {
      try {
        const response = await getHistory({ queryId: values });
        setData(response?.data);
      } catch (e) {
        console.error(e);
      }
    },
    [data],
  );

  useEffect(() => {
    if (!data) {
      loadData(params?.id).catch(console.error);
    }
  }, [data]);

  const handleTableChange = async (pager) => {
    setQuery(
      produce((draft) => {
        draft.size = pager?.pageSize;
        draft.page = pager?.current - 1;
      }),
    );
  };

  return (
    <div>
      {data && data?.content?.length > 0 ? (
        <div>
          <Table
            rowKey={(record) => record.id}
            columns={columns}
            dataSource={data?.content || []}
            pagination={data?.pageable || {}}
            scroll={{ x: fullWidth }}
            onChange={handleTableChange}
            bordered
          />
        </div>
      ) : (
        <div> 아직 상담 내역이 존재하지 않는 상담원입니다. </div>
      )}
    </div>
  );
};
export default ConsultHistory;
