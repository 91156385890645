import React, { useCallback, useEffect, useState } from 'react';
import { bsApi, getAPIHost } from '@common/api';
import { Button, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { isEqual } from 'lodash';
import LoadingOutlined from '@ant-design/icons/lib/icons/LoadingOutlined';
import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined';

const UploadImage = ({ ids, onChange, disabled }) => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fileIds, setFileIds] = useState([]);
  const fileList = files.slice(-1);

  const loadData = useCallback(async (ids) => {
    const r = await bsApi.post('/files/info', { ids });
    setFiles(
      r.data.map((v) => ({
        uid: v.id,
        name: v.filename,
        status: 'done',
        response: v, // custom error message to show
        url: `${getAPIHost()}api/files/${v.id}`,
      })),
    );
  }, []);

  useEffect(() => {
    if (isEqual(fileIds, ids)) return;
    if ((ids || []).filter((v) => !!v).length > 0) {
      loadData(ids || []).catch(console.log);
      setFileIds(ids);
    }
  }, [ids]);

  useEffect(() => {
    if (isEqual(fileIds, ids)) return;
    onChange(fileIds);
  }, [fileIds]);

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  return (
    <>
      <Upload
        listType="picture-card"
        name={'file'}
        action={getAPIHost() + 'api/files'}
        headers={bsApi.defaults.headers.common}
        showUploadList={false}
        fileList={fileList}
        onChange={(e) => {
          setFiles(e.fileList);
          if (e.file.status === 'done' || e.file.status === 'removed') {
            setFileIds(e.fileList.map((v) => v?.response?.id).filter((v) => !!v));
          }
        }}
      >
        {fileIds.length ? (
          <img src={`${getAPIHost()}api/files/${fileIds[0]}`} alt="avatar" style={{ width: '100%' }} />
        ) : (
          uploadButton
        )}
      </Upload>
    </>
  );
};

export default UploadImage;
