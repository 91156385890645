import React from 'react';
import { Tabs } from 'antd';
import ManagerForm from '@widgets/managers/form';
import ConsultHistory from '@widgets/managers/consultHistory';
import CalculateHistory from '@widgets/managers/calculateHistory';
import CallHistory from '@widgets/managers/callHistory';
import ReviewHistroy from '@widgets/managers/reviewHistroy';

const ManagerDetails = ({ history, match }) => {
  const { params } = match || {};

  console.log(params);

  return (
    <div>
      {!params.id ? (
        <>
          <h2>상담원 관리</h2>
          <ManagerForm history={history} match={match} />
        </>
      ) : (
        <>
          <h2 style={{ marginBottom: 0, borderBottom: 0, paddingBottom: 0 }}>상담원 관리</h2>
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="기본정보" key="1">
              <ManagerForm history={history} match={match} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="상담내역" key="2">
              <ConsultHistory match={match} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="정산내역" key="3">
              <CalculateHistory match={match} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="통화내역" key="4">
              <CallHistory match={match} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="후기관리" key="5">
              <ReviewHistroy match={match} />
            </Tabs.TabPane>
          </Tabs>
        </>
      )}
    </div>
  );
};

export default ManagerDetails;
