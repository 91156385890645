import { useEffect, useState } from 'react';

export function getWindowDimensions() {
  if (process.browser) {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  } else {
    return { width: 0, height: 0 };
  }
}

export const useWindowsDim = () => {
  const [winDim, setWinDim] = useState(getWindowDimensions());
  useEffect(() => {
    const handleResize = () => setWinDim(getWindowDimensions());
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return winDim;
};

export default useWindowsDim;
