import React, { useEffect, useState } from 'react';
import { Avatar, Layout, List, Menu } from 'antd';
import { BarChart } from 'react-feather';

import { Link, useHistory } from 'react-router-dom';
import nookies from 'nookies';

import DashHeader, { Notification } from './styles/Header';
import { useAppState } from './shared/AppProvider';
import { shallowEqual, useSelector } from 'react-redux';
import moment from 'moment';
import { CLEAR_PRINCIPAL } from '@data/auth/actionTypes';
import { ROLE } from '@common/constants';

const { SubMenu } = Menu;
const { Header } = Layout;

const NowTime = React.memo(() => {
  const diff = useSelector((s) => s.auth.timediff, shallowEqual);
  const time = moment().add(diff, 'seconds');
  const [now, setNow] = useState(time);

  useEffect(() => {
    const ix = setInterval(() => {
      const time = moment().add(diff, 'seconds');
      setNow(time);
    }, 500);
    return () => clearInterval(ix);
  }, [diff]);

  return (
    <span style={{ marginRight: 25, fontSize: 12, fontFamily: 'Tahoma' }}>
      서버시각 : {now.format('YYYY. MM. DD. HH:mm:ss')}
    </span>
  );
});

const MainHeader = () => {
  const principal = useSelector((s) => s.auth.principal, shallowEqual);
  const [state, dispatch] = useAppState();
  const historyPush = useHistory();
  const myPage = () => {
    // /managers/:id
    historyPush.push('/my-page/' + principal.id);
  };
  return (
    <DashHeader>
      <Header>
        {state.mobile && (
          <a onClick={() => dispatch({ type: 'mobileDrawer' })} className="trigger">
            <BarChart size={20} strokeWidth={1} />
          </a>
        )}
        <Link to="/" className="brand">
          <img style={{ maxHeight: state.mobile ? 15 : 20 }} src={'/bi_white.png'} />
        </Link>

        <span className="mr-auto" />

        {!state.mobile && <NowTime />}

        {!!principal?.organization && <span style={{ fontWeight: 'bold' }}>[{principal?.organization?.name}]</span>}

        <Menu mode="horizontal">
          <SubMenu
            title={
              <b>
                {principal?.name} ({ROLE[principal?.type]})
              </b>
            }
          >
            <Menu.Item onClick={() => myPage()}>정보수정</Menu.Item>
            <Menu.Item
              onClick={() => {
                const options = {
                  maxAge: 30 * 24 * 60 * 60,
                  path: '/',
                };
                nookies.set(null, 'accessToken', '', options);
                dispatch({ type: CLEAR_PRINCIPAL });
                location.reload();
              }}
            >
              로그아웃
            </Menu.Item>
          </SubMenu>
        </Menu>
      </Header>
    </DashHeader>
  );
};

export default MainHeader;
