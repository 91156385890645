import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, DatePicker, Form, Input, InputNumber, message, Radio, Row, Select } from 'antd';
import { bsApi } from '@common/api';
import FindCenter from '@components/FindCenter';
import { useHistory } from 'react-router-dom';
import {
  ACCOUNT_TYPE,
  CONSULTATION_LEVEL,
  EDUCATION,
  PROFESSIONAL_FILELD,
  PROFESSIONAL_FILELD_SUB,
  WORK_TYPE,
} from '@common/constants';
import PostcodeFinder from '@components/FindPostcode';
import FindInstitution from '@components/FindInstitution';
import moment from 'moment';
import { shallowEqual, useSelector } from 'react-redux';
import styled from 'styled-components';

const RedFont = styled.span`
  color: red;
`;

const ManagerForm = ({ history, match }) => {
  const { params } = match || {};
  const { principal } = useSelector((s) => s.auth, shallowEqual);

  const urltype = match?.params?.type;
  console.log('params::', params?.id);

  const [type, setType] = useState();
  const [data, setData] = useState();
  const [center, setCenter] = useState(principal.type === 'CENTER_MANAGER' ? principal.organization : undefined);
  const [institution, setInstitution] = useState(
    principal.type === 'RELATIONSHIP_MANAGER' ? principal.organization : undefined,
  );
  const [withChild, setWithChild] = useState();
  const [zipCode, setZipCode] = useState();
  const [address, setAddress] = useState();
  const [detailedAddress, setDetailedAddress] = useState();
  const [professionalField, setProfessionalField] = useState();
  const historyPush = useHistory();
  const form = useRef();

  const loadData = async () => {
    const { data } = await bsApi.get(`/accounts/${params?.id}`);
    console.log('data::', data);
    setType(data.type);
    setData(data);
    setZipCode(data.address?.zipCode);
    setAddress(data.address?.address);
    setDetailedAddress(data.address?.detailedAddress);
    setProfessionalField(data?.professionalField);
    setWithChild(true);
    if (data.type === 'PRO_CONSULTANT' || data.type === 'RELATIONSHIP_MANAGER') {
      setInstitution(data.organization);
    } else {
      setCenter(data.organization);
    }
    if (data.type === 'CENTER_MANAGER') {
      setWithChild(data.authorities.includes('CONSULTANT'));
    }
    if (data.type === 'RELATIONSHIP_MANAGER') {
      setWithChild(data.authorities.includes('PRO_CONSULTANT'));
    }

    form?.current?.setFieldsValue({
      ...data,
      effectiveDate: data?.effectiveDate ? moment(data?.effectiveDate) : undefined,
    });
  };

  useEffect(() => {
    if (params?.id) {
      loadData().catch((e) => {
        console.log(e);
      });
    } else {
      setWithChild(true);
    }
  }, [params?.id]);

  const handleComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    setZipCode(data?.zonecode);
    setAddress(fullAddress);
  };

  const goToBack = () => {
    historyPush.push('/managers/' + urltype);
  };

  const handleSubmit = async (data) => {
    console.log(data);
    const args = {
      ...data,
      type,
      effectiveDate: data.effectiveDate?.format('YYYY-MM-DD'),
      address: {
        zipCode,
        address,
        detailedAddress,
      },
    };

    try {
      if (!params?.id) {
        if (args.type === 'CENTER_MANAGER' || args.type === 'CONSULTANT') {
          args.organizationId = center.id;
        }
        if (args.type === 'RELATIONSHIP_MANAGER' || args.type === 'PRO_CONSULTANT') {
          args.organizationId = institution.id;
        }

        if (data.birthday) {
          args.birthday = data.birthday.format('YYYY-MM-DD');
        }

        const r = await bsApi.post(`/accounts`, args);
        alert('저장되었습니다.');
        history.replace(`/managers/${urltype}/${r.data.id}`);
      } else {
        if (args.type === 'CENTER_MANAGER' || args.type === 'CONSULTANT') {
          args.organizationId = center.id;
        }
        if (args.type === 'RELATIONSHIP_MANAGER' || args.type === 'PRO_CONSULTANT') {
          args.organizationId = institution.id;
        }

        args.sideline = withChild;
        if (withChild) {
          if (args.type === 'CENTER_MANAGER') {
            args.authorities = ['CENTER_MANAGER', 'CONSULTANT'];
          }
          if (args.type === 'RELATIONSHIP_MANAGER') {
            args.authorities = ['RELATIONSHIP_MANAGER', 'PRO_CONSULTANT'];
          }
        } else {
          args.authorities = [args.type];
          args.consultantLevel = form?.current?.consultantLevel;
          args.educationCompletionLevel = form?.current?.educationCompletionLevel;
          args.professionalField = form?.current?.professionalField;
          args.professionalField2 = form?.current?.professionalField2;
          args.consultationPrice = form?.current?.consultationPrice;
          console.log(args);
        }

        const r = await bsApi.put(`/accounts/${params?.id}`, args);
        alert('저장되었습니다.');
        goToBack();
      }
    } catch (e) {
      console.log(e);
      console.log(e.response);
      alert('서버와 연결이 올바르지 않습니다. \n[' + e.response?.data?.message + '}');
      if (e.response?.errorFields) {
        e.response?.errorFields?.errors.forEach((v) => {
          message.error(v);
        });
      }
    }
  };

  let accountType = ACCOUNT_TYPE;
  if (['CENTER_MANAGER'].includes(principal.type)) {
    accountType = {
      CONSULTANT: '일반 상담원',
      CENTER_MANAGER: '센터장',
    };
  } else if (['RELATIONSHIP_MANAGER'].includes(principal.type)) {
    accountType = {
      PRO_CONSULTANT: '전문 상담원',
      RELATIONSHIP_MANAGER: 'RM',
    };
  }

  return (
    <Form ref={form} onFinish={handleSubmit} onFinishFailed={console.log}>
      <div className={'pageWrapper'}>
        <Card>
          {!params?.id ? (
            <>
              <h5>상담원 등록</h5>
              <table className={'details-table'}>
                <colgroup>
                  <col width={'150'} />
                  <col width={'auto'} />
                  <col width={'150'} />
                  <col width={'auto'} />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      상담원 구분 <RedFont> * </RedFont>{' '}
                    </th>
                    <td colSpan={3}>
                      <Form.Item
                        name={'type'}
                        rules={[{ required: true, message: '회원 구분은 반드시 선택되어야 합니다.' }]}
                      >
                        <Radio.Group value={type} onChange={(e) => setType(e.target.value)}>
                          {Object.keys(accountType).map((key) => (
                            <Radio value={key} key={key}>
                              {accountType[key]}
                            </Radio>
                          ))}
                        </Radio.Group>
                      </Form.Item>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      아이디<RedFont> * </RedFont>
                    </th>
                    <td colSpan={3}>
                      <Form.Item
                        name={'username'}
                        rules={[{ required: true, message: '회원 ID는 반드시 입력해야 합니다.' }]}
                      >
                        <Input />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      비밀번호<RedFont> * </RedFont>
                    </th>
                    <td colSpan={3}>
                      <Form.Item
                        name={'password'}
                        rules={[
                          { required: true, message: '비밀번호를 입력해주세요.' },
                          {
                            pattern: /^(?=.*[~`!@#$%\\^&*()-])(?=.*[0-9])(?=.*[A-Za-z])/,
                            message: '영문,숫자,특수문자 각각 포함해야 합니다.',
                          },
                          { min: 8, max: 20, message: '8~20자 내로 입력해 주세요.' },
                        ]}
                        hasFeedback
                      >
                        <Input.Password />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      비밀번호 확인<RedFont> * </RedFont>
                    </th>
                    <td colSpan={3}>
                      <Form.Item
                        name={'confirm'}
                        dependencies={['password']}
                        rules={[
                          { required: true, message: '비밀번호를 입력해주세요.' },
                          ({ getFieldValue }) => ({
                            validator(rule, value) {
                              if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                              }
                              return Promise.reject('비밀번호가 일치하지 않습니다.');
                            },
                          }),
                        ]}
                        hasFeedback
                      >
                        <Input.Password />
                      </Form.Item>
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          ) : (
            <>
              <h5>기본정보</h5>
              <table className={'details-table'}>
                <colgroup>
                  <col width={'150'} />
                  <col width={'auto'} />
                  <col width={'150'} />
                  <col width={'auto'} />
                </colgroup>
                <tbody>
                  <tr>
                    <th>회원구분</th>
                    <td colSpan={3}>{ACCOUNT_TYPE[type]}</td>
                  </tr>
                  <tr>
                    <th>아이디</th>
                    <td>{data?.username}</td>
                    <th>이름</th>
                    <td>{data?.name}</td>
                  </tr>
                  <tr>
                    <th>생년월일</th>
                    <td>{data?.birthday}</td>
                    <th>이메일</th>
                    <td>
                      <Form.Item
                        name={'email'}
                        rules={[
                          {
                            type: 'email',
                            message: '이메일 형식이 올바르지 않습니다.',
                          },
                          {
                            required: true,
                            message: '이메일을 입력하여 주세요',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr>
                    <th>휴대폰번호</th>
                    <td colSpan={3}>
                      <Form.Item
                        name={'mobilePhone'}
                        rules={[
                          { required: true, message: '휴대폰 번호를 입력하여 주세요.' },
                          { max: 11, message: '휴대폰 번호를 다시 확인해 주세요. ' },
                          { pattern: /(\d{3})(\d{4})(\d{4})/, message: 'ex) 01012341234 (으)로 입력하여주세요' },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr>
                    <th>주소</th>
                    <td colSpan={3}>
                      <Row gutter={5} style={{ paddingTop: 5 }}>
                        <Col width={150}>
                          <Input
                            onChange={(e) => setZipCode(e.target.value)}
                            value={zipCode}
                            style={{ marginBottom: 5 }}
                          />
                        </Col>
                        <Col>
                          <PostcodeFinder onComplete={handleComplete}>
                            <Button>우편번호 찾기</Button>
                          </PostcodeFinder>
                        </Col>
                      </Row>
                      <Row gutter={[5, 5]}>
                        <Col xs={24} sm={12}>
                          <Input onChange={(e) => setAddress(e.target.value)} value={address} />
                        </Col>
                        <Col xs={24} sm={12}>
                          <Input onChange={(e) => setDetailedAddress(e.target.value)} value={detailedAddress} />
                        </Col>
                      </Row>
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )}

          {['PRO_CONSULTANT', 'RELATIONSHIP_MANAGER'].includes(type) ? (
            <>
              <h5 style={{ marginTop: 40 }}>기관정보</h5>
              <table className={'details-table'}>
                <colgroup>
                  <col width={'150'} />
                  <col width={'auto'} />
                  <col width={'150'} />
                  <col width={'auto'} />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      기관명 <RedFont> * </RedFont>{' '}
                    </th>
                    <td>
                      {principal.type === 'ADMIN' && (
                        <FindInstitution onComplete={setInstitution} style={{ marginRight: 10 }}>
                          <Button>기관찾기</Button>
                        </FindInstitution>
                      )}
                      {institution?.name}
                    </td>
                    <th>기관코드</th>
                    <td>{institution?.code}</td>
                  </tr>
                  <tr>
                    <th>사업자등록번호</th>
                    <td colSpan={3}>{institution?.crn}</td>
                  </tr>
                </tbody>
              </table>
            </>
          ) : (
            <>
              <h5 style={{ marginTop: 40 }}>센터정보</h5>
              <table className={'details-table'}>
                <colgroup>
                  <col width={'150'} />
                  <col width={'auto'} />
                  <col width={'150'} />
                  <col width={'auto'} />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      센터명 <RedFont> * </RedFont>{' '}
                    </th>
                    <td>
                      {principal.type === 'ADMIN' && (
                        <FindCenter onComplete={setCenter} style={{ marginRight: 10 }}>
                          <Button>센터찾기</Button>
                        </FindCenter>
                      )}
                      {center?.name}
                    </td>
                    <th>센터코드</th>
                    <td>{center?.code}</td>
                  </tr>
                  <tr>
                    <th>사업자등록번호</th>
                    <td>{center?.crn}</td>
                    <th>대표전화번호</th>
                    <td>{center?.phoneNumber}</td>
                  </tr>
                  <tr>
                    <th>센터주소</th>
                    <td colSpan={3}>
                      [{center?.zipCode}] {center?.address}
                      <br /> {center?.detailedAddress}
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )}

          {!params?.id && (
            <>
              <h5 style={{ marginTop: 40 }}>기본정보</h5>
              <table className={'details-table'}>
                <colgroup>
                  <col width={'150'} />
                  <col width={'auto'} />
                  <col width={'150'} />
                  <col width={'auto'} />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      이름 <RedFont> * </RedFont>{' '}
                    </th>
                    <td>
                      <Form.Item name={'name'} rules={[{ required: true, message: '이름은 반드시 입력해야 합니다.' }]}>
                        <Input />
                      </Form.Item>
                    </td>
                    <th>
                      생년월일 <RedFont> * </RedFont>
                    </th>
                    <td>
                      <Form.Item
                        name={'birthday'}
                        rules={[{ required: true, message: '이름은 반드시 입력해야 합니다.' }]}
                      >
                        <DatePicker />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      이메일 <RedFont> * </RedFont>{' '}
                    </th>
                    <td>
                      <Form.Item
                        name={'email'}
                        rules={[
                          {
                            type: 'email',
                            message: '이메일 형식이 올바르지 않습니다.',
                          },
                          {
                            required: true,
                            message: '이메일을 입력하여 주세요',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </td>
                    <th>
                      휴대폰번호 <RedFont> * </RedFont>{' '}
                    </th>
                    <td>
                      <Form.Item
                        name={'mobilePhone'}
                        rules={[
                          { required: true, message: '휴대폰 번호를 입력하여 주세요.' },
                          { max: 11, message: '휴대폰 번호를 다시 확인해 주세요. ' },
                          { pattern: /(\d{3})(\d{3,4})(\d{4})/, message: 'ex) 01012341234 (으)로 입력하여주세요' },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr>
                    <th>주소</th>
                    <td colSpan={3}>
                      <Row gutter={5} style={{ paddingTop: 5 }}>
                        <Col width={150}>
                          <Input
                            onChange={(e) => setZipCode(e.target.value)}
                            value={zipCode}
                            style={{ marginBottom: 5 }}
                          />
                        </Col>
                        <Col>
                          <PostcodeFinder onComplete={handleComplete}>
                            <Button>우편번호 찾기</Button>
                          </PostcodeFinder>
                        </Col>
                      </Row>
                      <Row gutter={[5, 5]}>
                        <Col xs={24} sm={12}>
                          <Input onChange={(e) => setAddress(e.target.value)} value={address} />
                        </Col>
                        <Col xs={24} sm={12}>
                          <Input onChange={(e) => setDetailedAddress(e.target.value)} value={detailedAddress} />
                        </Col>
                      </Row>
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )}

          <h5 style={{ marginTop: 40 }}>상담원 정보</h5>
          <table className={'details-table'}>
            <colgroup>
              <col width={'150'} />
              <col width={'auto'} />
              <col width={'150'} />
              <col width={'auto'} />
            </colgroup>
            <tbody>
              <tr>
                <th>
                  근무형태 <RedFont> * </RedFont>
                </th>
                <td>
                  <Form.Item
                    name={'workerType'}
                    rules={[{ required: true, message: '근무형태는 반드시 선택해야 합니다.' }]}
                  >
                    <Select style={{ width: '100%' }}>
                      {Object.keys(WORK_TYPE).map((key) => (
                        <Select.Option key={key} value={key}>
                          {WORK_TYPE[key]}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </td>
                <th>
                  입사일 <RedFont> * </RedFont>
                </th>
                <td>
                  <Form.Item
                    name={'effectiveDate'}
                    rules={[{ required: true, message: '입사일은 반드시 선택해야 합니다.' }]}
                  >
                    <DatePicker style={{ width: '100%' }} />
                  </Form.Item>
                </td>
              </tr>
              {['CENTER_MANAGER', 'RELATIONSHIP_MANAGER'].includes(type) && withChild !== undefined && (
                <tr>
                  <th>
                    겸직 여부 <RedFont> * </RedFont>
                  </th>
                  <td colSpan={3}>
                    {type === 'RELATIONSHIP_MANAGER' && (
                      <>
                        <Form.Item
                          name={'withChild'}
                          initialValue={withChild}
                          rules={[{ required: true, message: '겸직여부는 반드시 선택해야 합니다.' }]}
                        >
                          <Radio.Group onChange={(e) => setWithChild(e.target.value)}>
                            <Radio value={false}>RM</Radio>
                            <Radio value={true}>RM + 전문 상담원</Radio>
                          </Radio.Group>
                        </Form.Item>
                        <p style={{ margin: 0, paddingTop: 5 }}>
                          기본상담원 역할 겸직을 원할 경우 교육수료등급, 상담원레벨, 전문분야, 상담단가를 입력해주세요.
                        </p>
                      </>
                    )}
                    {type === 'CENTER_MANAGER' && (
                      <>
                        <Form.Item
                          name={'withChild'}
                          initialValue={withChild}
                          rules={[{ required: true, message: '겸직여부는 반드시 선택해야 합니다.' }]}
                        >
                          <Radio.Group onChange={(e) => setWithChild(e.target.value)}>
                            <Radio value={false}>센터장</Radio>
                            <Radio value={true}>센터장 + 일반 상담원</Radio>
                          </Radio.Group>
                        </Form.Item>
                        <p style={{ margin: 0, paddingTop: 5 }}>
                          기본상담원 역할 겸직을 원할 경우 교육수료등급, 상담원레벨, 전문분야, 상담단가를 입력해주세요.
                        </p>
                      </>
                    )}
                  </td>
                </tr>
              )}

              {withChild && (
                <>
                  <tr>
                    <th>교육수료등급</th>
                    <td>
                      <Form.Item name={'educationCompletionLevel'}>
                        <Select style={{ width: '100%' }}>
                          {Object.keys(EDUCATION).map((key) => (
                            <Select.Option key={key} value={key}>
                              {EDUCATION[key]}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </td>
                    <th>상담원레벨</th>
                    <td>
                      <Form.Item name={'consultantLevel'}>
                        <Select style={{ width: '100%' }}>
                          {Object.keys(CONSULTATION_LEVEL).map((key) => (
                            <Select.Option key={key} value={key}>
                              {CONSULTATION_LEVEL[key]}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </td>
                  </tr>
                  <tr>
                    <th>전문분야</th>
                    <td>
                      <Row gutter={5}>
                        <Col span={12}>
                          <Form.Item name={'professionalField'}>
                            <Select
                              style={{ width: '100%' }}
                              onChange={(v) => setProfessionalField(v)}
                              value={professionalField}
                            >
                              {Object.keys(PROFESSIONAL_FILELD).map((key) => (
                                <Select.Option value={key} key={key}>
                                  {key}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item name={'professionalField2'}>
                            <Select style={{ width: '100%' }}>
                              {Object.keys(PROFESSIONAL_FILELD_SUB)
                                .filter((key) => PROFESSIONAL_FILELD_SUB[key][1] === professionalField)
                                .map((key) => (
                                  <Select.Option value={key} key={key}>
                                    {PROFESSIONAL_FILELD_SUB[key][0]}
                                  </Select.Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </td>
                    <th>
                      상담단가(건당) <RedFont> * </RedFont>
                    </th>
                    <td>
                      <Form.Item
                        name={'consultationPrice'}
                        rules={[{ required: true, message: '상담단가는 반드시 선택해야 합니다.' }]}
                      >
                        <InputNumber
                          style={{ width: '100%' }}
                          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={(value) => value.replace(/(,*)/g, '')}
                        />
                      </Form.Item>
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </Card>

        <div>
          <Row style={{ marginTop: 10 }}>
            <Col flex={1}></Col>
            <Col flex={1} style={{ textAlign: 'right' }}>
              <Button htmlType="button" onClick={() => goToBack()}>
                목록으로
              </Button>{' '}
              <Button htmlType="submit" type="primary">
                저장
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </Form>
  );
};

export default ManagerForm;
