import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useWindowsDim } from '@common/hooks/useWindowsDim';
import { produce } from 'immer';
import { Button, Card, Col, Form, Input, Row, Select, Table, Checkbox } from 'antd';
import { bsApi } from '@common/api';
import moment from 'moment';
import { NOTICE_CATEGORY_TYPE, searchItemLayout } from '@common/constants';
import { Link } from 'react-router-dom';
import { useAppState } from '@components/shared/AppProvider';
import useListForm from '@common/hooks/useListForm';

const columnsOrigin = [
  {
    title: 'NO',
    dataIndex: 'rowNum',
    key: 'rowNum',
    width: 80,
    fixed: 'left',
    align: 'center',
  },
  {
    title: '카테고리',
    dataIndex: 'category',
    key: 'category',
    width: 120,
    align: 'center',
    render: (v) => NOTICE_CATEGORY_TYPE[v],
  },
  {
    title: '제목',
    dataIndex: 'title',
    key: 'title',
    width: 'auto',
    minWidth: 260,
  },
  {
    title: '첨부파일',
    dataIndex: 'attachFiles',
    key: 'attachFiles',
    align: 'center',
    width: 80,
    render: (data) => data.length,
  },
  {
    title: '공개여부',
    dataIndex: 'display',
    key: 'display',
    width: 120,
    align: 'center',
    render: (data) => (data ? '공개' : '비공개'),
  },
  {
    title: '작성자',
    dataIndex: 'createdBy',
    key: 'createdBy',
    width: 160,
    align: 'center',
    render: (value) => value?.name,
  },
  {
    title: '작성일자',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 180,
    align: 'center',
    render: (text, record, index) => moment(text).format('YYYY-MM-DD HH:mm'),
  },
  {
    title: '관리',
    key: 'operation',
    width: 100,
    align: 'center',
    fixed: 'right',
  },
];

const initialQuery = {
  page: 0,
  size: 20,
  category: undefined,
  display: undefined,
  searchType: undefined,
  keyword: '',
};

const NoticeIndex = (props) => {
  const fetchData = useCallback(async (query) => {
    const { data: response } = await bsApi.get('/notices', { params: query });
    return {
      rows: response?._embedded?.responseList || [],
      pagination: {
        current: response?.page?.number + 1,
        pageSize: response?.page?.size,
        total: response?.page?.totalElements || 0,
      },
    };
  }, []);

  const { form, tableProps, onFormFinish, onFormReset } = useListForm({
    ...props,
    fetchData,
    initialQuery,
    columnsOrigin,
    applyColumns: (draft, data) => {
      draft[0].render = (text, record, index) => {
        const page = data?.pagination;
        return page?.total - page?.pageSize * (page?.current - 1) - index;
      };

      draft[7].render = (record) => (
        <Link to={`/notices/${record.id}`}>
          <Button htmlType={'button'} size={'small'}>
            상세
          </Button>
        </Link>
      );
    },
  });

  return (
    <div>
      <h2>공지사항</h2>
      <Form ref={form} onFinish={onFormFinish}>
        <Card>
          <Form.Item {...searchItemLayout} label="선택검색">
            <Row type={'flex'} gutter={5}>
              <Col span={12}>
                <Form.Item name={'display'} noStyle>
                  <Select style={{ flex: 1, marginRight: 5 }} placeholder="게시물 상태" allowClear>
                    <Select.Option value={true}>노출</Select.Option>
                    <Select.Option value={false}>미노출</Select.Option>
                  </Select>
                  {/* <Checkbox onChange={(e) => console.log(e.target.checked)}>미노출</Checkbox> */}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name={'category'} noStyle>
                  <Select style={{ flex: 1, marginRight: 5 }} placeholder="카테고리 선택" allowClear>
                    {Object.keys(NOTICE_CATEGORY_TYPE).map((key) => (
                      <Select.Option key={key} value={key}>
                        {NOTICE_CATEGORY_TYPE[key]}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item {...searchItemLayout} label="상세검색">
            <Row type={'flex'}>
              <Col width={300}>
                <Form.Item name={'searchType'} noStyle>
                  <Select style={{ width: 150, marginRight: 5 }} placeholder="전체" allowClear>
                    <Select.Option value="title">제목</Select.Option>
                    <Select.Option value="contentsPlainText">내용</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col flex={1}>
                <Form.Item name={'keyword'} noStyle>
                  <Input placeholder={'검색어를 입력하세요.'} />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
        </Card>
        <div style={{ marginTop: 10 }}>
          <Row>
            <Col xs={12}></Col>
            <Col style={{ textAlign: 'right' }} xs={12}>
              <Button htmlType="button" onClick={onFormReset}>
                초기화
              </Button>{' '}
              <Button htmlType="submit" type="primary">
                검색
              </Button>
            </Col>
          </Row>
        </div>
      </Form>

      <div style={{ marginTop: 25 }} bodystyle={{ padding: 0 }}>
        <Table
          {...tableProps}
          rowKey={(record) => record.id}
          footer={() => (
            <Row>
              <Col xs={12}></Col>
              <Col style={{ textAlign: 'right' }} xs={12}>
                <Link to={'/notices/create'}>
                  <Button htmlType="button" type="primary">
                    등록
                  </Button>
                </Link>
              </Col>
            </Row>
          )}
        />
      </div>
    </div>
  );
};

export default NoticeIndex;
