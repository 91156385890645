import React, { useState, useEffect } from 'react';
import TodoResourceForm from '@widgets/todo/todoResourceForm';
import { bsApi } from '@common/api';

const ResourceDetail = ({ history, match }) => {
  const { params } = match || {};

  const [currentData, setCurrentData] = useState();
  const [toDoMessages, setToDoMessages] = useState([]);

  const fetchData = async (id) => {
    let args = {};
    if (params?.id !== 'add') {
      const res = await bsApi.get(`/todo/todo-resources/detail/${id}`);
      args = { ...res?.data };
      args.organizationId = res?.data?.organization?.id;
      args.bigCategoryId = res?.data?.bigCategory?.id;
      args.selectedOrganization = res?.data?.organization;
      args.smallCategoryId = res?.data?.smallCategory?.id;
      args.toDoMessageIds = res?.data?.toDoMessages.map((v) => v['id']);
      args.smallCategory = [];
    }
    const orgnz = await bsApi.get('/todo/organizations');
    const big = await bsApi.get('/todo-category/big');
    if(params?.id !== 'add') {
      const small = await bsApi.get(`/todo-category/small/${args.bigCategoryId}`);
      args.smallCategory = small?.data || [];
    } else {
      args.smallCategory = [];
    }
    args.organization = orgnz?.data;
    args.bigCategory = big?.data;
    setCurrentData({...args});
    const messageData = await bsApi.get('/todo/todo-messages/all');
    setToDoMessages(messageData?.data || []);
  };

  useEffect(() => {
    fetchData(params?.id).catch(console.error);
  }, [params?.id]);
  console.log(currentData);
  return (
    <div>
      {toDoMessages.length > 0 ? (
        <TodoResourceForm currentData={currentData} toDoMessages={toDoMessages} params={params?.id} />
      ) : (
        <span>loading...</span>
      )}
    </div>
  );
};
export default ResourceDetail;
