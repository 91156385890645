import React, { useCallback } from 'react';
import { Button, Card, Col, DatePicker, Form, Radio, Row, Select, Table } from 'antd';
import { bsApi } from '@common/api';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { DATETIME_FORMAT, PUSH_STATUS, searchItemLayout } from '@common/constants';
import useListForm from '@common/hooks/useListForm';

const columnsOrigin = [
  {
    title: 'NO',
    dataIndex: 'rowNum',
    key: 'rowNum',
    width: 80,
    fixed: 'left',
    align: 'center',
  },
  {
    title: '메세지내용',
    dataIndex: 'body',
    key: 'body',
    width: 'auto',
    minWidth: 300,
  },
  {
    title: '상태',
    dataIndex: 'status',
    key: 'status',
    width: 100,
    align: 'center',
    render: (v) => PUSH_STATUS[v],
  },
  {
    title: '발신일',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 160,
    align: 'center',
    render: (text, record, index) => moment(text).format(DATETIME_FORMAT),
  },
  {
    title: '발송완료',
    dataIndex: 'updatedOn',
    key: 'updatedOn',
    width: 160,
    align: 'center',
    render: (v, row) => row.status !== 'IN_PREPARATION' && moment(v).format(DATETIME_FORMAT),
  },
  {
    title: '성공',
    dataIndex: 'successCount',
    key: 'successCount',
    width: 100,
    align: 'center',
    render: (v) => v || 0,
  },
  {
    title: '실패',
    dataIndex: 'failureCount',
    key: 'failureCount',
    width: 100,
    align: 'center',
    render: (v) => v || 0,
  },
  {
    title: '관리',
    key: 'operation',
    width: 80,
    align: 'center',
    fixed: 'right',
    render: (record) => (
      <Link to={`/pushes/${record.id}`}>
        <Button htmlType={'button'} size={'small'}>
          상세
        </Button>
      </Link>
    ),
  },
];

const initialQuery = {
  page: 0,
  size: 20,
  status: undefined,
  periodType: 'createdAt',
  fromDate: undefined,
  toDate: undefined,
};

const PushIndex = (props) => {
  const fetchData = useCallback(async (query) => {
    const { data: response } = await bsApi.get('/notifications', { params: query });
    return {
      rows: response?._embedded?.responseList || [],
      pagination: {
        current: response?.page?.number + 1,
        pageSize: response?.page?.size,
        total: response?.page?.totalElements || 0,
      },
    };
  }, []);

  const { form, tableProps, onFormDateRangeChange, onFormFinish, onFormReset } = useListForm({
    ...props,
    fetchData,
    initialQuery,
    columnsOrigin,
    applyColumns: (draft, data) => {
      draft[0].render = (text, record, index) => {
        const page = data?.pagination;
        return page?.total - page?.pageSize * (page?.current - 1) - index;
      };
    },
  });

  return (
    <div>
      <h2>PUSH 발송</h2>
      <Form ref={form} onFinish={onFormFinish}>
        <Card>
          <Form.Item {...searchItemLayout} label="기간검색">
            <Row type={'flex'} gutter={[5, 5]}>
              <Col>
                <Form.Item name="periodType" noStyle>
                  <Select style={{ width: 150, marginRight: 5 }}>
                    <Select.Option value="createdAt">발송시작</Select.Option>
                    <Select.Option value="updatedOn">발송완료</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col flex={1}>
                <Form.Item name="dateRange" noStyle>
                  <DatePicker.RangePicker
                    style={{ marginRight: 5 }}
                    disabled={[false, false]}
                    allowClear
                    allowEmpty={[true, true]}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Button.Group>
                  <Button onClick={() => onFormDateRangeChange(0)}>오늘</Button>
                  <Button onClick={() => onFormDateRangeChange(7)}>7일</Button>
                  <Button onClick={() => onFormDateRangeChange(30)}>30일</Button>
                  <Button onClick={() => onFormDateRangeChange(365)}>1년</Button>
                </Button.Group>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item name={'status'} {...searchItemLayout} label="발송결과">
            <Radio.Group>
              <Radio value={''}>전체</Radio>
              {Object.keys(PUSH_STATUS).map((key) => (
                <Radio value={key} key={key}>
                  {PUSH_STATUS[key]}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        </Card>
        <div style={{ marginTop: 10 }}>
          <Row>
            <Col xs={12}></Col>
            <Col style={{ textAlign: 'right' }} xs={12}>
              <Button htmlType="button" onClick={onFormReset}>
                초기화
              </Button>{' '}
              <Button htmlType="submit" type="primary">
                검색
              </Button>
            </Col>
          </Row>
        </div>
      </Form>

      <div style={{ marginTop: 25 }}>
        <Table
          {...tableProps}
          rowKey={(record) => record.id}
          footer={() => (
            <Row>
              <Col xs={12}></Col>
              <Col style={{ textAlign: 'right' }} xs={12}>
                <Link to={'/pushes/create'}>
                  <Button htmlType="button" type="primary">
                    등록
                  </Button>
                </Link>
              </Col>
            </Row>
          )}
        />
      </div>
    </div>
  );
};

export default PushIndex;
