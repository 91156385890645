import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, Input, message, Radio, Row } from 'antd';
import { useHistory } from 'react-router-dom';
import { bsApi } from '@common/api';
import { NOTICE_CATEGORY_TYPE } from '@common/constants';
import UploadFile from '@components/UploadFile';
import { red } from '@ant-design/colors';
import { distinct } from '@common/helpers';
import { shallowEqual, useSelector } from 'react-redux';
import styled from 'styled-components';

const RedFont = styled.span`
  color: red;
`;
const NoticeDetails = ({ history, match }) => {
  const { principal } = useSelector((s) => s.auth, shallowEqual);

  const { params } = match || {};

  const historyPush = useHistory();
  const handleSubmit = async (data) => {
    if (!['ADMIN'].includes(principal.type)) return;
    const args = {
      ...data,
      attachFiles: distinct(attachFiles),
    };

    if (!params.id) {
      //새로 등록
      await bsApi.post(`/notices`, args);
      alert('저장되었습니다.');
      history.push('/notices');
      // history.goBack();
    } else {
      //수정
      await bsApi.put(`/notices/${params.id}`, args);
      alert('저장되었습니다.');
      history.push('/notices');
      // history.goBack();
    }
  };

  //목록으로 돌아가기
  const handleBack = async () => {
    if (confirm('게시물을 저장하지 않고 목록으로 돌아갑니다.') === true) {
      history.push('/notices');
      // history.goBack();
    } else {
      return;
    }
  };

  const handleDelete = async () => {
    if (!confirm('정말 삭제하시겠습니까?')) return;
    await bsApi.delete(`/notices/${params?.id}`);
    alert('삭제 되었습니다.');
    // history.goBack();
    history.push('/notices');
  };

  const form = useRef();
  const [attachFiles, setAttachFiles] = useState([]);

  useEffect(() => {
    if (params?.id) {
      const loadData = async () => {
        const { data } = await bsApi.get(`/notices/${params?.id}`);
        form?.current?.setFieldsValue(data);
        setAttachFiles(distinct(data?.attachFiles));
      };

      loadData().catch((e) => {
        history.goBack();
      });
    }
  }, []);

  return (
    <div>
      <h2>공지사항</h2>
      <Form ref={form} onFinish={handleSubmit}>
        <div className={'pageWrapper'}>
          <Card>
            <h5>기본정보</h5>
            <table className={'details-table'}>
              <colgroup>
                <col width={'150'} />
                <col width={'auto'} />
                <col width={'150'} />
                <col width={'auto'} />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    카테고리 <RedFont>*</RedFont>{' '}
                  </th>
                  <td colSpan={3}>
                    <Form.Item name={'category'} rules={[{ required: true, message: '카테고리 항목은 필수입니다.' }]}>
                      <Radio.Group disabled={!['ADMIN'].includes(principal.type)}>
                        {Object.keys(NOTICE_CATEGORY_TYPE).map((key) => (
                          <Radio key={key} value={key}>
                            {NOTICE_CATEGORY_TYPE[key]}
                          </Radio>
                        ))}
                      </Radio.Group>
                    </Form.Item>
                  </td>
                </tr>
                <tr>
                  <th>
                    제목 <RedFont>*</RedFont>{' '}
                  </th>
                  <td colSpan={3}>
                    <Form.Item name={'title'} rules={[{ required: true, message: '제목을 입력하세요.' }]}>
                      <Input readOnly={!['ADMIN'].includes(principal.type)} />
                    </Form.Item>
                  </td>
                </tr>
                <tr>
                  <th>
                    내용 <RedFont>*</RedFont>{' '}
                  </th>
                  <td colSpan={3}>
                    <Form.Item name={'contents'} rules={[{ required: true, message: '내용을 입력하세요.' }]}>
                      <Input.TextArea autoSize={{ minRows: 10 }} readOnly={!['ADMIN'].includes(principal.type)} />
                    </Form.Item>
                  </td>
                </tr>
                <tr>
                  <th>첨부파일</th>
                  <td colSpan={3}>
                    <UploadFile
                      onChange={setAttachFiles}
                      ids={attachFiles}
                      disabled={!['ADMIN'].includes(principal.type)}
                    />
                  </td>
                </tr>
                {['ADMIN'].includes(principal.type) && (
                  <>
                    <tr>
                      <th>공개여부</th>
                      <td colSpan={3}>
                        <Form.Item name={'display'} initialValue={true}>
                          <Radio.Group>
                            <Radio value={true}>공개</Radio>
                            <Radio value={false}>비공개</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </td>
                    </tr>
                    <tr>
                      <th>상단공지</th>
                      <td colSpan={3}>
                        <Form.Item name={'topNotice'} initialValue={false}>
                          <Radio.Group>
                            <Radio value={true}>설정</Radio>
                            <Radio value={false}>미설정</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </td>
                    </tr>
                  </>
                )}
                {!params.id && (
                  <tr>
                    <th>푸쉬발송</th>
                    <td colSpan={3}>
                      <Form.Item name={'sendPush'} initialValue={false}>
                        <Radio.Group>
                          <Radio value={true}>발송</Radio>
                          <Radio value={false}>미발송</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </Card>

          {['ADMIN'].includes(principal.type) && (
            <div>
              <Row style={{ marginTop: 10 }}>
                <Col flex={1}>
                  {!!params.id && (
                    <Button
                      onClick={handleDelete}
                      htmlType="button"
                      type={'danger'}
                      style={{
                        backgroundColor: red[6],
                        borderColor: red[7],
                        color: 'white',
                        fontWeight: 'bold',
                      }}
                    >
                      삭제
                    </Button>
                  )}
                </Col>
                <Col flex={1} style={{ textAlign: 'right' }}>
                  <Button htmlType="button" onClick={handleBack}>
                    목록으로
                  </Button>{' '}
                  <Button htmlType="submit" type="primary">
                    저장
                  </Button>
                </Col>
              </Row>
            </div>
          )}
        </div>
      </Form>
    </div>
  );
};

export default NoticeDetails;
