import React, { useEffect, useCallback, useState } from 'react';
import { Button, Card, Table, Form, Popconfirm, Input } from 'antd';
import { bsApi } from '@common/api';
import { Link } from 'react-router-dom';

const EditTableCell = ({ editing, dataIndex, title, record, index, children, ...restProps }) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rule={[
            {
              required: true,
              message: `내용을 입력하세요`,
            },
          ]}
        >
          <Input />
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const Categorys = (props) => {
  const bigColumns = [
    {
      title: 'id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '대분류',
      dataIndex: 'bigCategory',
      key: 'bigCategory',
      editable: true,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => {
        const editable = isEditing(record);
        return (
          <div>
            <Popconfirm
              title="대분류를 삭제하게 되면 속해있는 소분류들도 삭제됩니다. 삭제하시겠습니까?"
              onConfirm={() => deleteBigCategoryt(record.id)}
            >
              <Button>삭제</Button>
              {'  '}
            </Popconfirm>
            {editable ? (
              <>
                <Button onClick={() => save(record.id)}>저장</Button>
                {'  '}
                <Button onClick={() => cancel()}>취소</Button>
              </>
            ) : (
              <Button onClick={() => edit(record)}>수정</Button>
            )}
            <Link to={`/todo/category/sub/${record.id}/${record.bigCategory}`}>
              <Button>상세</Button>
            </Link>
          </div>
        );
      },
    },
  ];

  const [form] = Form.useForm();
  const [bigCategorys, setBigCategorys] = useState();
  const [smallCategorys, setSmallCategorys] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const isEditing = (record) => record.id === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      bigCategory: '',
      ...record,
    });
    setEditingKey(record.id);
  };
  const cancel = () => {
    setEditingKey('');
  };

  const save = useCallback(
    async (id) => {
      try {
        const row = await form.validateFields();
        if (row?.bigCategory) {
          const saved = id
            ? await bsApi.put(`/todo-category/big/${id}`, { bigCategory: row?.bigCategory })
            : await bsApi.post('/todo-category/big', { bigCategory: row?.bigCategory });
          setBigCategorys([...saved?.data]);
          setEditingKey('');
        }
      } catch (e) {
        console.log('validate...fail..', e);
      }
    },
    [bigCategorys],
  );

  const deleteBigCategoryt = useCallback(
    async (id) => {
      if (!id) {
        if (bigCategorys) {
          const test = bigCategorys;
          test.splice(test.length - 1, 1);
          setBigCategorys([...test]);
          return;
        }
      } else {
        const res = await bsApi.delete(`/todo-category/big/${id}`);
        setBigCategorys([...res?.data]);
      }
    },
    [bigCategorys],
  );

  const addBigRow = useCallback(() => {
    const newData = {
      id: null,
      bigCategory: '',
    };

    setBigCategorys([...bigCategorys, newData]);
  }, [bigCategorys]);

  const fetchData = useCallback(async () => {
    const bigCategory = await bsApi.get('/todo-category/big');
    setBigCategorys(bigCategory.data);
  }, [bigCategorys]);

  useEffect(() => {
    if (!bigCategorys) {
      fetchData().catch(console.error);
    }
  }, [bigCategorys]);

  const mergedColumns = bigColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputtype: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Card>
      <h2>대분류 정보</h2>
      {bigCategorys && (
        <div>
          <Button
            type="primary"
            style={{
              marginBottom: 16,
            }}
            onClick={() => addBigRow()}
          >
            대분류 추가
          </Button>
          <Form form={form} component={false}>
            <Table
              components={{
                body: {
                  cell: EditTableCell,
                },
              }}
              rowKey={(record) => record.id}
              columns={mergedColumns}
              bordered
              pagination={false}
              dataSource={bigCategorys}
            />
          </Form>
        </div>
      )}
    </Card>
  );
};

export default Categorys;
