import React, { useEffect, useState, useCallback, useRef } from 'react';
import { bsApi } from '@common/api';
import FindConsultant from '@components/FindConsultant';
import FindRM from '@components/FindRM';
import { uniqBy } from 'lodash';

import { shallowEqual, useSelector } from 'react-redux';

import { Card, Descriptions, Select, Button, Form, Input, message } from 'antd';

import {
  PROFESSIONAL_FILELD,
  PROFESSIONAL_FILELD_SUB,
  WANT_TIME,
  CONSULTATION_STATUS,
  CONSULT_TYPE,
} from '@common/constants';
import FindInstitution from '@components/FindInstitution';

const { Option } = Select;
const consultEnd = ['COMPLETE', 'PRO_RECEIPT', 'PRO_COMPANION', 'PRO_ACCEPT', 'PRO_COMPLETE'];

const TypeOfFindConsult = ({
  currentStatus,
  chanageData,
  disabled,
  account,
  principal,
  addQuery,
  consultationStatus,
}) => {
  const endStatus = 'PRO_APPLY';

  console.log('hi....');
  const RMComponent = (
    <FindRM onComplete={chanageData} style={{ marginRight: 10 }} userType={principal.type}>
      <Button disabled={disabled}>RM 찾기</Button>
    </FindRM>
  );

  const ConsultantComponent = (
    <FindConsultant onComplete={chanageData} style={{ marginRight: 10 }} userType={principal.type} addQuery={addQuery}>
      <Button disabled={disabled}>상담원 찾기</Button>
    </FindConsultant>
  );
  if ('APPLY' === currentStatus && principal.type === 'ADMIN') {
    return ConsultantComponent;
  }
  if ('PRO_APPLY' === consultationStatus && principal.id === account?.id) {
    return RMComponent;
  }

  if (['RECEIPT', 'APPLY'].indexOf(currentStatus) >= 0 && principal.id === account?.id) {
    return <Button disabled> 상담원 찾기 </Button>;
  }

  return ConsultantComponent;
};

const ConsultInfo = ({ data, principal, queryId, fetchData }) => {
  const [account, setAccount] = useState(null);
  const [consultationStatus, setConsultationStatus] = useState(data.status);
  const [userSpecificStatus, setUserSpecificStatus] = useState(null);

  console.log(account);
  console.log(consultationStatus);
  console.log(userSpecificStatus);
  const fetchStatus = useCallback(async (test) => {
    // 상담사 조건 별 상태
    const { status, data: response } = await bsApi.get(
      //상담 정보 기본
      '/account-select/status-list/' + test,
    );
    const statusList = response;
    if (statusList > 1) {
      statusList[response.length] = data.status;
    }
    setUserSpecificStatus(uniqBy(statusList, (e) => e));
  }, []);
  console.log(userSpecificStatus);
  useEffect(() => {
    fetchStatus(data.status);
  }, [data.status]);

  // 상담 상태 변경
  const changeStatus = (value) => {
    setConsultationStatus(value);
  };

  const chanageData = (value) => {
    setAccount(value);
  };

  //상담 기본 정보 변경
  const changeConsultation = async () => {
    const returnValues = {};
    console.log('returnValues1', returnValues);
    console.log('account type', account?.type); //null
    console.log('account', account); //null
    console.log('data', data); //null
    if (account?.type === 'RELATIONSHIP_MANAGER') {
      returnValues.proConsultantId = null;
      returnValues.consultantId = data?.consultant?.id;
      returnValues.institutionId = account.organization.id;
      returnValues.type = 'PRO_CONSULTATION';
      returnValues.status = consultationStatus;
    } else {
      switch (consultationStatus) {
        case 'COMPANION':
          returnValues.proConsultantId = null;
          returnValues.consultantId = null;
          returnValues.institutionId = null;
          returnValues.type = 'BASIC_CONSULTATION';
          returnValues.status = consultationStatus;
          break;

        case 'RECEIPT':
          returnValues.proConsultantId = null;
          returnValues.consultantId = account?.id || data?.consultant?.id;
          returnValues.institutionId = null;
          returnValues.type = 'BASIC_CONSULTATION';
          returnValues.status = consultationStatus;

          if (!returnValues.consultantId) {
            alert('상담원 지정이 필요합니다.');
            return;
          }
          break;

        case 'ACCEPT':
          returnValues.proConsultantId = null;
          returnValues.institutionId = null;
          returnValues.consultantId = account?.id || data?.consultant?.id;
          returnValues.type = 'BASIC_CONSULTATION';
          returnValues.status = consultationStatus;

          if (!returnValues.consultantId) {
            alert('상담원 지정이 필요합니다.');
            return;
          }

          break;

        case 'PRO_APPLY':
          returnValues.proConsultantId = null;
          returnValues.consultantId = data?.consultant?.id;
          returnValues.institutionId = account?.organizationId || data?.consultant?.organizationId;
          returnValues.type = 'PRO_CONSULTATION';
          returnValues.status = consultationStatus;

          if (!returnValues.institutionId) {
            alert('RM 지정이 필요합니다.');
            return;
          }

          break;

        default:
          returnValues.type = 'BASIC_CONSULTATION';
          returnValues.status = consultationStatus;
          break;
      }
    }

    console.log(returnValues);

    console.log('returnValues:::', returnValues);
    try {
      const { status, response } = await bsApi.put(
        //상담 정보 기본
        '/consultations/' + queryId,
        returnValues,
      );
      alert('수정 완료.');
      console.log('response::', response);
      fetchData();
      //  fetchHistory();
    } catch (e) {
      console.error(e);
    }
  };

  console.log('account', account);
  console.log('data', data);
  const isBasic =
    principal.authorities.indexOf('ADMIN') >= 0 ||
    principal.authorities.indexOf('CENTER_MANAGER') >= 0 ||
    principal.authorities.indexOf('CONSULTANT') >= 0;
  return (
    <div className={'pageWrapper'}>
      <Card>
        <h5>상담 정보 신청</h5>
        <table className={'details-table'}>
          <colgroup>
            <col width={'150'} />
            <col width={'auto'} />
            <col width={'150'} />
            <col width={'auto'} />
          </colgroup>
          <tbody>
            <tr>
              <th>희망상담일자</th>
              <td>{data.consultWantDate}</td>
              <th>희망상담시간</th>
              <td>{WANT_TIME[data.consultWantTime]}</td>
            </tr>
          </tbody>
        </table>

        <h5 style={{ marginTop: 20 }}>상담 진행 정보</h5>
        <table className={'details-table'}>
          <colgroup>
            <col width={'150'} />
            <col width={'auto'} />
            <col width={'150'} />
            <col width={'auto'} />
          </colgroup>
          <tbody>
            <tr>
              <th>상담진행상태</th>
              <td colSpan={3}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  {CONSULTATION_STATUS[data.status]}
                  {isBasic && (
                    <Select
                      defaultValue={consultationStatus}
                      onChange={changeStatus}
                      disabled={userSpecificStatus?.length <= 1}
                      style={{ marginLeft: 10, width: '150px' }}
                    >
                      {userSpecificStatus && userSpecificStatus.length > 0 ? (
                        userSpecificStatus.map((data) => {
                          return <Option value={data}>{CONSULTATION_STATUS[data]}</Option>;
                        })
                      ) : (
                        <Option value={consultationStatus}>{CONSULTATION_STATUS[data.status]}</Option>
                      )}
                    </Select>
                  )}
                </div>
              </td>
            </tr>
            <tr>
              <th>센터</th>
              <td>{data?.center?.name}</td>

              <th>상담원 검색</th>
              <td>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  {account !== null
                    ? account?.name
                    : (data?.consultant === null
                        ? `센터장 : ${data.center?.manager?.name}(${data.center?.manager?.username})`
                        : `${data.consultant?.name}(${data.consultant?.username})`) || ''}{' '}
                  {isBasic && (
                    <TypeOfFindConsult
                      currentStatus={data.status}
                      chanageData={chanageData}
                      consultationStatus={consultationStatus}
                      disabled={userSpecificStatus?.length <= 1}
                      addQuery={{ center: data.center }}
                      account={data?.consultant}
                      principal={principal}
                    />
                  )}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        {data.status.includes('COMPLETE') == false && data.status.includes('PRO') === false && (
          <Button onClick={changeConsultation} style={{ float: 'right', marginTop: 10 }}>
            상담 정보 수정
          </Button>
        )}

        {/*<h5 style={{ marginTop: 40 }}>기본 상담 이력</h5>*/}
        {/*<table className={'details-table'}>*/}
        {/*  <colgroup>*/}
        {/*    <col width={'150'} />*/}
        {/*    <col width={'auto'} />*/}
        {/*    <col width={'150'} />*/}
        {/*    <col width={'auto'} />*/}
        {/*  </colgroup>*/}
        {/*  <tbody>*/}
        {/*    <tr>*/}
        {/*      <th>기본 상담 신청</th>*/}
        {/*      <td>{data.createdBy === null ? '유저 상담 신청' : data.createdBy.name}</td>*/}
        {/*      <th>일자</th>*/}
        {/*      <td>{data.logDate}</td>*/}
        {/*    </tr>*/}
        {/*  </tbody>*/}
        {/*</table>*/}
      </Card>
    </div>
  );
};

const ConsultHistorys = ({ consultHistory, type }) => {
  return (
    <Card title="기본 상담 이력" style={{ marginTop: 25, marginBottom: 20 }}>
      <table className={'details-table'}>
        <colgroup>
          <col width={'150'} />
          <col width={'auto'} />
          <col width={'150'} />
          <col width={'auto'} />
        </colgroup>
        <tbody>
          {consultHistory.map((data, i) => (
            <tr>
              <th>{CONSULTATION_STATUS[data.status]}</th>
              <td>{data.createdBy === null ? '유저 상담 신청' : data.createdBy.name}</td>
              <th>일자</th>
              <td>{data.logDate}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Card>
  );
};

const ConsultInsert = ({ handleSubmit, saveSuccess }) => {
  const form = useRef();
  const [category2, setCategory2] = useState([]);
  const [saveBtn, setSaveBtn] = useState(false);

  const btnChange = async (values) => {
    setSaveBtn(true);
    handleSubmit(values);
    form.current.resetFields();
  };

  useEffect(() => {
    if (saveSuccess && saveBtn) {
      setSaveBtn(false);
    }
  }, [saveBtn, saveSuccess]);

  const changeCategory1 = (value) => {
    const values = [];
    Object.keys(PROFESSIONAL_FILELD_SUB).map((data) => {
      if (PROFESSIONAL_FILELD_SUB[data][1] === value) {
        if (PROFESSIONAL_FILELD_SUB[data][0] !== undefined) {
          return values.push(data);
        }
      }
    });
    setCategory2(values);
  };

  return (
    <>
      <Card>
        <Form ref={form} onFinish={btnChange}>
          <table className={'details-table'}>
            <colgroup>
              <col width={'150'} />
              <col width={'auto'} />
              <col width={'150'} />
              <col width={'auto'} />
            </colgroup>
            <tbody>
              <tr>
                <th>상담카테고리</th>
                <td>
                  <Form.Item name="category" noStyle>
                    <Select
                      style={{ width: 150, marginRight: 5 }}
                      allowClear
                      placeholder={'상담카테고리1'}
                      onChange={changeCategory1}
                    >
                      {Object.keys(PROFESSIONAL_FILELD).map((data) => {
                        return <Select.Option value={data}> {PROFESSIONAL_FILELD[data]} </Select.Option>;
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item name="subCategory" noStyle>
                    <Select style={{ width: 150, marginRight: 5 }} allowClear placeholder={'상담카테고리2'}>
                      {category2 &&
                        category2.map((data) => {
                          return <Select.Option value={data}> {PROFESSIONAL_FILELD_SUB[data][0]} </Select.Option>;
                        })}
                    </Select>
                  </Form.Item>
                </td>
                <th>상담유형</th>
                <td>
                  <Form.Item name="consultType" noStyle>
                    <Select style={{ width: 150, marginRight: 5 }} allowClear placeholder={'상담유형'}>
                      {Object.keys(CONSULT_TYPE).map((data) => {
                        return <Select.Option value={data}>{CONSULT_TYPE[data]}</Select.Option>;
                      })}
                    </Select>
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <th>상담내용</th>
                <td colSpan={3}>
                  <Form.Item name="content" noStyle>
                    <Input.TextArea rows={7} />
                  </Form.Item>
                </td>
              </tr>
            </tbody>
          </table>

          <div style={{ textAlign: 'right', marginTop: 10 }}>
            <Button htmlType="submit" type="primary" disabled={saveBtn}>
              저장
            </Button>
          </div>
        </Form>
      </Card>
    </>
  );
};

const ConsultContents = ({ consultContent, principal }) => {
  return (
    <Card title="상담 내역" style={{ marginTop: 25 }}>
      {consultContent.map((data, i) => {
        return (
          <>
            <table className={'details-table'} style={{ marginBottom: 10 }}>
              <colgroup>
                <col width={'150'} />
                <col width={'auto'} />
                <col width={'150'} />
                <col width={'auto'} />
              </colgroup>
              <tbody>
                <tr>
                  <th>상담일자</th>
                  <td>{data.consultDate}</td>
                  <th>상담원 정보</th>
                  <td>{data.consultant.name}</td>
                </tr>
                <tr>
                  <th>상담 카테고리</th>
                  <td>{`${PROFESSIONAL_FILELD[data.category]} >  ${PROFESSIONAL_FILELD_SUB[data.subCategory][0]}`}</td>
                  <th>상담 유형</th>
                  <td>{`${CONSULT_TYPE[data.consultType]}`}</td>
                </tr>
                <tr>
                  <th>상담 내용</th>
                  <td colSpan={3}>
                    {data.content.split('\n').map((v) => (
                      <p style={{ margin: 0 }}>{v}</p>
                    ))}
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        );
      })}
    </Card>
  );
};

const Consult = (props) => {
  const { queryId, type } = props;
  const [consultHistory, setConsultHistory] = useState(null);
  const [saveSuccess, setSaveSuccess] = useState(false);

  const [consultContent, setConsultContent] = useState(null);
  const [data, setData] = useState(null);
  const { principal } = useSelector((s) => s.auth, shallowEqual);

  const fetchData = useCallback(async (queryId, type) => {
    const { status, data: response } = await bsApi.get(
      //상담 정보 기본
      '/consultations/' + queryId + '/' + type,
    );
    setData(response);
    setConsultContent(response.consultContent);
  }, []);

  const fetchHistory = useCallback(async () => {
    const { status, data: response } = await bsApi.get(
      //상담 정보 기본
      '/consultations/information/' + queryId + '/logs',
    );
    setConsultHistory(response);
  }, []);

  useEffect(() => {
    if (data === null) {
      fetchData(queryId, type);
      fetchHistory(queryId, type);
    }
  }, [data]);

  const handleSubmit = async (values) => {
    setSaveSuccess(true);
    console.log('valuesdddd::', values);
    const formData = { ...values };

    formData.consultantId = principal.id;
    formData.consultationId = queryId;
    formData.type = 'BASIC_CONSULTATION';
    const { status, data: response } = await bsApi.post(
      //상담 정보 기본
      '/consultations/' + queryId + '/contents',
      formData,
    );
    console.log('formdata::', response);
    alert('저장되었습니다.');
    fetchData(queryId, type);
  };

  const initData = () => {
    setData(null);
  };
  console.log('data11::', data);
  return (
    <div>
      {data && (
        <ConsultInfo
          data={data}
          type={type}
          principal={principal}
          queryId={queryId}
          setData={() => initData()}
          fetchData={() => fetchData(queryId, type)}
        />
      )}
      {consultHistory && <ConsultHistorys type={type} consultHistory={consultHistory} />}1 {data?.status}2{' '}
      {data?.consultant?.id}3 {principal.id}
      {data?.status === 'ACCEPT' && data?.consultant?.id === principal.id && (
        <ConsultInsert handleSubmit={handleSubmit} saveSuccess={saveSuccess} />
      )}
      {consultContent && consultContent.length > 0 ? (
        <ConsultContents consultContent={consultContent} principal={principal} />
      ) : (
        <Card title="상담 내역">등록된 상담 내용 없음.</Card>
      )}
    </div>
  );
};
export default Consult;

const userCheck = (preValue, newValue) => {
  console.log('userCheck:::', preValue, newValue);
  if (preValue === newValue) {
    return true;
  }
  return false;
};
