import React from 'react';
import FindConsultant from '@components/FindConsultant';
import FindProConsultant from '@components/FindProConsultant';
import { Button } from 'antd';
import { orange } from '@ant-design/colors';
import { blue } from '@ant-design/colors';

export const TypeOfFindConsult = ({ chanageData, principal, addQuery }) => {
  const ConsultantComponent = (
    <FindConsultant onComplete={chanageData} style={{ marginRight: 10 }} userType={principal.type} addQuery={addQuery}>
      <Button
        htmlType="button"
        type={'success'}
        style={{
          backgroundColor: blue[6],
          borderColor: blue[7],
          color: 'white',
          fontWeight: 'bold',
        }}
      >
        기본 상담원 설정
      </Button>
    </FindConsultant>
  );
  return ConsultantComponent;
};

export const TypeOfFindProConsult = ({ chanageData, principal, addQuery }) => {
  const ConsultantComponent = (
    <FindProConsultant
      onComplete={chanageData}
      style={{ marginRight: 10 }}
      userType={principal.type}
      addQuery={addQuery}
    >
      <Button
        htmlType="button"
        type={'success'}
        style={{
          backgroundColor: orange[6],
          borderColor: orange[7],
          color: 'white',
          fontWeight: 'bold',
        }}
      >
        전문 상담원 설정
      </Button>
    </FindProConsultant>
  );
  return ConsultantComponent;
};


export default {
  TypeOfFindConsult, TypeOfFindProConsult
}