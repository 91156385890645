import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, Input, Modal, Row, Select, Table } from 'antd';
import DaumPostcode from 'react-daum-postcode';
import { DATETIME_FORMAT, searchItemLayout } from '@common/constants';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useWindowsDim } from '@common/hooks/useWindowsDim';
import { produce } from 'immer';
import { bsApi } from '@common/api';

const columnsOrigin = [
  {
    title: 'NO',
    dataIndex: 'rowNum',
    key: 'rowNum',
    width: 80,
    fixed: 'left',
    align: 'center',
  },
  {
    title: '기관명',
    dataIndex: 'name',
    key: 'name',
    width: 'auto',
    minWidth: 300,
  },
  {
    title: '기관코드',
    dataIndex: 'code',
    key: 'code',
    width: 150,
    align: 'center',
  },
  {
    title: '사업자등록번호',
    dataIndex: 'crn',
    key: 'crn',
    width: 150,
    align: 'center',
    render: (v) => v && `${v.substring(0, 3)}-${v.substring(3, 5)}-${v.substring(5)}`,
  },
  {
    title: '첨부',
    dataIndex: 'businessLicense',
    key: 'businessLicense',
    align: 'center',
    width: 80,
    render: (v) => (v ? '첨부' : '미첨부'),
  },
  {
    title: '등록자',
    dataIndex: 'createdBy',
    key: 'createdBy',
    width: 100,
    render: (v) => v?.name,
  },
  {
    title: '등록일',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 150,
    render: (v) => moment(v).format(DATETIME_FORMAT),
  },
  {
    title: ' ',
    key: 'operation',
    width: 80,
    align: 'center',
    fixed: 'right',
  },
];

const initialQuery = {
  page: 0,
  size: 10,
  display: undefined,
  searchType: undefined,
  keyword: '',
};

const FindInstitution = ({ children, onComplete }) => {
  const [isModal, setModal] = useState(false);

  const handleComplete = (data) => {
    onComplete?.(data);
    setModal(false);
  };

  const childrenArray = React.useMemo(() => {
    return React.Children.toArray(children);
  }, [children]);

  const { width } = useWindowsDim();
  const tableWidth = 900 - 48;

  const columns = produce(columnsOrigin, (draft) => {
    const cwidths = draft.reduce((a, c) => {
      if (c.width !== 'auto') {
        return a + c.width;
      } else {
        return a + 300;
      }
    }, 0);

    if (cwidths > tableWidth) {
      draft.forEach((v) => {
        if (v.width === 'auto') v.width = v.minWidth || 300;
      });
    }

    draft[0].render = (text, record, index) => {
      const page = data?.pagination;
      return page?.total - page?.pageSize * (page?.current - 1) - index;
    };

    draft[7].render = (record) => (
      <Button htmlType={'button'} size={'small'} onClick={() => handleComplete(record)}>
        선택
      </Button>
    );
  });

  const form = useRef();
  const [query, setQuery] = useState(initialQuery);
  const [data, setData] = useState({});

  const fetchData = useCallback(async () => {
    if (isModal) {
      const { data: response } = await bsApi.get('/institutions', { params: query });
      setData({
        rows: response?._embedded?.responseList || [],
        pagination: {
          current: response?.page?.number + 1,
          pageSize: response?.page?.size,
          total: response?.page?.totalElements || 0,
        },
      });
    }
  }, [query, isModal]);

  const handleSubmit = async (data) => {
    setQuery(
      produce((draft) => {
        draft.page = 0;
        draft.keyword = data.keyword;
        draft.searchType = data.searchType;
      }),
    );
  };

  const handleReset = async () => {
    form?.current?.setFieldsValue(initialQuery);
    await handleSubmit(initialQuery);
  };

  const handleTableChange = async (pager) => {
    setQuery(
      produce((draft) => {
        draft.page = pager?.current - 1;
      }),
    );
  };

  useEffect(() => {
    fetchData().catch(console.log);
  }, [query, isModal]);

  let fullWidth = 0;
  columns.forEach((v) => (fullWidth += v.width));

  return (
    <>
      <Modal title={'기관검색'} visible={isModal} width={900} footer={null} onCancel={() => setModal(false)}>
        {isModal && (
          <div>
            <Form ref={form} onFinish={handleSubmit}>
              <Card>
                <Form.Item {...searchItemLayout} label="직접검색">
                  <Row type={'flex'} gutter={[5, 5]}>
                    <Col>
                      <Form.Item name="searchType" noStyle>
                        <Select style={{ width: 150, marginRight: 5 }} allowClear placeholder={'전체'}>
                          <Select.Option value="name">센터명</Select.Option>
                          <Select.Option value="code">센터코드</Select.Option>
                          <Select.Option value="crn">사업자등록번호</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col flex={1}>
                      <Form.Item name="keyword" noStyle>
                        <Input placeholder="검색어를 입력하세요." />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              </Card>
              <div style={{ marginTop: 10 }}>
                <Row>
                  <Col xs={12}></Col>
                  <Col style={{ textAlign: 'right' }} xs={12}>
                    <Button htmlType="button" onClick={handleReset}>
                      초기화
                    </Button>{' '}
                    <Button htmlType="submit" type="primary">
                      검색
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
            <div style={{ marginTop: 25 }}>
              <Table
                rowKey={(record) => record.id}
                columns={columns}
                dataSource={data?.rows || []}
                pagination={data?.pagination || {}}
                scroll={{ x: fullWidth }}
                onChange={handleTableChange}
                bordered
              />
            </div>
          </div>
        )}
      </Modal>
      {childrenArray.map((v) =>
        React.cloneElement(v, {
          onClick: () => setModal(true),
        }),
      )}
    </>
  );
};

export default React.memo(FindInstitution);
