import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, DatePicker, Form, Input, InputNumber, message, Row } from 'antd';
import { bsApi } from '@common/api';
import { PUSH_STATUS } from '@common/constants';
import locale from 'antd/es/date-picker/locale/ko_KR';
import { shallowEqual, useSelector } from 'react-redux';
import styled from 'styled-components';

const RedFont = styled.span`
  color: red;
`
const FacilityDetails = ({ history, match }) => {
  const { params } = match || {};
  const { principal } = useSelector((s) => s.auth, shallowEqual);
  const handleSubmit = async (data) => {
    const formData = Object.keys(data).reduce((a, key) => {
      a[key] = data[key] === undefined ? 0 : data[key];
      return a;
    }, {});

    const args = {
      ...formData,
      centerId: principal.organization.id,
      days: data.days.format('YYYY-MM'),
    };

    if (!params.id) {
      await bsApi.post(`/facilities`, args);
      alert('저장되었습니다.');
      history.goBack();
    }
  };

  const handleBack = async () => {
    history.goBack();
  };

  const form = useRef();
  const [data, setData] = useState();

  useEffect(() => {
    if (params?.id) {
      const loadData = async () => {
        const { data } = await bsApi.get(`/notifications/${params?.id}`);
        form?.current?.setFieldsValue(data);
        setData(data);
      };

      loadData().catch((e) => {
        history.goBack();
      });
    }
  }, []);

  return (
    <div>
      <h2>시설관리</h2>
      <Form
        ref={form}
        onFinish={handleSubmit}
      >
        <div className={'pageWrapper'}>
          <Card>
            <table className={'details-table'}>
              <colgroup>
                <col width={'150'} />
                <col width={'auto'} />
                <col width={'150'} />
                <col width={'auto'} />
              </colgroup>
              <tbody>
                <tr>
                  <th>기간선택 <RedFont>*</RedFont></th>
                  <td colSpan={3}>
                    <Form.Item name={'days'}  rules={[{ required: true, message: '제목을 입력하세요.' }]}>
                      <DatePicker onChange={console.log} picker="month" locale={locale} />
                    </Form.Item>
                  </td>
                </tr>

                <tr>
                  <th>임차료 <RedFont>*</RedFont> </th>
                  <td colSpan={3}>
                    <Form.Item name={'rent'}  rules={[{ required: true, message: '금액을 입력하세요.' }]}>
                      <InputNumber
                        style={{ width: '100%' }}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/(,*)/g, '')}
                      />
                    </Form.Item>
                  </td>
                </tr>

                <tr>
                  <th>관리비</th>
                  <td colSpan={3}>
                    <Form.Item name={'maintenanceFee'} >
                      <InputNumber
                        style={{ width: '100%' }}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/(,*)/g, '')}
                      />
                    </Form.Item>
                  </td>
                </tr>

                <tr>
                  <th>전기세</th>
                  <td colSpan={3}>
                    <Form.Item name={'electricityFee'} >
                      <InputNumber
                        style={{ width: '100%' }}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/(,*)/g, '')}
                      />
                    </Form.Item>
                  </td>
                </tr>

                <tr>
                  <th>수도세</th>
                  <td colSpan={3}>
                    <Form.Item name={'waterFee'} >
                      <InputNumber
                        style={{ width: '100%' }}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/(,*)/g, '')}
                      />
                    </Form.Item>
                  </td>
                </tr>

                <tr>
                  <th>도시가스비</th>
                  <td colSpan={3}>
                    <Form.Item name={'cityGasFee'} >
                      <InputNumber
                        style={{ width: '100%' }}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/(,*)/g, '')}
                      />
                    </Form.Item>
                  </td>
                </tr>

                <tr>
                  <th>유선전화비</th>
                  <td colSpan={3}>
                    <Form.Item name={'phoneFee'} >
                      <InputNumber
                        style={{ width: '100%' }}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/(,*)/g, '')}
                      />
                    </Form.Item>
                  </td>
                </tr>

                <tr>
                  <th>인터넷비</th>
                  <td colSpan={3}>
                    <Form.Item name={'internetFee'} >
                      <InputNumber
                        style={{ width: '100%' }}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/(,*)/g, '')}
                      />
                    </Form.Item>
                  </td>
                </tr>

                <tr>
                  <th>사무용품비</th>
                  <td colSpan={3}>
                    <Form.Item name={'officeSuppliesCost'} >
                      <InputNumber
                        style={{ width: '100%' }}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/(,*)/g, '')}
                      />
                    </Form.Item>
                  </td>
                </tr>

                <tr>
                  <th>기타</th>
                  <td colSpan={3}>
                    <Form.Item name={'otherCost'} >
                      <InputNumber
                        style={{ width: '100%' }}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/(,*)/g, '')}
                      />
                    </Form.Item>
                  </td>
                </tr>
              </tbody>
            </table>
          </Card>
          <div style={{ padding: '0 15px' }}>
            <Row style={{ marginTop: 20 }}>
              <Col flex={1}></Col>
              <Col flex={1} style={{ textAlign: 'right' }}>
                <Button htmlType="button" onClick={handleBack}>
                  목록으로
                </Button>

                {!params.id && (
                  <>
                    {' '}
                    <Button htmlType="submit" type="primary">
                      저장
                    </Button>
                  </>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default FacilityDetails;
