import React, { useState, useCallback, useRef, useEffect } from 'react';
import { produce } from 'immer';
import { Button, Card, Form, Select, Input } from 'antd';
import { bsApi } from '@common/api';
import { todoType, serviceCategory } from '@common/constants';
import { useHistory } from 'react-router-dom';

const { Option } = Select;

const ToDoMessageForm = ({ currentData, params, todoResources }) => {
  const form = useRef();
  const [current, setCurrent] = useState({ ...currentData });

  const [codeCheck, setCodeCheck] = useState();
  const [duplicationMsg, setDuplicationMsg] = useState();
  const [errorType, setErrorType] = useState(false);
  const [select, setSelect] = useState('');

  const duplicationCheck = useCallback(async () => {
    try {
      const result = await bsApi.get(`/todo/duplication/todo/${current?.code}`);
      setDuplicationMsg(result.data);
      setErrorType(false);
    } catch (e) {
      if (e?.response?.status === 400) {
        setDuplicationMsg(e?.response?.data);
        setErrorType(true);
      }
    }
  }, [codeCheck]);

  const msgType = todoType.sort().map((v) => {
    ({ key: v.index, id: v.value });
  });

  const onChange = async (e) => {
    setCurrent({ ...current, [e.type]: e.value });
    console.log(e.value);
  };

  //저장
  const historyPush = useHistory();
  const saveData = async (values) => {
    if (params === 'add') {
      const res = await bsApi.post('/todo/todo-messages', values);
    } else {
      values.id = currentData.id;
      const res = await bsApi.put('/todo/todo-messages', values);
    }
    alert('저장완료');
    history.back();
  };

  //삭제
  const onDelete = async () => {
    console.log(currentData.id);
    if (!confirm('삭제하시겠습니까?')) return;
    const res = await bsApi.delete(`/todo/todo-messages/${currentData.id}`);
    alert('삭제되었습니다.');
    historyPush.push('/todo/todoMsg');
  };

  const searchKeyword = (e) => {
    setSelect(e);
  };

  console.log(current);

  return (
    <div>
      <h2>todo message 상세</h2>
      <Form ref={form} onFinish={saveData}>
        <Card>
          <table className={'details-table'} style={{ marginBottom: 10 }}>
            <colgroup>
              <col width={'20%'} />
              <col width={'80%'} />
            </colgroup>
            <tbody>
              <tr>
                <th>todo 유형</th>
                <td>
                  <Form.Item
                    name="type"
                    rules={[
                      {
                        required: true,
                        message: 'type을 필수로 지정하여 주세요.',
                      },
                    ]}
                    initialValue={current?.type}
                  >
                    <Select
                      placeholder="todo 유형 선택"
                      style={{ width: 200 }}
                      onChange={(e) => onChange({ type: 'type', value: e })}
                    >
                      {todoType
                        .sort((a, b) => {
                          return a.value < b.value ? -1 : a.value > b.value ? 1 : 0;
                        })
                        .map((v) => (
                          <Option key={`todo-type-${v.id}`} value={v.value}>
                            {v.value}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <th>todo 코드</th>
                <td>
                  <Form.Item
                    name="code"
                    rules={[
                      {
                        required: true,
                        message: 'todo code는 필수로 작성해 주세요.',
                      },
                    ]}
                    initialValue={current?.code}
                  >
                    <Input
                      style={{ width: 200 }}
                      disabled={!!currentData}
                      onChange={(e) => onChange({ type: 'code', value: e.target.value })}
                    />
                  </Form.Item>
                  {!currentData && (
                    <Button htmlType="button" onClick={duplicationCheck} disabled={!current?.code}>
                      중복체크
                    </Button>
                  )}
                  {!currentData && duplicationMsg && (
                    <span style={{ color: errorType ? 'red' : 'green' }}> {duplicationMsg}</span>
                  )}
                </td>
              </tr>
              <tr>
                <th>todo 이름</th>
                <td>
                  <Form.Item
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: 'todo 이름은 필수로 작성해 주세요.',
                      },
                    ]}
                    initialValue={current?.name}
                  >
                    <Input
                      style={{ width: '100%' }}
                      onChange={(e) => onChange({ type: 'name', value: e.target.value })}
                    />
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <th>todo 메세지</th>
                <td>
                  <Form.Item
                    name="message"
                    rules={[
                      {
                        required: true,
                        message: 'todo 메세지는 필수로 작성해 주세요.',
                      },
                    ]}
                    initialValue={current?.message}
                  >
                    <Input.TextArea
                      style={{ width: '100%' }}
                      rows={6}
                      onChange={(e) => onChange({ type: 'message', value: e.target.value })}
                    />
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <th>상품/서비스 분류</th>
                <td>
                  <Form.Item
                    name="category"
                    rules={[
                      {
                        required: true,
                        message: '상품/서비스 분류는 필수로 지정하여 주세요.',
                      },
                    ]}
                    initialValue={current?.category}
                  >
                    <Select
                      placeholder="상품/서비스 분류 선택"
                      style={{ width: 200 }}
                      onChange={(e) => onChange({ type: 'category', value: e })}
                    >
                      {serviceCategory
                        .sort((a, b) => {
                          return a.value < b.value ? -1 : a.value > b.value ? 1 : 0;
                        })
                        .map((v) => (
                          <Option key={`category-type-${v.id}`} value={v.value}>
                            {v.value}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <th>리소스</th>
                <td>
                  <Form.Item name="toDoResourceIds" rules={[]} initialValue={current?.toDoResourceIds || []}>
                    <Select
                      mode="multiple"
                      allowClear
                      style={{ width: '100%' }}
                      placeholder="Please select"
                      onSearch={searchKeyword}
                      filterOption={false}
                      onChange={(e) => onChange({ type: 'toDoResourceIds', value: e })}
                    >
                      {todoResources
                        ?.filter((v) => v.name.includes(select))
                        .map((v) => {
                          return (
                            <Option key={`toDoResources-${v.id}`} value={v.id}>
                              {v.name}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </td>
              </tr>
            </tbody>
          </table>
          <Button
            htmlType="submit"
            type={'primary'}
            disabled={params === 'add' && errorType}
            style={{ float: 'right' }}
          >
            저장
          </Button>
          <Button htmlType="button" onClick={onDelete}>
            삭제
          </Button>
        </Card>
      </Form>
    </div>
  );
};
export default ToDoMessageForm;
