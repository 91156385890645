import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Card, Col, DatePicker, Form, Input, Row, Select, Table } from 'antd';
import moment from 'moment';
import { produce } from 'immer';
import { Link } from 'react-router-dom';
import FileSaver from 'file-saver';
import { bsApi } from '@common/api';
import { DATE_FORMAT, searchItemLayout } from '@common/constants';
import { useWindowsDim } from '@common/hooks/useWindowsDim';
import { useAppState } from '@components/shared/AppProvider';
import querystring from 'querystring';

const columnsOrigin = [
  {
    title: 'NO',
    dataIndex: 'rowNum',
    key: 'rowNum',
    width: 80,
    fixed: 'left',
    align: 'center',
  },
  {
    title: '고객 ID',
    dataIndex: 'username',
    key: 'username',
    width: 150,
    fixed: 'left',
  },
  {
    title: '이름',
    dataIndex: 'name',
    key: 'name',
    width: 120,
  },
  {
    title: '전화번호',
    dataIndex: 'mobilePhone',
    key: 'mobilePhone',
    width: 'auto',
    minWidth: 160,
    render: (text) => (text || '').replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, '$1-$2-$3'),
  },
  {
    title: '이메일',
    dataIndex: 'email',
    key: 'username',
    width: 'auto',
    minWidth: 200,
  },
  {
    title: '회원상태',
    dataIndex: 'isLeaved',
    key: 'isLeaved',
    width: 100,
    align: 'center',
    render: (value) => (value ? '탈퇴처리' : '정상'),
  },
  {
    title: '가입일',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 160,
    align: 'center',
    render: (text, record, index) => moment(text).format('YYYY-MM-DD HH:mm'),
  },
  {
    title: '최근접속일',
    dataIndex: 'lastAccessTime',
    key: 'lastAccessTime',
    width: 160,
    align: 'center',
    render: (text, record, index) => (text !== null ? moment(text).format('YYYY-MM-DD HH:mm') : '최근 접속정보 없음'),
  },
  {
    title: '관리',
    key: 'operation',
    width: 80,
    align: 'center',
    fixed: 'right',
  },
];

//서버에 보내는 쿼리
const initialQuery = {
  page: 0,
  size: 20,
  searchType: undefined,
  type: 'MEMBER',
  periodType: 'createdAt',
  keyword: undefined,
  fromDate: undefined,
  toDate: undefined,
  isLeaved: false,
};

const MemberIndex = (props) => {
  const [state] = useAppState();
  const { width } = useWindowsDim();
  const tableWidth = width - 40;

  const columns = produce(columnsOrigin, (draft) => {
    const cwidths = draft.reduce((a, c) => {
      if (c.width !== 'auto') {
        return a + c.width;
      } else {
        return a + (c.minWidth || 300);
      }
    }, 0);

    draft.forEach((v) => {
      v.ellipsis = {
        showTitle: false,
      };
    });

    if (cwidths > tableWidth) {
      draft.forEach((v) => {
        if (v.width === 'auto') v.width = v.minWidth || 300;
        if (state.mobile) delete v.fixed;
      });
    }

    draft[0].render = (text, record, index) => {
      const page = data?.pagination;
      return page?.total - page?.pageSize * (page?.current - 1) - index;
    };
    draft[8].render = (record) => (
      <Link to={`/members/${record.id}`}>
        <Button htmlType={'button'} size={'small'}>
          상세
        </Button>
      </Link>
    );
  });

  const form = useRef();

  //내가 state 사용하던거랑 비슷함.
  const [query, setQuery] = useState(initialQuery);
  const [data, setData] = useState({});

  const getMyExcel = async () => {
    const { data: response } = await bsApi.get('/members/excel-download/live', {
      params: query,
      headers: { Accept: 'application/hal+json' },
      responseType: 'blob',
    });
    FileSaver.saveAs(response, 'Member.xls');
  };

  //rest 검색 로직, useCallback은 함수를 새로 생성x, 재사용하기위함으로 사용
  const fetchData = useCallback(async () => {
    const { status, data: response } = await bsApi.get('/members', { params: query });
    setData({
      //_embedded > responseList에 []식으로 실데이터가 담겨진다(페이징처리된 데이터들)
      rows: response?._embedded?.responseList || [],

      //page 페이징 정보담겨있음.
      pagination: {
        current: response?.page?.number + 1,
        pageSize: response?.page?.size,
        total: response?.page?.totalElements || 0,
      },
    });
    //함수 내부에서 쓰는 state룰 deps 배열로 넣는다.
  }, [query]);

  //검색버튼 클릭시
  const handleSubmit = async (data) => {
    //useState 이용하여 담기.
    setQuery(
      //produce??(immu) 담든건 알지만 정확한 이해 아직 ㄴㄴ
      produce((draft) => {
        draft.page = 0;
        draft.keyword = data.keyword;
        draft.periodType = data.periodType;
        draft.searchType = data.searchType;
        draft.isLeaved = false;
        draft.fromDate = data.dateRange?.[0]?.format('YYYY-MM-DD');
        draft.toDate = data.dateRange?.[1]?.format('YYYY-MM-DD');
      }),
    );
  };

  const handleReset = async () => {
    form?.current?.setFieldsValue(initialQuery);
    await handleSubmit(initialQuery);
  };

  //페이지넘, 페이지 사이즈 바꿀 때
  const handleTableChange = async (pager) => {
    console.log(pager);
    setQuery(
      produce((draft) => {
        draft.page = pager?.current - 1;
        draft.size = pager?.pageSize;
      }),
    );
  };

  const handleDateRangeSet = (date) => {
    const date1 = moment().add(-date, 'days');
    const date2 = moment();
    form?.current?.setFieldsValue({
      dateRange: [date1, date2],
    });
    form?.current?.submit();
  };

  useEffect(() => {
    const { history, location } = props;
    history.replace(location.pathname + '?' + querystring.encode(query));

    fetchData().catch(console.log);
  }, [query]);

  let fullWidth = 0;
  columns.forEach((v) => (fullWidth += v.width));

  return (
    <div>
      <h2>회원 관리</h2>
      <Form ref={form} onFinish={handleSubmit}>
        <Card>
          <Form.Item {...searchItemLayout} label="기간검색">
            <Row type={'flex'} gutter={[5, 5]}>
              <Col>
                <Form.Item name="periodType" noStyle initialValue={initialQuery.periodType}>
                  <Select style={{ width: 150, marginRight: 10 }}>
                    <Select.Option value="createdAt">가입일</Select.Option>
                    <Select.Option value="lastAccessTime">최근접속일</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col flex={1}>
                <Form.Item name="dateRange" noStyle initialValue={null}>
                  <DatePicker.RangePicker
                    style={{ marginRight: 5 }}
                    disabled={[false, false]}
                    allowClear
                    allowEmpty={[true, true]}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Button.Group>
                  <Button onClick={() => handleDateRangeSet(0)}>오늘</Button>
                  <Button onClick={() => handleDateRangeSet(7)}>7일</Button>
                  <Button onClick={() => handleDateRangeSet(30)}>30일</Button>
                  <Button onClick={() => handleDateRangeSet(365)}>1년</Button>
                </Button.Group>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item {...searchItemLayout} label="상세검색">
            <Row type={'flex'} gutter={[5, 5]}>
              <Col>
                <Form.Item name="searchType" noStyle>
                  <Select style={{ width: 150, marginRight: 5 }} placeholder={'전체'} allowClear>
                    <Select.Option value="username">회원 아이디</Select.Option>
                    <Select.Option value="name">이름</Select.Option>
                    <Select.Option value="mobilePhone">전화번호</Select.Option>
                    <Select.Option value="email">이메일</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col flex={1} style={{ minWidth: 280 }}>
                <Form.Item name="keyword" noStyle>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
        </Card>
        <div style={{ marginTop: 10 }}>
          <Row>
            <Col xs={12}>
              <Button htmlType="button" type={'success'} onClick={getMyExcel}>
                엑셀 다운로드
              </Button>
            </Col>
            <Col style={{ textAlign: 'right' }} xs={12}>
              <Button htmlType="button" onClick={handleReset}>
                초기화
              </Button>{' '}
              <Button htmlType="submit" type="primary">
                검색
              </Button>
            </Col>
          </Row>
        </div>
      </Form>

      <div style={{ marginTop: 25 }}>
        <Table
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={data?.rows || []}
          pagination={data?.pagination || {}}
          scroll={{ x: fullWidth }}
          onChange={handleTableChange}
          bordered
        />
      </div>
    </div>
  );
};

export default MemberIndex;
