import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Card, Col, DatePicker, Form, Input, Row, Select, Table, Radio } from 'antd';
import moment from 'moment';
import { produce } from 'immer';
import { Link } from 'react-router-dom';
import FileSaver from 'file-saver';

import { bsApi } from '@common/api';
import { DATE_FORMAT, searchItemLayout, LEAVE_TYPE, LEAVE_REASON_TYPE } from '@common/constants';
import { useWindowsDim } from '@common/hooks/useWindowsDim';
import { useAppState } from '@components/shared/AppProvider';
import useListForm from '@common/hooks/useListForm';

const columnsOrigin = [
  {
    title: 'NO',
    dataIndex: 'rowNum',
    key: 'rowNum',
    width: 80,
    fixed: 'left',
    align: 'center',
  },
  {
    title: '고객 ID',
    dataIndex: 'username',
    key: 'username',
    width: 180,
    fixed: 'left',
  },
  {
    title: '상세내용',
    dataIndex: 'leaveReason',
    key: 'leaveReason',
    width: 'auto',
    minWidth: 180,
    render: (text) => {
      return text === null || text === '' ? '사유 없음' : text;
    },
  },
  {
    title: '처리유형',
    dataIndex: 'leaveType',
    key: 'treatmentType',
    width: 'leaveType',
    minWidth: 120,
    render: (text) => LEAVE_TYPE[text],
  },
  {
    title: '가입일',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 180,
    align: 'center',
    render: (text, record, index) => moment(text).format('YYYY-MM-DD HH:mm'),
  },
  {
    title: '탈퇴일',
    dataIndex: 'leaveDate',
    key: 'leaveDate',
    width: 180,
    align: 'center',
    render: (text, record, index) => {
      console.log('record::', record);
      return moment(text).format('YYYY-MM-DD HH:mm');
    },
  },
];
const initialQuery = {
  page: 0,
  size: 20,
  leaveType: '', //처리유형
  searchType: undefined,
  periodType: 'createdAt',
  keyword: '',
  fromDate: undefined,
  toDate: undefined,
  isLeaved: true,
};

const MemberLeaveIndex = (props) => {
  const fetchData = useCallback(async (query) => {
    const { status, data: response } = await bsApi.get('/members', { params: query });
    return {
      rows: response?._embedded?.responseList || [],
      pagination: {
        current: response?.page?.number + 1,
        pageSize: response?.page?.size,
        total: response?.page?.totalElements || 0,
      },
    };
  }, []);

  const { params, form, tableProps, onFormDateRangeChange, onFormFinish, onFormReset } = useListForm({
    ...props,
    fetchData,
    initialQuery,
    columnsOrigin,
    applyColumns: (draft, data) => {
      draft[0].render = (text, record, index) => {
        const page = data?.pagination;
        return page?.total - page?.pageSize * (page?.current - 1) - index;
      };
    },
  });

  const getMyExcel = async () => {
    const { data: response } = await bsApi.get('/members/excel-download/leave', {
      params,
      headers: { Accept: 'application/hal+json' },
      responseType: 'blob',
    });
    FileSaver.saveAs(response, 'LeaveMember.xls');
  };

  return (
    <div>
      <h2>탈퇴 회원 관리</h2>
      <Form ref={form} onFinish={onFormFinish}>
        <Card>
          <Form.Item {...searchItemLayout} label="기간검색">
            <Row type={'flex'} gutter={[5, 5]}>
              <Col>
                <Form.Item name="periodType" noStyle>
                  <Select style={{ width: 150, marginRight: 5 }}>
                    <Select.Option value="createdAt">가입일</Select.Option>
                    <Select.Option value="leaveDate">탈퇴일</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col flex={1}>
                <Form.Item name="dateRange" noStyle initialValue={null}>
                  <DatePicker.RangePicker
                    style={{ marginRight: 5 }}
                    disabled={[false, false]}
                    allowClear
                    allowEmpty={[true, true]}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Button.Group>
                  <Button onClick={() => onFormDateRangeChange(0)}>오늘</Button>
                  <Button onClick={() => onFormDateRangeChange(7)}>7일</Button>
                  <Button onClick={() => onFormDateRangeChange(30)}>30일</Button>
                  <Button onClick={() => onFormDateRangeChange(365)}>1년</Button>
                </Button.Group>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item {...searchItemLayout} label="선택검색">
            <Row>
              <Col flex={1}>
                <Form.Item name="leaveType" noStyle>
                  <Radio.Group>
                    <Radio value={''}>전체</Radio>
                    <Radio value={'LEAVE'}>회원탈퇴</Radio>
                    <Radio value={'THROW_OUT'}>관리자탈퇴</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>

          <Form.Item {...searchItemLayout} label="상세검색">
            <Row type={'flex'} gutter={[5, 5]}>
              <Col>
                <Form.Item name="searchType" noStyle>
                  <Select style={{ width: 150, marginRight: 5 }} placeholder={'전체'} allowClear>
                    <Select.Option value="username">회원 아이디</Select.Option>
                    <Select.Option value="leaveReason">상세내용</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col flex={1} style={{ minWidth: 280 }}>
                <Form.Item name="keyword" noStyle>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
        </Card>
        <div style={{ marginTop: 10 }}>
          <Row>
            <Col xs={12}>
              <Button htmlType="button" type={'success'} onClick={getMyExcel}>
                엑셀 다운로드
              </Button>
            </Col>
            <Col style={{ textAlign: 'right' }} xs={12}>
              <Button htmlType="button" onClick={onFormReset}>
                초기화
              </Button>{' '}
              <Button htmlType="submit" type="primary">
                검색
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
      <div style={{ marginTop: 25 }}>
        <Table rowKey={(record) => record.id} {...tableProps} />
      </div>
    </div>
  );
};

export default MemberLeaveIndex;
