import React, { useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Divider, Drawer, Menu } from 'antd';
import Icon from '@ant-design/icons';
import Inner from './styles/Sidebar';
import Routes from '../lib/menus';
import { useAppState } from './shared/AppProvider';
import { shallowEqual, useSelector } from 'react-redux';
import { intersection } from 'lodash';

const { SubMenu } = Menu;

let rootSubMenuKeys = [];

const getKey = (name, index) => {
  const string = `${name}-${index}`;

  let key = string.replace(' ', '-');
  return key.charAt(0).toLowerCase() + key.slice(1);
};

const SidebarContent = ({ sidebarTheme, sidebarIcons, collapsed }) => {
  const [state, dispatch] = useAppState();
  const [openKeys, setOpenKeys] = useState([]);
  const [appRoutes] = useState(Routes);

  const location = useLocation();
  const { pathname } = location;

  const principal = useSelector((s) => s.auth.principal, shallowEqual);

  const onOpenChange = (openKeys) => {
    const latestOpenKey = openKeys.slice(-1);
    if (rootSubMenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys([...latestOpenKey]);
    } else {
      setOpenKeys(latestOpenKey ? [...latestOpenKey] : []);
    }
  };

  /** 탑메뉴 수정로직(menus.js 수정)*/
  const TopMenuCode = (menuItem, isSub, suffix) => {
    let menuResult = menuItem.map((value, index) => {
      if (!isSub && intersection(value.roles, principal.authorities).length === 0) {
        return null;
      }
      const children =
        value?.child?.filter((v) => !v.hide && intersection(v.roles, principal.authorities).length > 0) || [];
      if (children?.length === 1) {
        const val2 = children[0];
        if (intersection(val2.roles, principal.authorities).length > 0) {
          return (
            <Menu.Item
              key={`${suffix}-${index}`}
              className={value && pathname.startsWith(value.path) ? 'ant-menu-item-selected' : ''}
            >
              {!isSub && value.icon && <Icon component={value.icon} />}
              <Link to={val2.path}>{value.name}</Link>
            </Menu.Item>
          );
        }
      }
      if (!children?.length) {
        if (intersection(value.roles, principal.authorities).length > 0) {
          return (
            <Menu.Item
              key={`${suffix}-${index}`}
              className={value && pathname.startsWith(value.path) ? 'ant-menu-item-selected' : ''}
            >
              {!isSub && value.icon && <Icon component={value.icon} />}
              <Link to={value.path}>{value.name}</Link>
            </Menu.Item>
          );
        }
        // } else if (children?.length === 1) {
        //   const value2 = children[0];
        //   if (intersection(value2.roles, principal.authorities).length > 0) {
        //   //   return (
        //   //     <Menu.Item
        //   //       key={`${suffix}-${index}`}
        //   //       className={value2 && pathname.startsWith(value2.path) ? 'ant-menu-item-selected' : ''}
        //   //     >
        //   //       {!isSub && value.icon && <Icon component={value.icon} />}
        //   //       <Link to={value2.path}>{value.name}</Link>
        //   //     </Menu.Item>
        //   //   );
        //   }
      } else {
        const textTag = (
          <span>
            {value.icon && <Icon component={value.icon} />}
            {value.name}
          </span>
        );
        return (
          <SubMenu
            key={`${suffix}-${index}`}
            title={textTag}
            className={value && pathname.startsWith(value.path) ? 'ant-menu-item-selected' : ''}
          >
            {TopMenuCode(children, true, index)}
          </SubMenu>
        );
      }
    });
    return menuResult;
  };

  const menu = (
    <>
      <Menu
        theme={sidebarTheme}
        className="border-0"
        style={{ flex: 1, height: '100%' }}
        mode={state.mobile ? 'vertical' : 'horizontal'}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
      >
        {TopMenuCode(Routes)}
      </Menu>

      <Divider
        className={`m-0`}
        style={{
          display: `${sidebarTheme === 'dark' ? 'none' : ''}`,
        }}
      />
    </>
  );

  return (
    <div
      style={{
        background: '#fff',
        position: 'sticky',
        top: 0,
        zIndex: 999,
        boxShadow: '0 0 7px rgba(0,0,0,0.2)',
      }}
    >
      <Inner>
        <div
          style={{
            flexDirection: 'row',
            maxWidth: 1440,
            margin: '0px auto',
            borderLeft: '1px solid #eee',
            borderRight: '1px solid #eee',
          }}
        >
          {!state.mobile && (
            <div className={`bg-${sidebarTheme}`} theme={sidebarTheme}>
              {menu}
            </div>
          )}
        </div>

        <Drawer
          closable={false}
          width={240}
          placement="left"
          onClose={() => dispatch({ type: 'mobileDrawer' })}
          visible={state.mobileDrawer}
          className="chat-drawer"
        >
          <Inner>
            <div
              css={`
                overflow: hidden;
                flex: 1 1 auto;
                flex-direction: column;
                display: flex;
                height: 100vh;
                padding-top: 30px;
              `}
            >
              {menu}
            </div>
          </Inner>
        </Drawer>
      </Inner>
    </div>
  );
};

export default SidebarContent;
