import React, { useCallback } from 'react';
import { Button, Card, Col, Form, Input, Row, Select, Table } from 'antd';
import { bsApi } from '@common/api';
import { Link } from 'react-router-dom';
import { searchItemLayout } from '@common/constants';
import useListForm from '@common/hooks/useListForm';

const columnsOrigin = [
  {
    title: 'NO',
    dataIndex: 'rowNum',
    key: 'rowNum',
    width: 60,
    align: 'center',
  },
  {
    title: 'todo 유형',
    dataIndex: 'type',
    key: 'type',
    width: 120,
  },
  {
    title: '상품/서비스 분류',
    dataIndex: 'category',
    key: 'category',
    width: 120,
  },
  {
    title: 'todo 이름',
    dataIndex: 'name',
    key: 'name',
    width: 250,
    align: 'left',
    ellipsis: true,
  },
  {
    title: 'todo code',
    dataIndex: 'code',
    key: 'code',
    width: 120,
    align: 'center',
  },
  {
    title: 'todo 메세지',
    dataIndex: 'message',
    key: 'message',
    width: 'auto',
    align: 'left',
    ellipsis: true,
  },
  {
    title: '관리',
    key: 'operation',
    width: 70,
    align: 'center',
    fixed: 'right',
  },
];

const initialQuery = {
  page: 0,
  limit: 10,
  searchType: '',
  periodType: 'createdAt',
  keyword: '',
};

const TodoMessageIndex = (props) => {
  const fetchData = useCallback(async (query) => {
    const { status, data: response } = await bsApi.get('/todo/todo-messages', { params: query });
    return {
      rows: response?.content || [],
      pagination: {
        current: response?.number + 1,
        pageSize: response?.size,
        total: response?.totalElements || 0,
      },
    };
  }, []);

  const { form, tableProps, onFormFinish, onFormReset } = useListForm({
    ...props,
    fetchData,
    initialQuery,
    columnsOrigin,
    applyColumns: (draft, data) => {
      draft[0].render = (text, record, index) => {
        const page = data?.pagination;
        return page?.total - page?.pageSize * (page?.current - 1) - index;
      };
      draft[columnsOrigin.length - 1].render = (record) => (
        <Link to={`/todo/message/${record.id}?page=${data?.pagination?.current}`}>
          <Button htmlType={'button'} size={'small'}>
            상세
          </Button>
        </Link>
      );
    },
  });

  return (
    <div>
      <h2>todo 관리</h2>
      <Form ref={form} onFinish={onFormFinish}>
        <Card>
          <Form.Item {...searchItemLayout} label="직접검색">
            <Row type={'flex'} gutter={[5, 5]}>
              <Col>
                <Form.Item name="searchType" noStyle>
                  <Select style={{ width: 150, marginRight: 5 }} placeholder={'전체'} allowClear>
                    <Select.Option value="name">todo이름</Select.Option>
                    <Select.Option value="code">코드</Select.Option>
                    <Select.Option value="message">메세지</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col flex={1}>
                <Form.Item name="keyword" noStyle>
                  <Input placeholder="검색어를 입력하세요." />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
        </Card>
        <div style={{ marginTop: 10 }}>
          <Row>
            <Col style={{ textAlign: 'right' }} xs={24}>
              <Button htmlType="button" onClick={onFormReset}>
                초기화
              </Button>{' '}
              <Button htmlType="submit" type="primary">
                검색
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
      <br />
      <br />
      <Table
        {...tableProps}
        rowKey={(record) => record.id}
        footer={() => (
          <Row>
            <Col>
              <Link to={`/todo/message/add`}>
                <Button style={{ marginLeft: 5 }} type={'primary'}>
                  등록
                </Button>
              </Link>
            </Col>
          </Row>
        )}
      />
    </div>
  );
};
export default TodoMessageIndex;
