import React, { useEffect, useState, useCallback, useRef } from 'react';
import { bsApi } from '@common/api';
import FindProConsultant from '@components/FindProConsultant';
import { shallowEqual, useSelector } from 'react-redux';

import { Card, Descriptions, Select, Button, Form, Input, message } from 'antd';

import {
  PROFESSIONAL_FILELD,
  PROFESSIONAL_FILELD_SUB,
  WANT_TIME,
  CONSULTATION_STATUS,
  CONSULT_TYPE,
} from '@common/constants';

const { Option } = Select;
const consultRM = ['PRO_APPLY', 'PRO_COMPANION'];
const proConsult = ['PRO_RECEIPT', 'PRO_ACCEPT', 'PRO_COMPLETE'];

const TypeOfFindConsult = ({ currentStatus, chanageData, disabled, account, principal, addQuery }) => {
  const ProConsultantComponent = (
    <FindProConsultant
      onComplete={chanageData}
      style={{ marginRight: 10 }}
      userType={principal.type}
      addQuery={addQuery}
    >
      <Button disabled={disabled}>상담원 찾기</Button>
    </FindProConsultant>
  );
  if (currentStatus === 'PRO_COMPANION' || 'PRO_APPLY' === currentStatus || 'PRO_RECEIPT' === currentStatus) {
    if (principal.type === 'ADMIN' || principal.type === 'RELATIONSHIP_MANAGER') {
      return ProConsultantComponent;
    }
  }
  return <Button disabled>전문 상담원 찾기</Button>;
};

const ConsultInfo = ({
  data,
  roleStatus,
  principal,
  queryId,
  consultHistory = [],
  initData,
  fetchData,
  disabledValue,
}) => {
  const [account, setAccount] = useState(null);
  const [consultationStatus, setConsultationStatus] = useState(data.status);
  const changeStatus = (value) => {
    setConsultationStatus(value);
  };

  const chanageData = (value) => {
    setAccount(value);
  };
  const changeConsultation = async () => {
    const returnValues = {};
    returnValues.proConsultantId = account?.id || data?.proConsultant?.id;
    returnValues.consultantId = data?.consultant?.id;
    returnValues.institutionId = data?.organization?.id;
    returnValues.type = 'PRO_CONSULTATION';
    returnValues.status = consultationStatus;

    if (!returnValues.proConsultantId) {
      alert('상담원 지정이 필요합니다.');
      return;
    }

    const { status, data: response } = await bsApi.put(
      //상담 정보 기본
      '/consultations/' + queryId,
      returnValues,
    );
    alert('수정 완료.');
    initData();
    fetchData();
  };
  console.log('data::', data);
  const isPro =
    principal.authorities.indexOf('ADMIN') >= 0 ||
    principal.authorities.indexOf('RELATIONSHIP_MANAGER') >= 0 ||
    principal.authorities.indexOf('PRO_CONSULTANT') >= 0;
  return (
    <>
      <div className={'pageWrapper'}>
        <Card>
          <h5>상담 진행 정보</h5>
          <table className={'details-table'}>
            <colgroup>
              <col width={'150'} />
              <col width={'auto'} />
              <col width={'150'} />
              <col width={'auto'} />
            </colgroup>
            <tbody>
              <tr>
                <th>상담 진행 상태</th>
                <td>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    {CONSULTATION_STATUS[consultationStatus]}
                    {isPro && (
                      <Select
                        defaultValue={consultationStatus}
                        onChange={changeStatus}
                        style={{ marginLeft: 10 }}
                        disabled={disabledValue}
                      >
                        {roleStatus && roleStatus.length > 0 ? (
                          roleStatus.map((data) => {
                            return <Option value={data}>{CONSULTATION_STATUS[data]}</Option>;
                          })
                        ) : (
                          <Option value={consultationStatus}>{CONSULTATION_STATUS[consultationStatus]}</Option>
                        )}
                      </Select>
                    )}
                  </div>
                </td>
                <th>상담원 검색</th>
                <td>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    {account
                      ? `${account.name}(${account?.username})`
                      : data?.proConsultant
                      ? `${data?.proConsultant?.name}(${data?.proConsultant?.username})`
                      : '지정해주세요. ' + data?.institution?.name}
                    {isPro && (
                      <TypeOfFindConsult
                        currentStatus={consultationStatus}
                        chanageData={chanageData}
                        addQuery={{ institution: data.institution }}
                        account={data?.proConsultant}
                        principal={principal}
                      />
                    )}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          {isPro && (
            <Button onClick={changeConsultation} style={{ float: 'right', marginTop: 10 }} disabled={disabledValue}>
              상담 정보 수정
            </Button>
          )}

          <h5 style={{ marginTop: 40 }}>전문 상담 이력</h5>
          <table className={'details-table'}>
            <colgroup>
              <col width={'150'} />
              <col width={'auto'} />
              <col width={'150'} />
              <col width={'auto'} />
            </colgroup>
            <tbody>
              {consultHistory?.map((data, i) => {
                return (
                  <tr key={`history-list_${data.id}`}>
                    <th>{CONSULTATION_STATUS[data.status]}</th>
                    <td>{data.createdBy === null ? '유저 상담 신청' : data.createdBy.name}</td>
                    <th>일자</th>
                    <td>{data.logDate}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Card>
      </div>
    </>
  );
};

const ConsultInsert = ({ handleSubmit }) => {
  const form = useRef();
  const [category2, setCategory2] = useState([]);

  const changeCategory1 = (value) => {
    const values = [];
    Object.keys(PROFESSIONAL_FILELD_SUB).map((data) => {
      if (PROFESSIONAL_FILELD_SUB[data][1] === value) {
        if (PROFESSIONAL_FILELD_SUB[data][0] !== undefined) {
          return values.push(data);
        }
      }
    });
    setCategory2(values);
  };

  return (
    <>
      <Card>
        <Form ref={form} onFinish={handleSubmit}>
          <Descriptions bordered>
            <Descriptions.Item label="상담카테고리" span={2}>
              <Form.Item name="category" noStyle>
                <Select
                  style={{ width: 150, marginRight: 5 }}
                  allowClear
                  placeholder={'상담카테고리1'}
                  onChange={changeCategory1}
                >
                  {Object.keys(PROFESSIONAL_FILELD).map((data) => {
                    return <Select.Option value={data}> {PROFESSIONAL_FILELD[data]} </Select.Option>;
                  })}
                </Select>
              </Form.Item>
              <Form.Item name="subCategory" noStyle>
                <Select style={{ width: 150, marginRight: 5 }} allowClear placeholder={'상담카테고리2'}>
                  {category2 &&
                    category2.map((data) => {
                      return <Select.Option value={data}> {PROFESSIONAL_FILELD_SUB[data][0]} </Select.Option>;
                    })}
                </Select>
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label="상담유형" span={2}>
              <Form.Item name="consultType" noStyle>
                <Select style={{ width: 150, marginRight: 5 }} allowClear placeholder={'상담유형'}>
                  {Object.keys(CONSULT_TYPE).map((data) => {
                    return <Select.Option value={data}>{CONSULT_TYPE[data]}</Select.Option>;
                  })}
                </Select>
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label="상담내용">
              <Form.Item name="content" noStyle>
                <Input.TextArea />
              </Form.Item>
            </Descriptions.Item>
          </Descriptions>
          <Button htmlType="submit" type="primary" style={{ flaot: 'right' }}>
            저장
          </Button>
        </Form>
      </Card>
    </>
  );
};

const ConsultContents = ({ consultContent, principal }) => {
  console.log(' consultContent::', consultContent);
  return (
    <Card title="상담 내역" style={{ marginTop: 25 }}>
      {consultContent.map((data, i) => {
        return (
          <>
            <Descriptions bordered>
              <Descriptions.Item label="상담일자" span={2}>
                {data.consultDate}
              </Descriptions.Item>
              <Descriptions.Item label="상담원 정보" span={2}>
                {data.consultant.name}
              </Descriptions.Item>
              <Descriptions.Item label="상담 카테고리" span={2}>
                {`${PROFESSIONAL_FILELD[data.category]} >  ${PROFESSIONAL_FILELD_SUB[data.subCategory][0]}`}
              </Descriptions.Item>
              <Descriptions.Item label="상담 유형" span={2}>
                {`${CONSULT_TYPE[data.consultType]}`}
              </Descriptions.Item>
              <Descriptions.Item label="상담 내용">{data.content}</Descriptions.Item>
            </Descriptions>
            <br />
            <br />
          </>
        );
      })}
    </Card>
  );
};

const ProConsult = (props) => {
  const { queryId, type } = props;
  const [consultHistory, setConsultHistory] = useState(null);

  const [consultContent, setConsultContent] = useState(null);
  const [roleStatus, setRoleStatus] = useState(null);
  const [data, setData] = useState(null);
  const { principal } = useSelector((s) => s.auth, shallowEqual);

  const initData = () => {
    setData(null);
  };

  const fetchData = useCallback(async (queryId, type) => {
    console.log('type::', type);

    const { status, data: response } = await bsApi.get(
      //상담 정보 기본
      '/consultations/' + queryId + '/' + type,
    );
    setData(response);
    setConsultContent(response.consultContent);
  }, []);

  const fetchHistory = useCallback(async (queryId, type) => {
    const { status, data: response } = await bsApi.get(
      //상담 정보 기본
      '/consultations/information/' + queryId + '/logs',
    );
    setConsultHistory(response);
  }, []);

  const fetchStatus = useCallback(async (test) => {
    // 상담사 조건 별 상태
    const { status, data: response } = await bsApi.get(
      //상담 정보 기본
      '/account-select/status-list/' + test,
    );
    setRoleStatus(response);
  }, []);

  useEffect(() => {
    if (data === null) {
      fetchData(queryId, type);
      fetchHistory(queryId, type);
    }
  }, [data === null]);

  useEffect(() => {
    if (data !== null) {
      fetchStatus(data.status);
    }
  }, [data?.status]);

  const handleSubmit = async (values) => {
    values.consultantId = principal.id;
    values.consultationId = queryId;
    values.type = 'PRO_CONSULTATION';
    const { status, data: response } = await bsApi.post(
      //상담 정보 기본
      '/consultations/' + queryId + '/contents',
      values,
    );

    fetchData(queryId, type);
  };

  console.log('proConsultant:::', data);
  if (data && ['PRO_APPLY', 'PRO_RECEIPT', 'PRO_COMPANION', 'PRO_ACCEPT', 'PRO_COMPLETE'].includes(data?.status)) {
    return (
      <div>
        {data && (
          <ConsultInfo
            data={data}
            roleStatus={roleStatus}
            principal={principal}
            consultHistory={consultHistory}
            queryId={queryId}
            initData={() => initData()}
            fetchData={() => fetchData(queryId, type)}
            disabledValue={false}
          />
        )}
        <br />
        <br />
        {data?.status === 'PRO_ACCEPT' && userCheck(data?.proConsultant?.id, principal.id) && (
          <ConsultInsert handleSubmit={handleSubmit} />
        )}

        {consultContent && consultContent.length > 0 ? (
          <ConsultContents consultContent={consultContent} principal={principal} />
        ) : (
          <Card title="상담 내역">등록된 상담 내용 없음.</Card>
        )}
      </div>
    );
  } else {
    return (
      // <div>아직 전문 상담으로 이관 된 이력이 없는 데이터 입니다. 전문상담으로 이관 후 다시 열어주세요.</div>

      <div>
        {data && (
          <ConsultInfo
            data={data}
            roleStatus={roleStatus}
            principal={principal}
            consultHistory={consultHistory}
            queryId={queryId}
            initData={() => initData()}
            fetchData={() => fetchData(queryId, type)}
            disabledValue={true}
          />
        )}
        <br />
        <br />
        {userCheck(data?.proConsultant?.id, principal.id) && <ConsultInsert handleSubmit={handleSubmit} />}
        {consultContent && consultContent.length > 0 ? (
          <ConsultContents consultContent={consultContent} principal={principal} />
        ) : (
          <Card title="상담 내역">등록된 상담 내용 없음.</Card>
        )}
      </div>
    );
  }
};
export default ProConsult;

const userCheck = (preValue, newValue) => {
  console.log('preValue, newValue:::', preValue, newValue);
  if (preValue === newValue) {
    return true;
  }
  return false;
};
