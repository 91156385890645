import React, { useCallback, useEffect, useState } from 'react';
import { useWindowsDim } from '@common/hooks/useWindowsDim';
import { produce } from 'immer';
import { Button, Card, Col, Form, Input, Row, Select, Table } from 'antd';
import { bsApi } from '@common/api';
import { Link } from 'react-router-dom';
import { WANT_TIME, ROLE } from '@common/constants';
import moment from 'moment';

const columnsOrigin = [
  {
    title: '변경 일자',
    dataIndex: 'update',
    key: 'logDateTime',
    align: 'center',
    width: 210,
      render: (v) => moment(v).format('YYYY-MM-DD HH:mm'),
  },
  {
    title: '상담원 유형',
    dataIndex: 'createdBy',
    key: 'createdBy',
    align: 'center',
    width: 210,
      render: (value) => { 
        return (<span>{ROLE[value.type]} </span>)
    }
  },
  {
    title: '상담원 ID',
    dataIndex: 'consultation',
    key: 'consultation',
    width: 210,
    align: 'center',
    render: (v) => {
      const status =v.status.includes("PRO");
      if(status) {
        if(v.proConsultant) {
          return v.proConsultant.username;
        } else {
          return v.institution.username;
        }
      } else {
        if(v.consultant) {
          return v.consultant.username;
        } else {
          return v.center.username;
        }
      }
    }
  },
  {
    title: '상담 내역',
    dataIndex: 'logComment',
    key: 'name',
    width: 210,
    //    render: (v) => v != null ? v.name : ""
  },
];

const ConsulHistory = (props) => {
  const { width } = useWindowsDim();
  const tableWidth = width - 40;

  const columns = produce(columnsOrigin, (draft) => {
    const cwidths = draft.reduce((a, c) => {
      if (c.width !== 'auto') {
        return a + c.width;
      } else {
        return a + 300;
      }
    }, 0);

    if (cwidths > tableWidth) {
      draft.forEach((v) => {
        if (v.width === 'auto') v.width = 300;
      });
    }
  });

  const [data, setData] = useState([]);
  const [query, setQuery] = useState('');

  const fetchData = useCallback(async () => {
    const { status, data: response } = await bsApi.get(`/consultations/information/${props.queryId}/history`);
    setData(response);
  }, [query]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  let fullWidth = 0;
  columns.forEach((v) => (fullWidth += v.width));

  return (
    <div>
      <Card title="상담 이력">
        <Table
          pagination={false}
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={data}
          scroll={{ x: fullWidth }}
          bordered
        />
      </Card>
    </div>
  );
};

export default ConsulHistory;
