import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, Input, message, Row, AutoComplete } from 'antd';
import { bsApi } from '@common/api';
import { PUSH_STATUS } from '@common/constants';
import styled from 'styled-components';

const RedFont = styled.span`
  color: red;
`;
const PushDetails = ({ history, match }) => {
  const { params } = match || {};
  const handleSubmit = async (data) => {
    const args = {
      ...data,
      type: 'MANUAL',
    };
    if (!params.id) {
      await bsApi.post(`/notifications`, args);
      alert('저장되었습니다.');
      history.goBack();
    }
  };

  const handleBack = async () => {
    history.goBack();
  };

  const form = useRef();
  const [data, setData] = useState();
  const [pushCount, setPushCount] = useState(0);
  const [autoCompleteResult, setAutoCompleteResult] = useState([]);

  const onWebsiteChange = (value) => {
    if (!value) {
      setAutoCompleteResult([]);
    } else {
      setAutoCompleteResult(['.com', '.org', '.net', '.co.kr', 'kr'].map((domain) => `${value}${domain}`));
    }
  };

  const websiteOptions = autoCompleteResult.map((website) => ({
    label: website,
    value: website,
  }));

  const loadData2 = async () => {
    const { data } = await bsApi.get(`/accounts/push-count`);
    console.log('data::', data);
    setPushCount(data?.counts);
  };

  useEffect(() => {
    if (params?.id) {
      const loadData = async () => {
        const { data } = await bsApi.get(`/notifications/${params?.id}`);
        form?.current?.setFieldsValue(data);
        setData(data);
      };

      loadData().catch((e) => {
        history.goBack();
      });
    }
  }, []);

  useEffect(() => {
    if (pushCount === 0) {
      loadData2().catch((e) => {
        history.goBack();
      });
    }
  }, [pushCount]);

  return (
    <div>
      <h2>PUSH 발송</h2>
      <Form ref={form} onFinish={handleSubmit}>
        <div className={'pageWrapper'}>
          <Card>
            <h5>기본정보</h5>
            <table className={'details-table'}>
              <colgroup>
                <col width={'150'} />
                <col width={'auto'} />
                <col width={'150'} />
                <col width={'auto'} />
              </colgroup>
              <tbody>
                {params.id ? (
                  <tr>
                    <th>상태</th>
                    <td>{PUSH_STATUS[data?.status]}</td>
                    <th>성공 / 실패</th>
                    <td>
                      {data?.successCount} / {data?.failureCount}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <th>카테고리</th>
                    <td colSpan={3}>푸쉬 수신 동의 회원 ({pushCount}명)</td>
                  </tr>
                )}
                <tr>
                  <th>
                    제목 <RedFont>*</RedFont>{' '}
                  </th>
                  <td colSpan={3}>
                    <Form.Item name={'title'} rules={[{ required: true, message: '제목을 입력하세요.' }]}>
                      <Input disabled={!!params.id} />
                    </Form.Item>
                  </td>
                </tr>
                <tr>
                  <th>
                    내용 <RedFont>*</RedFont>{' '}
                  </th>
                  <td colSpan={3}>
                    <Form.Item name={'body'} rules={[{ required: true, message: '내용을 입력하세요.' }]}>
                      <Input.TextArea disabled={!!params.id} autoSize={{ minRows: 5 }} />
                    </Form.Item>
                  </td>
                </tr>
                <tr>
                  <th>링크</th>
                  <td colSpan={3}>
                    {!params?.id ? (
                      <Form.Item name={'link'} rules={[{ required: true, message: '사이트 주소를 입력하여 주세요' }]}>
                        <AutoComplete options={websiteOptions} onChange={onWebsiteChange} placeholder="사이트 입력">
                          <Input />
                        </AutoComplete>
                      </Form.Item>
                    ) : (
                      <Form.Item name={'link'} rules={[{ required: true, message: '사이트 주소를 입력하여 주세요' }]}>
                        <Input disabled={true} />
                      </Form.Item>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </Card>
          <div>
            <Row style={{ marginTop: 10 }}>
              <Col flex={1}></Col>
              <Col flex={1} style={{ textAlign: 'right' }}>
                <Button htmlType="button" onClick={handleBack}>
                  목록으로
                </Button>

                {!params.id && (
                  <>
                    {' '}
                    <Button htmlType="submit" type="primary">
                      저장
                    </Button>
                  </>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default PushDetails;
