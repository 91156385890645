import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useWindowsDim } from '@common/hooks/useWindowsDim';
import { produce } from 'immer';
import { Button, Card, Col, DatePicker, Form, Input, Row, Select, Table, Upload } from 'antd';
import { bsApi, getAPIHost } from '@common/api';
import moment from 'moment';
import { searchItemLayout } from '@common/constants';
import { useAppState } from '@components/shared/AppProvider';
import { shallowEqual, useSelector } from 'react-redux';
import querystring from 'querystring';
import useListForm from '@common/hooks/useListForm';

const columnsOrigin = [
  {
    title: 'NO',
    dataIndex: 'rowNum',
    key: 'rowNum',
    width: 80,
    fixed: 'left',
    align: 'center',
  },
  {
    title: '통화구분',
    dataIndex: 'callType',
    key: 'callType',
    width: 110,
  },
  {
    title: '통화시작',
    dataIndex: 'sCall',
    key: 'sCall',
    width: 160,
    align: 'center',
    render: (text, record, index) => moment(record.scall).format('YYYY-MM-DD HH:mm'),
  },
  {
    title: '통화종료',
    dataIndex: 'eCall',
    key: 'eCall',
    width: 160,
    align: 'center',
    render: (text, record, index) => {
      return moment(record.ecall).format('YYYY-MM-DD hh:mm');
    },
  },
  {
    title: '발신 전화',
    dataIndex: 'senderPhone',
    key: 'senderPhone',
    width: 160,
  },
  {
    title: '수신 전화',
    dataIndex: 'receivePhone',
    key: 'receivePhone',
    width: 160,
  },
  {
    title: '상담번호',
    dataIndex: 'consultation',
    key: 'code',
    width: 180,
    align: 'center',
    render: (v) => v?.code,
  },
  {
    title: '회원ID',
    dataIndex: 'consultation',
    key: 'memberUserName',
    width: 160,
    align: 'center',
    render: (text, record, index) => {
      if (record?.consultation) {
        return record?.consultation?.createdBy?.username;
      } else {
        return '';
      }
    },
  },
  {
    title: '회원 이름',
    dataIndex: 'consultation',
    key: 'memberName',
    width: 160,
    align: 'center',
    render: (text, record, index) => {
      return record?.consultation?.createdBy?.name;
    },
  },
  {
    title: '상담원 ID',
    dataIndex: 'consultation',
    key: 'consultantUserName',
    width: 160,
    align: 'center',
    render: (text, record, index) => {
      if (record?.consultation) {
        if (record?.consultation?.type === 'BASIC_CONSULTATION') {
          return record?.consultation?.consultant?.username;
        } else {
          return record?.consultation?.proConsultant?.username;
        }
      } else {
        return '';
      }
    },
  },
  {
    title: '상담원 이름',
    dataIndex: 'consultation',
    key: 'consultantName',
    width: 160,
    align: 'center',
    render: (text, record, index) => {
      if (record?.consultation) {
        if (record?.consultation?.type === 'BASIC_CONSULTATION') {
          return record?.consultation?.consultant?.name;
        } else {
          return record?.consultation?.proConsultant?.name;
        }
      } else {
        return '';
      }
    },
  },
  {
    title: '상담원 구분',
    dataIndex: 'consultation',
    key: 'consultantType',
    width: 160,
    align: 'center',
    render: (text, record, index) => {
      if (record?.consultation) {
        if (record?.consultation?.type === 'BASIC_CONSULTATION') {
          return '기본 상담원';
        } else {
          return '전문 상담원';
        }
      } else {
        return '';
      }
    },
  },
];

const initialQuery = {
  page: 0,
  callType: undefined,
  consultation: undefined,
  counselor: undefined,
  searchType: undefined,
  periodType: 'sCall',
  keyword: undefined,
  fromDate: undefined,
  toDate: undefined,
};

const CallIndex = (props) => {
  const { principal } = useSelector((s) => s.auth, shallowEqual);
  const fetchData = useCallback(async (query) => {
    const { data: response } = await bsApi.get('/bound', { params: query });
    return {
      rows: response?.content || [],
      pagination: {
        current: response?.number + 1,
        pageSize: response?.size,
        total: response?.totalElements || 0,
      },
    };
  }, []);

  const { form, tableProps, onFormDateRangeChange, onFormFinish, onFormReset } = useListForm({
    ...props,
    fetchData,
    initialQuery,
    columnsOrigin,
    applyColumns: (draft, data) => {
      draft[0].render = (text, record, index) => {
        const page = data?.pagination;
        return page?.total - page?.pageSize * (page?.current - 1) - index;
      };
    },
  });

  return (
    <div>
      <h2>통화이력</h2>
      <Form ref={form} onFinish={onFormFinish}>
        <Card>
          <Form.Item {...searchItemLayout} label="기간검색">
            <Row type={'flex'} gutter={[5, 5]}>
              <Col flex={1}>
                <Form.Item name="dateRange" noStyle>
                  <DatePicker.RangePicker
                    style={{ marginRight: 5 }}
                    disabled={[false, false]}
                    allowClear
                    allowEmpty={[true, true]}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Button.Group>
                  <Button onClick={() => onFormDateRangeChange(0)}>오늘</Button>
                  <Button onClick={() => onFormDateRangeChange(7)}>7일</Button>
                  <Button onClick={() => onFormDateRangeChange(30)}>30일</Button>
                  <Button onClick={() => onFormDateRangeChange(365)}>1년</Button>
                </Button.Group>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item {...searchItemLayout} label="선택검색">
            <Row type={'flex'} gutter={[5, 5]}>
              <Col flex={1}>
                <Form.Item name="callType" noStyle>
                  <Select style={{ flex: 1, marginRight: 5 }} placeholder="통화구분" allowClear>
                    <Select.Option value="INBOUND">Inbound</Select.Option>
                    <Select.Option value="OUTBOUND">Outbound</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col flex={1}>
                <Form.Item name="consultation" noStyle>
                  <Select style={{ flex: 1, marginRight: 5 }} placeholder="상담원구분" allowClear>
                    <Select.Option value="BASIC_CONSULTATION">기본상담원</Select.Option>
                    <Select.Option value="PRO_CONSULTATION">전문상담원</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item {...searchItemLayout} label="상세검색">
            <Row type={'flex'} gutter={[5, 5]}>
              <Col>
                <Form.Item name="searchType" noStyle>
                  <Select style={{ width: 150, marginRight: 5 }} placeholder={'전체'} allowClear>
                    <Select.Option value="memberName">회원이름</Select.Option>
                    <Select.Option value="memberId">회원 ID</Select.Option>
                    <Select.Option value="accountId">상담원 ID</Select.Option>
                    <Select.Option value="accountName">상담원이름</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col flex={1} style={{ minWidth: 280 }}>
                <Form.Item name="keyword" noStyle>
                  <Input placeholder="검색어를 입력하세요." />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
        </Card>
        <div style={{ marginTop: 10 }}>
          <Row>
            <Col xs={12}></Col>
            <Col style={{ textAlign: 'right' }} xs={12}>
              <Button htmlType="button" onClick={onFormReset}>
                초기화
              </Button>{' '}
              <Button htmlType="submit" type="primary">
                검색
              </Button>
            </Col>
          </Row>
        </div>
      </Form>

      <div style={{ marginTop: 25 }}>
        <Table rowKey={(record) => record.id} {...tableProps} />
      </div>

      <Col span={12} style={{ textAlign: 'right' }}>
        {['ADMIN', 'CENTER_MANAGER'].includes(principal.type) && (
          <Upload
            name="file"
            action={getAPIHost() + 'api/files/bound'}
            headers={bsApi.defaults.headers.common}
            onSuccess={(response) => {
              response === 'success' ? alert('파일 등록 완료') : alert('실패');
            }}
            onError={(e) => {
              alert('error::', e);
            }}
            onChange={(e) => {
              console.log(e);
              if (e.file.status === 'done' || e.file.status === 'removed') {
                console.log('dddd', e);
              }
            }}
          >
            <Button style={{ marginLeft: 5 }}>통화기록 엑셀 파일 등록</Button>
          </Upload>
        )}
      </Col>
    </div>
  );
};

export default CallIndex;
