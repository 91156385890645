import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useWindowsDim } from '@common/hooks/useWindowsDim';
import { produce } from 'immer';
import { Table, Row, Col, Button, Card, Form, Input, Select } from 'antd';
import { bsApi } from '@common/api';
import { useAppState } from '@components/shared/AppProvider';
import { useSelector, shallowEqual } from 'react-redux';
import querystring from 'querystring';
import { Link } from 'react-router-dom';
import { searchItemLayout } from '@common/constants';
import useListForm from '@common/hooks/useListForm';
const { Option } = Select;

const columnsOrigin = [
  {
    title: 'NO',
    dataIndex: 'rowNum',
    key: 'rowNum',
    width: 60,
    align: 'center',
    fixed: 'left',
  },
  {
    title: '코드',
    dataIndex: 'code',
    key: 'code',
    width: 120,
    fixed: 'left',
  },
  {
    title: '취약점 명',
    dataIndex: 'riskName',
    key: 'riskName',
    width: 250,
    align: 'left',
  },
  {
    title: '허용여부',
    dataIndex: 'visible',
    key: 'visible',
    width: 80,
    render: (data) => {
      return data ? '표출' : '미노출';
    },
  },
  {
    title: '성공 메세지',
    dataIndex: 'successMessage',
    key: 'successMessage',
    width: 'auto',
    align: 'left',
  },
  {
    title: '실패 메세지',
    dataIndex: 'failureMessage',
    key: 'failureMessage',
    width: 'auto',
    align: 'left',
  },
  {
    title: '새는 돈 명칭',
    dataIndex: 'leakMoneyName',
    key: 'leakMoneyName',
    width: 'auto',
    align: 'left',
  },
  {
    title: '새는 돈 메세지',
    dataIndex: 'leakMoneyMessage',
    key: 'leakMoneyMessage',
    width: 'auto',
    align: 'left',
  },
  {
    title: '관리',
    key: 'operation',
    width: 80,
    align: 'center',
    fixed: 'right',
  },
];

const initialQuery = {
  page: 0,
  limit: 10,
  searchType: '',
  periodType: 'createdAt',
  keyword: '',
};

const TodoRiskIndex = (props) => {
  const fetchData = useCallback(async (query) => {
    const { status, data: response } = await bsApi.get('/todo/risk-messages', { params: query });
    return {
      // 내용이 없으면 빈 화면 띄우기
      rows: response?.content || [],
      pagination: {
        current: response?.number + 1,
        pageSize: response?.size,
        total: response?.totalElements || 0,
      },
    };
  }, []);

  const { form, tableProps, onFormFinish, onFormReset } = useListForm({
    ...props,
    fetchData,
    initialQuery,
    columnsOrigin,
    applyColumns: (draft, data) => {
      draft[0].render = (text, record, index) => {
        const page = data?.pagination;
        return page?.total - page?.pageSize * (page?.current - 1) - index;
      };

      draft[columnsOrigin.length - 1].render = (record) => (
        <Link to={`/todo/risk/${record?.id}`}>
          <Button htmlType={'button'} size={'small'}>
            상세
          </Button>
        </Link>
      );
    },
  });

  return (
    <div>
      <h2>취약점 관리</h2>
      <Form ref={form} onFinish={onFormFinish}>
        <Card>
          <Form.Item {...searchItemLayout} label="직접검색">
            <Row type={'flex'} gutter={[5, 5]}>
              <Col>
                <Form.Item name="searchType" noStyle>
                  <Select style={{ width: 150, marginRight: 5 }} placeholder={'전체'} allowClear>
                    <Select.Option value="riskName">취약점명</Select.Option>
                    <Select.Option value="code">코드</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col flex={1}>
                <Form.Item name="keyword" noStyle>
                  <Input placeholder="검색어를 입력하세요." />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
        </Card>
        <div style={{ marginTop: 10 }}>
          <Row>
            <Col style={{ textAlign: 'right' }} xs={24}>
              <Button htmlType="button" onClick={onFormReset}>
                초기화
              </Button>{' '}
              <Button htmlType="submit" type="primary">
                검색
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
      <br />
      <br />
      <Table {...tableProps} rowKey={(record) => record.id} />
    </div>
  );
};
export default TodoRiskIndex;
