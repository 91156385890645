import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useWindowsDim } from '@common/hooks/useWindowsDim';
import { Card, Row, Col, Descriptions, Button, Tabs, Form, Select, Input, Table } from 'antd';
import { produce } from 'immer';
import styled from 'styled-components';
import { WANT_TIME, CONSULTANT_TYPE, searchItemLayout, DATETIME_FORMAT } from '@common/constants';
import moment from 'moment';
import { bsApi } from '@common/api';
import { Link } from 'react-router-dom';
import { useAppState } from '@components/shared/AppProvider';
import useListForm from '@common/hooks/useListForm';

const columnsOrigin = [
  {
    title: 'NO1',
    dataIndex: 'rowNum',
    key: 'rowNum',
    width: 80,
    fixed: 'left',
    align: 'center',
  },
  {
    title: '센터명',
    dataIndex: 'name',
    key: 'name',
    width: 'auto',
    minWidth: 300,
  },
  {
    title: '센터코드',
    dataIndex: 'code',
    key: 'code',
    width: 150,
    align: 'center',
  },
  {
    title: '사업자등록번호',
    dataIndex: 'crn',
    key: 'crn',
    width: 150,
    align: 'center',
    render: (v) => v && `${v.substring(0, 3)}-${v.substring(3, 5)}-${v.substring(5)}`,
  },
  {
    title: '첨부',
    dataIndex: 'businessLicense',
    key: 'businessLicense',
    align: 'center',
    width: 80,
    render: (v) => (v ? '첨부' : '미첨부'),
  },
  {
    title: '사이트',
    dataIndex: 'url',
    key: 'url',
    align: 'left',
    width: 200,
    render: (v) => (
      <a href={v} target="_blank">
        {' '}
        {v}{' '}
      </a>
    ),
  },
  {
    title: '공개여부',
    dataIndex: 'display',
    key: 'display',
    width: 100,
    align: 'center',
    render: (v) => (v ? '공개' : '비공개'),
  },
  {
    title: '등록자',
    dataIndex: 'createdBy',
    key: 'createdBy',
    width: 100,
    render: (v) => v?.name,
  },
  {
    title: '등록일',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 150,
    render: (v) => moment(v).format(DATETIME_FORMAT),
  },
  {
    title: '관리',
    key: 'operation',
    width: 80,
    align: 'center',
    fixed: 'right',
  },
];

const initialQuery = {
  page: 0,
  size: 20,
  display: undefined,
  searchType: undefined,
  keyword: '',
};

const CodeCenterIndex = (props) => {
  const fetchData = useCallback(async (query) => {
    const { data: response } = await bsApi.get('/centers', { params: query });
    return {
      rows: response?._embedded?.responseList || [],
      pagination: {
        current: response?.page?.number + 1,
        pageSize: response?.page?.size,
        total: response?.page?.totalElements || 0,
      },
    };
  }, []);

  const { form, tableProps, onFormFinish, onFormReset } = useListForm({
    ...props,
    fetchData,
    initialQuery,
    columnsOrigin,
    applyColumns: (draft, data) => {
      draft[0].render = (text, record, index) => {
        const page = data?.pagination;
        return page?.total - page?.pageSize * (page?.current - 1) - index;
      };

      draft[9].render = (record) => (
        <Link to={`/codes/center/${record.id}`}>
          <Button htmlType={'button'} size={'small'}>
            상세
          </Button>
        </Link>
      );
    },
  });

  return (
    <div>
      <h2>센터 관리</h2>
      <Form ref={form} onFinish={onFormFinish}>
        <Card>
          <Form.Item {...searchItemLayout} label="직접검색">
            <Row type={'flex'} gutter={[5, 5]}>
              <Col>
                <Form.Item name="searchType" noStyle>
                  <Select style={{ width: 150, marginRight: 5 }} allowClear placeholder={'전체'}>
                    <Select.Option value="name">센터명</Select.Option>
                    <Select.Option value="code">센터코드</Select.Option>
                    <Select.Option value="crn">사업자등록번호</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col flex={1}>
                <Form.Item name="keyword" noStyle>
                  <Input placeholder="검색어를 입력하세요." />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item {...searchItemLayout} label="공개여부">
            <Row type={'flex'} gutter={[5, 5]}>
              <Col>
                <Form.Item name="display" noStyle>
                  <Select style={{ width: 150, marginRight: 5 }} allowClear placeholder={'전체'}>
                    <Select.Option value={true}>공개</Select.Option>
                    <Select.Option value={false}>비공개</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
        </Card>
        <div style={{ marginTop: 10 }}>
          <Row>
            <Col xs={12}></Col>
            <Col style={{ textAlign: 'right' }} xs={12}>
              <Button htmlType="button" onClick={onFormReset}>
                초기화
              </Button>{' '}
              <Button htmlType="submit" type="primary">
                검색
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
      <div style={{ marginTop: 25 }}>
        <Table
          {...tableProps}
          rowKey={(record) => record.id}
          footer={() => (
            <Row>
              <Col xs={12}></Col>
              <Col style={{ textAlign: 'right' }} xs={12}>
                <Link to={'/codes/center/create'}>
                  <Button htmlType="button" type="primary">
                    등록
                  </Button>
                </Link>
              </Col>
            </Row>
          )}
        />
      </div>
    </div>
  );
};
export default CodeCenterIndex;
