import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useWindowsDim } from '@common/hooks/useWindowsDim';
import { produce } from 'immer';
import { Button, Card, Col, Form, Input, message, Row, Select, Table } from 'antd';
import { bsApi } from '@common/api';
import { Link } from 'react-router-dom';
import { DATETIME_FORMAT, searchItemLayout } from '@common/constants';
import moment from 'moment';
import { useAppState } from '@components/shared/AppProvider';
import useListForm from '@common/hooks/useListForm';

const columnsOrigin = [
  {
    title: 'NO',
    dataIndex: 'rowNum',
    key: 'rowNum',
    width: 80,
    fixed: 'left',
    align: 'center',
  },
  {
    title: '기관명',
    dataIndex: 'name',
    key: 'name',
    width: 'auto',
    minWidth: 200,
  },
  {
    title: '기관코드',
    dataIndex: 'code',
    key: 'code',
    width: 150,
    align: 'center',
  },
  {
    title: '사업자등록번호',
    dataIndex: 'crn',
    key: 'crn',
    width: 150,
    align: 'center',
    render: (v) => v && `${v.substring(0, 3)}-${v.substring(3, 5)}-${v.substring(5)}`,
  },
  {
    title: '사이트',
    dataIndex: 'url',
    key: 'url',
    align: 'left',
    width: 200,
    render: (v) => (
      <a href={v} target="_blank">
        {' '}
        {v}{' '}
      </a>
    ),
  },
  {
    title: '등록자',
    dataIndex: 'createdBy',
    key: 'createdBy',
    width: 130,
    render: (v) => v?.name,
  },
  {
    title: '등록일',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 160,
    render: (v) => moment(v).format(DATETIME_FORMAT),
  },
  {
    title: '관리',
    key: 'operation',
    width: 80,
    align: 'center',
    fixed: 'right',
  },
];

const initialQuery = {
  page: 0,
  size: 20,
  searchType: undefined,
  keyword: '',
};

const CodeRMIndex = (props) => {
  const fetchData = useCallback(async (query) => {
    const { data: response } = await bsApi.get('/institutions', { params: query });
    return {
      rows: response?._embedded?.responseList || [],
      pagination: {
        current: response?.page?.number + 1,
        pageSize: response?.page?.size,
        total: response?.page?.totalElements || 0,
      },
    };
  }, []);

  const { form, tableProps, onFormFinish, onFormReset } = useListForm({
    ...props,
    fetchData,
    initialQuery,
    columnsOrigin,
    applyColumns: (draft, data) => {
      draft[0].render = (text, record, index) => {
        const page = data?.pagination;
        return page?.total - page?.pageSize * (page?.current - 1) - index;
      };

      draft[7].render = (record) => (
        <Link to={`/codes/rm/${record.id}`}>
          <Button htmlType={'button'} size={'small'}>
            상세
          </Button>
        </Link>
      );
    },
  });

  return (
    <div>
      <h2>기관 관리</h2>
      <Form ref={form} onFinish={onFormFinish}>
        <Card>
          <Form.Item {...searchItemLayout} label="직접검색">
            <Row type={'flex'} gutter={[5, 5]}>
              <Col>
                <Form.Item name="searchType" noStyle>
                  <Select style={{ width: 150, marginRight: 5 }} placeholder={'전체'} allowClear>
                    <Select.Option value="name">기관명</Select.Option>
                    <Select.Option value="code">기관 코드</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col flex={1}>
                <Form.Item name="keyword" noStyle>
                  <Input placeholder="검색어를 입력하세요." />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
        </Card>
        <div style={{ marginTop: 10 }}>
          <Row>
            <Col xs={12}></Col>
            <Col style={{ textAlign: 'right' }} xs={12}>
              <Button htmlType="button" onClick={onFormReset}>
                초기화
              </Button>{' '}
              <Button htmlType="submit" type="primary">
                검색
              </Button>
            </Col>
          </Row>
        </div>
        <div style={{ marginTop: 25 }}>
          <Table
            {...tableProps}
            rowKey={(record) => record.id}
            footer={() => (
              <Row>
                <Col xs={12}></Col>
                <Col style={{ textAlign: 'right' }} xs={12}>
                  <Link to={'/codes/rm/create'}>
                    <Button htmlType="button" type="primary">
                      등록
                    </Button>
                  </Link>
                </Col>
              </Row>
            )}
          />
        </div>
      </Form>
    </div>
  );
};

export default CodeRMIndex;
