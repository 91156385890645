import React, { useEffect, useRef, useState } from 'react';
import { useWindowsDim } from '@common/hooks/useWindowsDim';
import { produce } from 'immer';
import querystring from 'querystring';
import { isEqual, omit } from 'lodash';
import moment from 'moment';

const useListForm = ({ initialQuery, columnsOrigin, applyColumns, fetchData, location, history }) => {
  const { width } = useWindowsDim();
  const tableWidth = width - 280;

  const form = useRef();
  const [data, setData] = useState({});
  const lastParams = useRef();

  const params = {
    ...initialQuery,
    ...querystring.decode(location?.search?.substring(1)),
  };

  const qs = (args) => {
    return Object.keys(args)
      .filter((v) => !!v && !!args[v])
      .reduce((x, y) => {
        let str = x ? '&' : '';
        str += y + '=' + encodeURIComponent(args[y]);
        return x + str;
      }, '');
  };

  const columns = produce(columnsOrigin, (draft) => {
    const cwidths = draft.reduce((a, c) => {
      if (c.width !== 'auto') {
        return a + c.width;
      } else {
        return a + 300;
      }
    }, 0);

    draft.forEach((v) => {
      v.ellipsis = {
        showTitle: false,
      };
    });

    if (cwidths > tableWidth) {
      draft.forEach((v) => {
        if (v.width === 'auto') v.width = 300;
      });
    }

    applyColumns(draft, data);
  });

  const onFormFinish = async (data) => {
    const args = { ...params, ...omit(data, ['dateRange']) };
    args.fromDate = data?.dateRange?.[0]?.format?.('YYYY-MM-DD');
    args.toDate = data?.dateRange?.[1]?.format?.('YYYY-MM-DD');
    args.page = 0;

    const path = `${location.pathname}?${qs(args)}`;
    history.replace(path);
  };

  const onFormReset = async () => {
    const path = `${location.pathname}?${qs(initialQuery)}`;
    history.replace(path);
  };

  const onFormDateRangeChange = (date) => {
    const date1 = moment().add(-date, 'days');
    const date2 = moment();
    form?.current?.setFieldsValue({
      dateRange: [date1, date2],
    });
    form?.current?.submit();
  };

  const handleTableChange = async (pager) => {
    const args = produce(params, (draft) => {
      draft.page = pager?.current - 1;
      draft.size = pager?.pageSize;
    });
    const path = `${location.pathname}?${qs(args)}`;
    history.replace(path);
  };

  useEffect(() => {
    if (!isEqual(lastParams.current, params)) {
      fetchData(params)
        .then(setData)
        .catch((e) => {
          alert('서버와 연결이 올바르지 않습니다.');
          console.warn(e);
        });

      // form.current?.resetFields();
      form.current?.setFieldsValue({
        ...params,
        dateRange: [params?.fromDate && moment(params?.fromDate), params?.toDate && moment(params?.toDate)],
      });
    }
    lastParams.current = params;
    const path = `${location.pathname}?${qs(params)}`;
    history.replace(path);
  }, [JSON.stringify(params)]);

  let fullWidth = 0;
  columns.forEach((v) => (fullWidth += v.width));

  const tableProps = {
    columns,
    dataSource: data?.rows || [],
    pagination: data?.pagination || {},
    scroll: { x: fullWidth },
    onChange: handleTableChange,
    bordered: true,
  };

  return {
    params,
    form,
    data,
    tableProps,
    onFormFinish,
    onFormReset,
    onFormDateRangeChange,
  };
};

export default useListForm;
