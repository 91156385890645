import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  DiscreteColorLegend,
  FlexibleWidthXYPlot,
  VerticalBarSeries,
  VerticalBarSeriesCanvas,
  HorizontalGridLines,
  LabelSeries,
  VerticalGridLines,
  XAxis,
  YAxis,
} from 'react-vis';

const Legend = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0;
  .rv-discrete-color-legend {
    display: inline-block;
    width: auto !important;
    height: 30px !important;
  }
  .rv-discrete-color-legend-item:first-child {
    padding-top: 0;
    padding-bottom: 0;
    .rv-discrete-color-legend-item__color__path {
      stroke: rgb(0, 122, 255) !important;
    }
  }
`;

const series = [
  {
    title: '가입',
  },
  {
    title: '탈퇴',
  },
];

const Chart2 = ({ myDATA, myDATA2 }) => {
  const [useCanvas, setUseCanvas] = useState(false);
  let BarSeries = VerticalBarSeries;

  useEffect(() => {
    if (useCanvas) {
      BarSeries = VerticalBarSeriesCanvas;
    }
  }, [useCanvas]);

  return (
    <div>
      <Legend>
        <DiscreteColorLegend width={280} height={10} items={series} />
      </Legend>
      <FlexibleWidthXYPlot xType="ordinal" height={300} xDistance={100}>
        <VerticalGridLines style={{ strokeWidth: 0.5 }} />
        <HorizontalGridLines style={{ strokeWidth: 0.5 }} />
        <XAxis style={{ strokeWidth: 0.5 }} />
        <YAxis style={{ strokeWidth: 0.5 }} />
        <BarSeries color="#007bff" data={myDATA} />
        <BarSeries color="rgb(211, 232, 255)" data={myDATA2} />
        {/*<LabelSeries data={myDATA} getLabel={(d) => d.y} />*/}
      </FlexibleWidthXYPlot>
    </div>
  );
};

export default Chart2;
