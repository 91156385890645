import React, { useCallback, useEffect, useState } from 'react';
import { bsApi, getAPIHost } from '@common/api';
import { Button, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { isEqual } from 'lodash';

const UploadFile = ({ ids, single, onChange, listType, disabled }) => {
  const [files, setFiles] = useState([]);
  const [fileIds, setFileIds] = useState([]);
  const fileList = single && files.length >= 2 ? files.slice(-1) : files;

  const loadData = useCallback(async (ids) => {
    const r = await bsApi.post('/files/info', { ids });
    setFiles(
      r.data.map((v) => ({
        uid: v.id,
        name: v.filename,
        status: 'done',
        response: v, // custom error message to show
        url: `https://bankscan.io/api/files/${v.id}`,
      })),
    );
  }, []);

  useEffect(() => {
    if (isEqual(fileIds, ids)) return;
    if ((ids || []).filter((v) => !!v).length > 0) {
      loadData(ids || []).catch(console.log);
      setFileIds(ids);
    }
  }, [ids]);

  useEffect(() => {
    if (isEqual(fileIds, ids)) return;
    onChange(fileIds);
  }, [fileIds]);

  return (
    <Upload
      multiple={!single}
      name={'file'}
      listType={listType}
      action={getAPIHost() + 'api/files'}
      headers={bsApi.defaults.headers.common}
      fileList={fileList}
      onChange={(e) => {
        setFiles(e.fileList);
        if (e.file.status === 'done' || e.file.status === 'removed') {
          setFileIds(e.fileList.map((v) => v?.response?.id).filter((v) => !!v));
        }
      }}
    >
      <Button disabled={disabled}>
        <UploadOutlined /> Upload
      </Button>
    </Upload>
  );
};

export default UploadFile;
