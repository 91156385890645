import React, { useCallback, useEffect, useRef, useState } from 'react';
import { produce } from 'immer';
import { Tabs } from 'antd';
import { bsApi } from '@common/api';
import { ConsultantMain, ManagerMain } from '@widgets/calculates/main';
import moment from 'moment';
import { shallowEqual, useSelector } from 'react-redux';
import querystring from 'querystring';

const initialQuery = {
  page: 0,
  limit: 20,
  searchType: '',
  periodType: 'createdAt',
  keyword: '',
  fromDate: undefined,
  toDate: undefined,
};

const CalculatesIndex = (props) => {
  const form = useRef();

  const [data, setData] = useState(null);
  const [query, setQuery] = useState(initialQuery);

  const fetchData = useCallback(async () => {
    const { status, data: response } = await bsApi.get('/calculate');
    console.log('response::', response);
    setData({
      rows: response || [],
    });
  }, [query]);

  const handleSubmit = async (data) => {
    setQuery(
      produce((draft) => {
        draft.page = 0;
        draft.keyword = data.keyword;
        draft.searchType = data.searchType;
        draft.fromDate = data.dateRange?.[0]?.format('YYYY-MM-DD');
        draft.toDate = data.dateRange?.[1]?.format('YYYY-MM-DD');
      }),
    );
  };

  const handleDateRangeSet = (date) => {
    const date1 = moment().add(-date, 'days');
    const date2 = moment();
    form?.current?.setFieldsValue({
      dateRange: [date1, date2],
    });
    form?.current?.submit();
  };

  const handleReset = async () => {
    form?.current?.setFieldsValue(initialQuery);
    await handleSubmit(initialQuery);
  };

  useEffect(() => {
    const { history, location } = props;
    history.replace(location.pathname + '?' + querystring.encode(query));
    fetchData().catch(console.log);
  }, [query]);

  const { principal } = useSelector((state) => state.auth, shallowEqual);

  if (principal.type === 'CENTER_MANAGER') {
    if (principal.authorities.includes('CONSULTANT')) {
      return (
        <div>
          <h2>정산관리</h2>
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="센터장 모드" key="1">
              <ManagerMain principal={principal} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="상담원 모드" key="2">
              <ConsultantMain principal={principal} />
            </Tabs.TabPane>
          </Tabs>
        </div>
      );
    } else {
      return (
        <div>
          <h2>정산 관리</h2>
          <ManagerMain
            principal={principal}
            data={data}
            handleSubmit={handleSubmit}
            handleDateRangeSet={handleDateRangeSet}
            form={form}
          />
        </div>
      );
    }
  } else if (principal.type === 'CONSULTANT') {
    return (
      <div>
        <h2>정산관리</h2>
        <ConsultantMain
          principal={principal}
          data={data}
          handleSubmit={handleSubmit}
          handleDateRangeSet={handleDateRangeSet}
          form={form}
        />
      </div>
    );
  } else {
    return (
      <div>
        <h2>정산 관리</h2>
        <ManagerMain
          principal={principal}
          data={data}
          query={query}
          handleSubmit={handleSubmit}
          handleDateRangeSet={handleDateRangeSet}
          form={form}
        />
      </div>
    );
  }
};

export default CalculatesIndex;
