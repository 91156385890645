import React, { useCallback, useRef, useState } from 'react';
import { Button, Card, Checkbox, Divider, Form, Input, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { bsApi } from '@common/api';
import UploadImage from '@components/UploadImage';
import PostcodeFinder from '@components/FindPostcode';
import UploadFile from '@components/UploadFile';

const { Option } = Select;

const TodoResourceForm = ({ currentData, toDoMessages, params }) => {
  const form = useRef();
  const [currentInfo, setCurrentInfo] = useState({ ...currentData, toDoMessages: toDoMessages });
  const [saveBigCateogry, setSaveBigCategory] = useState();
  const [saveSmallCategory, setSaveSmallCategory] = useState();
  const [isDirect, setIsDirect] = useState(currentData?.organizationId ? true : false);
  const [findAddr, setFindAddr] = useState(currentData?.address || '');
  const [logo, setLogo] = useState(currentData?.mainImage || '');
  const [images, setImages] = useState(currentData?.subImages || '');
  const [select, setSelect] = useState('');

  console.log(currentInfo);
  //기관명 셀렉박스 바꿀 때
  const onChange = async (e) => {
    if (e.type === 'organizationId') {
      const select = currentInfo.organization.find((v) => v.id === e.value);
      const values = {};
      values['organizationName'] = select?.name;
      values['serviceUrl'] = select?.url;
      values['storeAndUrl'] = select?.storeAndUrl;
      values['storeIosUrl'] = select?.storeIosUrl;
      values['serviceSummary'] = select?.description;
      values['mainCall'] = select?.phoneNumber;
      values['address'] = select?.address + ' ' + select?.detailedAddress;
      values['zipCode'] = select?.zipCode;
      setLogo(select?.logo);
      setFindAddr(select?.address);
      setCurrentInfo({ ...currentInfo, ...values, [e.type]: e.value });
      form?.current?.setFieldsValue({ ...currentInfo, ...values });
      console.log(form?.current?.getFieldValue());
      return;
    }

    if (e.type === 'bigCategoryId') {
      const res = await bsApi.get(`/todo-category/small/${e.value}`);
      form?.current?.setFieldsValue({ smallCategoryId: null });
      setCurrentInfo({ ...currentInfo, bigCategoryId: e.value, smallCategory: res.data });
    } else {
      setCurrentInfo({ ...currentInfo, [e.type]: e.value });
    }
  };

  //대분류 항목 입력
  const handleBigCategoryChange = ({ currentTarget }) => {
    setSaveBigCategory(currentTarget.value);
  };

  //대분류 항목 추가하기
  const handleAddBigCategory = useCallback(async () => {
    const res = await bsApi.post('/todo-category/big', { bigCategory: saveBigCateogry });
    setCurrentInfo({ ...currentInfo, bigCategory: res?.data });
    //칸 비워주기
    setSaveBigCategory(null);
  }, [saveBigCateogry]);

  const onFocus = () => {};

  const onBlur = () => {};

  const onSearch = (value) => {};

  //저장
  const saveData = async (values) => {
    values.mainImage = logo;

    console.log(values);
    if (params === 'add') {
      const res = await bsApi.post('/todo/todo-resources', values);
    } else {
      values.id = currentData?.id;
      const res = await bsApi.put('/todo/todo-resources', values);
    }
    alert('저장 완료');
    history.back();
  };

  //삭제
  const onDelete = async () => {
    console.log(currentData.id);
    if (!confirm('삭제하시겠습니까?')) return;
    const res = await bsApi.delete(`/todo/todo-resources/${currentData.id}`);
    alert('삭제되었습니다.');
    history.back();
  };

  const handleComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }
    form?.current?.setFieldsValue({
      zipCode: data?.zonecode,
      address: fullAddress,
    });
    setFindAddr(fullAddress);
  };

  const searchKeyword = (e) => {
    setSelect(e);
  };

  return (
    <div>
      <h2>todo resource 상세</h2>
      <Form ref={form} onFinish={saveData}>
        <Card>
          <table className={'details-table'}>
            <colgroup>
              <col width={'30%'} />
              <col width={'70%'} />
            </colgroup>
            <tbody>
              <tr>
                <th>대분류</th>
                <td>
                  <Form.Item
                    name="bigCategoryId"
                    rules={[
                      {
                        required: true,
                        message: '대분류를 필수로 지정해 주세요.',
                      },
                    ]}
                    initialValue={currentInfo?.bigCategoryId}
                  >
                    <Select
                      placeholder="대분류"
                      onChange={(e) => onChange({ type: 'bigCategoryId', value: e })}
                      style={{ width: 200 }}
                      dropdownRender={(big) => (
                        <div>
                          {big}
                          <Divider style={{ margin: '4px 0' }} />
                          <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                            <Input
                              style={{ flex: 'auto' }}
                              value={saveBigCateogry}
                              onChange={handleBigCategoryChange}
                            />
                            <a
                              style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                              onClick={handleAddBigCategory}
                            >
                              <PlusOutlined /> 추가하기
                            </a>
                          </div>
                        </div>
                      )}
                    >
                      {currentInfo?.bigCategory &&
                        currentInfo?.bigCategory.map((big) => {
                          return (
                            <Option key={`big-category-${big.id}`} value={big.id}>
                              {big.bigCategory}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <th>소분류</th>
                <td>
                  <Form.Item
                    name="smallCategoryId"
                    rules={[
                      {
                        required: false,
                        message: '소분류를 필수로 지정해 주세요.',
                      },
                    ]}
                    initialValue={currentInfo?.smallCategoryId}
                  >
                    <Select
                      placeholder="소분류"
                      disabled={!currentInfo?.smallCategory?.length}
                      style={{ width: 200 }}
                      onChange={(e) => onChange({ type: 'smallCategoryId', value: e })}
                      value={currentInfo?.smallCategory}
                    >
                      {currentInfo?.smallCategory &&
                        currentInfo?.smallCategory.map((small) => {
                          return (
                            <Option key={`small-category-${small.id}`} value={small.id}>
                              {small.smallCategory}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <th>리소스 명칭</th>
                <td>
                  <Form.Item
                    name="name"
                    rules={[
                      {
                        required: false,
                        message: '명칭을 입력해 주세요.',
                      },
                    ]}
                    initialValue={currentInfo?.name}
                  >
                    <Input
                      style={{ width: '100%' }}
                      onChange={(e) => onChange({ type: 'name', value: e.target.value })}
                    />
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <th>로고 이미지</th>
                <td style={{ verticalAlign: 'top' }}>
                  <UploadImage
                    single={true}
                    ids={[logo]}
                    onChange={(items) => {
                      setLogo(items?.[0]);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th>성새 이미지</th>
                <td style={{ verticalAlign: 'top' }}>
                  <UploadFile
                    listType="picture"
                    ids={images}
                    multiple={true}
                    onChange={(items) => {
                      setImages(items);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th>url</th>
                <td>
                  <Form.Item name="serviceUrl" initialValue={currentInfo?.serviceUrl}>
                    <Input
                      style={{ width: '100%' }}
                      disabled={isDirect}
                      onChange={(e) => onChange({ type: 'serviceUrl', value: e.target.value })}
                    />
                  </Form.Item>
                </td>
              </tr>

              <tr>
                <th>설치주소 (ios)</th>
                <td>
                  <Form.Item name="storeIosUrl" initialValue={currentInfo?.storeIosUrl}>
                    <Input
                      style={{ width: '100%' }}
                      disabled={isDirect}
                      onChange={(e) => onChange({ type: 'storeIosUrl', value: e.target.value })}
                    />
                  </Form.Item>
                </td>
              </tr>

              <tr>
                <th>설치주소 (android)</th>
                <td>
                  <Form.Item name="storeAndUrl" initialValue={currentInfo?.storeAndUrl}>
                    <Input
                      style={{ width: '100%' }}
                      disabled={isDirect}
                      onChange={(e) => onChange({ type: 'storeAndUrl', value: e.target.value })}
                    />
                  </Form.Item>
                </td>
              </tr>

              <tr>
                <th>기관(업체)명</th>
                <td>
                  <Form.Item
                    name={'organizationName'}
                    rules={[
                      {
                        required: false,
                        message: `기관을 ${isDirect ? '선택' : '등록'}해 주세요.`,
                      },
                    ]}
                    initialValue={currentInfo?.organizationName}
                  >
                    {isDirect ? (
                      <Select
                        style={{ width: 280 }}
                        placeholder="기관명"
                        showSearch
                        optionFilterProp="children"
                        onChange={(e) => onChange({ type: 'organizationId', value: e })}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onSearch={onSearch}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {currentInfo?.organization &&
                          currentInfo?.organization
                            ?.sort((a, b) => {
                              return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
                            })
                            .map((o_v) => {
                              return (
                                <Option key={`organization-${o_v.id}`} value={o_v.id}>
                                  {o_v.name}
                                </Option>
                              );
                            })}
                      </Select>
                    ) : (
                      <Input
                        value={currentInfo?.organizationName}
                        onChange={(e) => onChange({ type: 'organizationName', value: e.target.value })}
                      />
                    )}
                  </Form.Item>
                  <Checkbox onChange={() => setIsDirect(!isDirect)} value={isDirect}>
                    기관 선택
                  </Checkbox>
                </td>
              </tr>
              <tr>
                <th>대표전화</th>
                <td>
                  <Form.Item
                    name="mainCall"
                    rules={[
                      {
                        required: false,
                        message: '전화번호를 입력해 주세요.',
                      },
                    ]}
                    initialValue={currentInfo?.mainCall}
                  >
                    <Input
                      style={{ width: '100%' }}
                      onChange={(e) => onChange({ type: 'mainCall', value: e.target.value })}
                      disabled={isDirect}
                    />
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <th>우편번호</th>
                <td>
                  <PostcodeFinder onComplete={handleComplete}>
                    <Button>우편번호 찾기</Button>
                  </PostcodeFinder>
                  <Form.Item
                    name="zipCode"
                    rules={[
                      {
                        required: false,
                        message: '우편번호를 입력해 주세요.',
                      },
                    ]}
                    initialValue={currentInfo?.zipCode}
                  >
                    <Input
                      style={{ width: '60%' }}
                      onChange={(e) => onChange({ type: 'zipCode', value: e.target.value })}
                      disabled
                    />
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <th>주소</th>
                <td>
                  <Form.Item
                    name="address"
                    rules={[
                      {
                        required: false,
                        message: '주소를 입력해 주세요.',
                      },
                    ]}
                    initialValue={currentInfo?.address}
                  >
                    <Input
                      style={{ width: '60%' }}
                      onChange={(e) => onChange({ type: 'address', value: e.target.value })}
                      disabled={isDirect}
                    />
                  </Form.Item>
                  {findAddr && (
                    <Button htmlType="a" href={`https://map.naver.com/v5/search/${findAddr}`} target="_blank">
                      네이버 지도 열기
                    </Button>
                  )}
                </td>
              </tr>

              <tr>
                <th>프로필</th>
                <td>
                  <Form.Item
                    name="profile"
                    rules={[
                      {
                        required: false,
                        message: '',
                      },
                    ]}
                    initialValue={currentInfo?.profile}
                  >
                    <Input.TextArea style={{ width: '100%' }} rows={6} />
                  </Form.Item>
                </td>
              </tr>
              {/* )} */}
              <tr>
                <th>서비스개요</th>
                <td>
                  <Form.Item
                    name="serviceSummary"
                    rules={[
                      {
                        required: false,
                        message: '서비스 개요를 입력해 주세요.',
                      },
                    ]}
                    initialValue={currentInfo?.serviceSummary}
                  >
                    <Input.TextArea
                      style={{ width: '100%' }}
                      rows={6}
                      disabled={isDirect}
                      onChange={(e) => onChange({ type: 'serviceSummary', value: e.target.value })}
                    />
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <th>유투브 영상 링크</th>
                <td>
                  <Form.Item
                    name="youtubeUrl"
                    rules={[
                      {
                        required: false,
                        message: '동영상 링크를 입력해 주세요.',
                      },
                    ]}
                    initialValue={currentInfo?.youtubeUrl}
                  >
                    <Input
                      style={{ width: '100%' }}
                      onChange={(e) => onChange({ type: 'youtubeUrl', value: e.target.value })}
                    />
                  </Form.Item>
                </td>
              </tr>
              {/*<tr>*/}
              {/*  <th>to do Messages</th>*/}
              {/*  <td>*/}
              {/*    <Form.Item*/}
              {/*      name="toDoMessageIds"*/}
              {/*      rules={[*/}
              {/*        {*/}
              {/*          required: false,*/}
              {/*          message: 'to do message를 선택해 주세요.',*/}
              {/*        },*/}
              {/*      ]}*/}
              {/*      initialValue={currentInfo?.toDoMessageIds || []}*/}
              {/*    >*/}
              {/*      <Select*/}
              {/*        mode="multiple"*/}
              {/*        allowClear*/}
              {/*        style={{ width: '100%' }}*/}
              {/*        placeholder="Please select"*/}
              {/*        onSearch={searchKeyword}*/}
              {/*        filterOption={false}*/}
              {/*        onChange={(e) => onChange({ type: 'toDoMessageIds', value: e })}*/}
              {/*      >*/}
              {/*        {currentInfo?.toDoMessages*/}
              {/*          ?.filter((v) => v.name.includes(select))*/}
              {/*          .map((v) => {*/}
              {/*            return (*/}
              {/*              <Option key={`mtoDOMessages-${v.id}`} value={v.id}>*/}
              {/*                [{v.code}]{v.name}*/}
              {/*              </Option>*/}
              {/*            );*/}
              {/*          })}*/}
              {/*      </Select>*/}
              {/*    </Form.Item>*/}
              {/*  </td>*/}
              {/*</tr>*/}
            </tbody>
          </table>
          <Button htmlType="submit">저장</Button>

          <Button htmlType="button" onClick={onDelete}>
            삭제
          </Button>
        </Card>
      </Form>
    </div>
  );
};

export default TodoResourceForm;
