import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useWindowsDim } from '@common/hooks/useWindowsDim';
import { produce } from 'immer';
import { Button, Card, Col, DatePicker, Form, Input, Row, Select, Table, Upload, message } from 'antd';
import { bsApi, getAPIHost } from '@common/api';
import moment from 'moment';
import FileSaver from 'file-saver';
import { useHistroy } from 'react-router-dom';
import * as constants from '@common/constants';
import {
  ACCOUNT_TYPE,
  CONSULTANT_TYPE,
  CONSULTATION_LEVEL,
  DATE_FORMAT,
  EDUCATION,
  PROFESSIONAL_FILELD,
  PROFESSIONAL_FILELD_SUB,
  searchItemLayout,
  WORK_TYPE,
} from '@common/constants';
import { useAppState } from '@components/shared/AppProvider';
import { Link } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { formatPrice } from '@common/helpers';
import querystring from 'querystring';
import useListForm from '@common/hooks/useListForm';

const columnsOrigin = [
  {
    title: 'NO',
    dataIndex: 'rowNum',
    key: 'rowNum',
    width: 60,
    fixed: 'left',
    align: 'center',
  },
  {
    title: '상담원 ID',
    dataIndex: 'username',
    key: 'username',
    width: 150,
    fixed: 'left',
  },
  {
    title: '이름',
    dataIndex: 'name',
    key: 'name',
    width: 120,
    fixed: 'left',
  },
  {
    title: '전화번호',
    dataIndex: 'mobilePhone',
    key: 'mobilePhone',
    width: 160,
    render: (text) => (text || '').replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, '$1-$2-$3'),
  },
  {
    title: '이메일',
    dataIndex: 'email',
    key: 'email',
    width: 260,
  },
  {
    title: '센터/기관 명',
    dataIndex: 'organization.name',
    key: 'organization.name',
    width: 'auto',
    render: (text, record, index) => (record.organization != null ? record.organization.name : ''),
  },
  {
    title: '센터/기관 코드',
    dataIndex: 'organization.code',
    key: 'organization.code',
    width: 150,
    align: 'center',
    render: (text, record, index) => (record.organization != null ? record.organization.code : ''),
  },
  {
    title: '근무형태',
    dataIndex: 'workerType',
    key: 'workerType',
    width: 120,
    align: 'center',
    render: (text, record, index) => (text != null ? constants.WORK_TYPE[text] : ''),
  },
  {
    title: '교육수료등급',
    dataIndex: 'educationCompletionLevel',
    key: 'educationCompletionLevel',
    width: 120,
    align: 'center',
    render: (text, record, index) => (text != null ? constants.EDUCATION[text] : ''),
  },
  {
    title: '상담원 레벨',
    dataIndex: 'consultantLevel',
    key: 'consultantLevel',
    width: 120,
    align: 'center',
    render: (text, record, index) => (text != null ? constants.CONSULTATION_LEVEL[text] : ''),
  },
  {
    title: '전문분야',
    dataIndex: 'professionalField2',
    key: 'professionalField2',
    width: 180,
    render: (text, record, index) => (text || '').replace(/\_/g, ' > '),
  },
  {
    title: '상담건수',
    dataIndex: 'consultCount',
    key: 'consultCount',
    width: 100,
    align: 'right',
    render: (text, record, index) => text,
  },
  {
    title: '상담단가',
    dataIndex: 'consultationPrice',
    key: 'consultationPrice',
    width: 100,
    align: 'right',
    render: (text, record, index) => formatPrice(text),
  },
  {
    title: '회원구분',
    dataIndex: '',
    key: 'type',
    width: 120,
    align: 'center',
    render: (v) => {
      if (v.type === 'CENTER_MANAGER') {
        const check = v.authorities.find((val) => val === 'CONSULTANT');
        if (check) {
          return '센터장(겸직)';
        }
      }
      if (v.type === 'RELATIONSHIP_MANAGER') {
        const check = v.authorities.find((val) => val === 'PRO_CONSULTANT');
        if (check) {
          return 'RM(겸직)';
        }
      }
      return ACCOUNT_TYPE[v.type];
    },
  },
  {
    title: '회원상태',
    dataIndex: 'isLeaved',
    key: 'isLeaved',
    width: 100,
    align: 'center',
    render: (text, record, index) => (!text ? '정상' : '운영중지'),
  },
  {
    title: '가입일',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 160,
    align: 'center',
    render: (text, record, index) => moment(text).format('YYYY-MM-DD HH:mm'),
  },
  {
    title: '최근접속일',
    dataIndex: 'lastAccessTime',
    key: 'lastAccessTime',
    width: 160,
    align: 'center',
    render: (text, record, index) => (text !== null ? moment(text).format('YYYY-MM-DD HH:mm') : '최근 접속정보 없음'),
  },
  {
    title: '관리',
    key: 'operation',
    width: 80,
    align: 'center',
    fixed: 'right',
  },
];

const initialQuery = {
  page: 0,
  size: 20,
  searchType: undefined,
  periodType: 'createdAt',
  keyword: '',
  fromDate: undefined,
  toDate: undefined,
  // authorities: [],
  workerType: undefined,
  isLeaved: undefined,
  educationCompletionLevel: undefined,
  consultantLevel: undefined,
  professionalField2: undefined,
};

const ManagerIndex = (props) => {
  const isPro = props?.match?.params?.types === 'pro';
  const { principal } = useSelector((s) => s.auth, shallowEqual);
  const fetchData = useCallback(
    async (query) => {
      const types = isPro ? ['PRO_CONSULTANT', 'RELATIONSHIP_MANAGER'] : ['CONSULTANT', 'CENTER_MANAGER'];
      const args = { ...query, types: types.toString() };
      const { status, data: response } = await bsApi.get('/accounts', { params: args });
      return {
        rows: response?._embedded?.responseList || [],
        pagination: {
          current: response?.page?.number + 1,
          pageSize: response?.page?.size,
          total: response?.page?.totalElements || 0,
        },
      };
    },
    [isPro],
  );

  const { form, tableProps, onFormDateRangeChange, onFormFinish, onFormReset } = useListForm({
    ...props,
    fetchData,
    initialQuery,
    columnsOrigin,
    applyColumns: (draft, data) => {
      draft[0].render = (text, record, index) => {
        const page = data?.pagination;
        return page?.total - page?.pageSize * (page?.current - 1) - index;
      };
      draft[draft.length - 1].render = (record) => (
        <Link to={`/managers/${type}/${record.id}`}>
          <Button htmlType={'button'} size={'small'}>
            상세
          </Button>
        </Link>
      );
    },
  });

  console.log(props);

  //권한에 따라 노출되는 값
  let accountType = ACCOUNT_TYPE;
  if (['CENTER_MANAGER'].includes(principal.type)) {
    accountType = {
      CONSULTANT: '일반 상담원',
    };
  } else if (['RELATIONSHIP_MANAGER'].includes(principal.type)) {
    accountType = {
      PRO_CONSULTANT: '전문 상담원',
    };
  } else {
    accountType = {
      CONSULTANT: '일반 상담원',
      CENTER_MANAGER: '센터장',
      PRO_CONSULTANT: '전문 상담원',
      RELATIONSHIP_MANAGER: 'RM',
    };
  }

  const getMyExcel = async () => {
    let value = props?.match?.params?.types === 'pro' ? 'proConsultant' : 'consultant';

    switch (principal.type) {
      case 'CENTER_MANAGER':
        value = 'CONSULTANT';
        break;
      case 'RELATIONSHIP_MANAGER':
        value = 'PRO_CONSULTANT';
        break;
      default:
        value = props?.match?.params?.types === 'pro' ? 'PRO_CONSULTANT' : 'CONSULTANT';
        break;
    }

    const { data: response } = await bsApi.get(`/accounts/excel`, {
      params: { ...query, types: value },
      headers: { Accept: 'application/hal+json' },
      responseType: 'blob',
    });
    FileSaver.saveAs(response, `${props?.match?.params?.types === 'pro' ? '전문_' : '일반_'}상담원리스트.xls`);
  };

  const downloadSample = async (value) => {
    const { data: response } = await bsApi.get(`/accounts/sample-excel/${value}`, {
      headers: { Accept: 'application/hal+json' },
      responseType: 'blob',
    });
    let text = '상담원_양식';
    if (value === 'proConsultant') {
      text = '전문_상담원_양식';
    }
    FileSaver.saveAs(response, text + '.xls');
  };

  return (
    <div>
      <h2>상담원 관리</h2>
      <Form ref={form} onFinish={onFormFinish}>
        <Card>
          <Form.Item {...searchItemLayout} label="기간검색">
            <Row type={'flex'} gutter={[5, 5]}>
              <Col>
                <Form.Item name="periodType" noStyle>
                  <Select style={{ width: 150, marginRight: 5 }}>
                    <Select.Option value="createdAt">가입일</Select.Option>
                    <Select.Option value="lastAccessTime">최근접속일</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col flex={1}>
                <Form.Item name="dateRange" noStyle>
                  <DatePicker.RangePicker
                    style={{ marginRight: 5 }}
                    disabled={[false, false]}
                    allowClear
                    allowEmpty={[true, true]}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Button.Group>
                  <Button onClick={() => onFormDateRangeChange(0)}>오늘</Button>
                  <Button onClick={() => onFormDateRangeChange(7)}>7일</Button>
                  <Button onClick={() => onFormDateRangeChange(30)}>30일</Button>
                  <Button onClick={() => onFormDateRangeChange(365)}>1년</Button>
                </Button.Group>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item {...searchItemLayout} label="선택검색">
            <Row type={'flex'} gutter={[5, 5]}>
              <Col span={4}>
                <Form.Item name={'type'} noStyle>
                  <Select style={{ flex: 1, marginRight: 5 }} placeholder="회원유형" allowClear>
                    {Object.keys(accountType).map((key) => (
                      <Select.Option key={key} value={key}>
                        {accountType[key]}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name={'isLeaved'} noStyle>
                  <Select style={{ flex: 1, marginRight: 5 }} placeholder="회원상태" allowClear>
                    <Select.Option value={false}>정상</Select.Option>
                    <Select.Option value={true}>운영중지</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name={'workerType'} noStyle>
                  <Select style={{ flex: 1, marginRight: 5 }} placeholder="근무형태" allowClear>
                    {Object.keys(WORK_TYPE).map((key) => (
                      <Select.Option key={key} value={key}>
                        {WORK_TYPE[key]}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name={'educationCompletionLevel'} noStyle>
                  <Select style={{ flex: 1, marginRight: 5 }} placeholder="교육수료등급" allowClear>
                    {Object.keys(EDUCATION).map((key) => (
                      <Select.Option key={key} value={key}>
                        {EDUCATION[key]}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name={'consultantLevel'} noStyle>
                  <Select style={{ flex: 1, marginRight: 5 }} placeholder="상담원레벨" allowClear>
                    {Object.keys(CONSULTATION_LEVEL).map((key) => (
                      <Select.Option key={key} value={key}>
                        {CONSULTATION_LEVEL[key]}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name={'professionalField2'} noStyle>
                  <Select style={{ flex: 1, marginRight: 5 }} placeholder="전문분야" allowClear>
                    {Object.keys(PROFESSIONAL_FILELD).map((group) => (
                      <Select.OptGroup key={group} label={group}>
                        {Object.keys(PROFESSIONAL_FILELD_SUB)
                          .filter((key) => PROFESSIONAL_FILELD_SUB[key][1] === group)
                          .map((key) => (
                            <Select.Option value={key} key={key}>
                              {PROFESSIONAL_FILELD_SUB[key][0]}
                            </Select.Option>
                          ))}
                      </Select.OptGroup>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item {...searchItemLayout} label="상세검색">
            <Row>
              <Col>
                <Form.Item name="searchType" noStyle>
                  <Select style={{ width: 150, marginRight: 5 }} placeholder={'전체'} allowClear>
                    <Select.Option value="name">이름</Select.Option>
                    <Select.Option value="email">이메일</Select.Option>
                    <Select.Option value="mobilePhone">전화번호</Select.Option>
                    <Select.Option value="organization.name">센터/기관 명</Select.Option>
                    <Select.Option value="organization.code">센터/기관 코드</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col flex={1}>
                <Form.Item name="keyword" noStyle>
                  <Input placeholder="검색어를 입력하세요" />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
        </Card>
        <div style={{ marginTop: 10 }}>
          <Row>
            <Col xs={12}>
              <Button htmlType="button" type={'success'} onClick={() => getMyExcel()}>
                엑셀 다운로드
              </Button>
            </Col>
            <Col style={{ textAlign: 'right' }} xs={12}>
              <Button onClick={onFormReset} htmlType="button">
                초기화
              </Button>{' '}
              <Button htmlType="submit" type="primary">
                검색
              </Button>
            </Col>
          </Row>
        </div>
      </Form>

      <div style={{ marginTop: 25 }}>
        <Table
          rowKey={(record) => record.id}
          {...tableProps}
          footer={() => (
            <Row>
              <Col span={12}>
                {isPro ? (
                  <>
                    {['ADMIN', 'RELATIONSHIP_MANAGER'].includes(principal.type) && (
                      <Button style={{ marginRight: 5 }} onClick={() => downloadSample('proConsultant')}>
                        전문 상담원 등록 엑셀 파일 양식 다운로드
                      </Button>
                    )}
                  </>
                ) : (
                  <>
                    {['ADMIN', 'CENTER_MANAGER'].includes(principal.type) && (
                      <Button style={{ marginRight: 5 }} onClick={() => downloadSample('consultant')}>
                        기본 상담원 등록 엑셀 파일 양식 다운로드
                      </Button>
                    )}
                  </>
                )}
              </Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                {['ADMIN', isPro ? 'RELATIONSHIP_MANAGER' : 'CENTER_MANAGER'].includes(principal.type) && (
                  <Upload
                    name="file"
                    showUploadList={false}
                    action={getAPIHost() + `api/files/consultant/${isPro ? 'pro-consultant' : 'consultant'}`}
                    headers={bsApi.defaults.headers.common}
                    onChange={(e) => {
                      if (e.file.status === 'done') {
                        const resp = e.file.response;
                        let failure_msg = '';
                        if (resp.failure > 0) {
                          failure_msg = `- 실패 수 : ${resp.failure} \n--------------------------\n`;
                          failure_msg += resp.failureMessages.join('\n');
                        }
                        alert(
                          `엑셀 등록처리가 완료되었습니다.\n` +
                            `- 처리 수 : ${resp.success} / ${resp.totalCount}\n` +
                            failure_msg,
                        );

                        // alert(e?.file?.response === 'success' ? '업로드가 완료되었습니다.' : e?.file?.response);
                        // fetchData().catch(console.log);
                      } else if (e.file.status === 'error') {
                        alert('파일이 올바르지 않거나 서버와 연결할 수 없습니다.');
                      }
                    }}
                  >
                    <Button style={{ marginLeft: 5 }}>엑셀 파일 등록</Button>
                  </Upload>
                )}

                <Link to={`/managers/${isPro ? 'pro' : 'basic'}/create`}>
                  <Button style={{ marginLeft: 5 }} type={'primary'}>
                    등록
                  </Button>
                </Link>
              </Col>
            </Row>
          )}
          bordered
        />
      </div>
    </div>
  );
};

export default ManagerIndex;
